import Background, { BackgroundProps } from "./";
import styled from "styled-components";

interface OverlayBackgroundProps extends BackgroundProps {
  overlay: string;
}

const OverlayBackground = styled(Background)<OverlayBackgroundProps>`
  background-image: url(/overlays/${props => props.overlay}.png);
`;

/*
const overlays = require.context('./overlays')
console.log('overlays', overlays);

const OverlayBackground = styled(Background)<OverlayBackgroundProps>`
  background-image: url(${props => overlays[props.overlay+'.png'] });
`*/

export default OverlayBackground;
