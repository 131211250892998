// @ts-ignore
import React from "react";
import { hot } from "react-hot-loader/root";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";
import {
  ThemeProvider as MUIThemeProvider,
  StylesProvider as MUIStylesProvider,
} from "@material-ui/styles";
import { CssBaseline } from "@material-ui/core";
import muiTheme from "./themes/muiTheme";
import HeaderMain from "./components/HeaderMain";
import theme from "./themes/theme";

import HeadApp from "./Head";
import SingleArticle from "./Blog/SingleArticle";
import AllArticles from "./Blog/AllArticles";
import SinglePage from "./pages/SinglePage";
import AdminSnsa from "./admin";
import { ConnectedRouter } from "connected-react-router";
import store, { history } from "./store";
import { Route, Switch } from "react-router";
import Home from "./Home";
import { ROUTES_ADMIN, ROUTES_PUBLIC } from "./routes";

const App: React.FC = () => (
  <MUIStylesProvider injectFirst>
    <MUIThemeProvider theme={muiTheme}>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <HeadApp />
            <CssBaseline />
            <Switch>
              <Route exact path={ROUTES_PUBLIC} component={Home} />
              <Route path={ROUTES_ADMIN} component={AdminSnsa} />
              <Route>
                <HeaderMain />
                <Switch>
                  <Route exact path="/articles" component={AllArticles} />
                  <Route path="/articles/:postId" component={SingleArticle} />
                  <Route path="/pages/:pageId" component={SinglePage} />
                  <Route>
                    <p>Page inconnue</p>
                  </Route>
                </Switch>
              </Route>
            </Switch>
          </ConnectedRouter>
        </Provider>
      </ThemeProvider>
    </MUIThemeProvider>
  </MUIStylesProvider>
);

export default hot(App);
