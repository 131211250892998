import React from "react";
import { Edit, SimpleForm, TextInput } from "react-admin";

export const EditStats = props => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="label" />
        <TextInput source="value" />
      </SimpleForm>
    </Edit>
  );
};
