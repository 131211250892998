import React from "react";
import styled from "styled-components";

interface TitleProps {
  x: number;
  y: number;
  title: string;
  className?: string;
}

const Title = styled(({ x, y, title, className }: TitleProps) => {
  const width = 70;
  const height = 50;
  const distance = 20;
  return (
    <g className={className}>
      <foreignObject
        x={x - width / 2}
        y={y + distance}
        width={width}
        height={height}
      >
        <div>{title}</div>
      </foreignObject>
    </g>
  );
})`
  & div {
    font-size: 12px;
    font-family: sans-serif;
    color: black;
    text-align: center;
    font-weight: bold;
    line-height: 1.2;
  }
`;

export default Title;
