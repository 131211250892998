import React from "react";
import {
  Container,
  Grid,
  useTheme,
  useMediaQuery,
  Typography,
} from "@material-ui/core";
import HeaderLogo from "./HeaderLogo";
import MenuMain from "../MenuMain";
import MenuMobile from "../MenuMobile";
import styled from "styled-components";

const Header = styled("header")`
  position: fixed;
  top: 0;
  width: 100%;
  background: white;
  z-index: 10;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1.5px 7.5px 3px;
`;

interface HeaderMainProps {
  id?: string;
  isTop?: boolean;
  currentSection?: string;
  scrollTo?: (string) => void;
}

const HeaderMain = ({
  id,
  isTop,
  currentSection,
  scrollTo,
}: HeaderMainProps) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <Header id={id}>
      <Container>
        <Grid
          container
          direction="row"
          spacing={0}
          justify="center"
          alignItems="center"
        >
          <Grid item xs={1}>
            <HeaderLogo
              appearance="complet"
              withLink={true}
              isTop={isTop}
              isDesktop={isDesktop}
            />
          </Grid>
          <Grid item xs={11}>
            <div style={{ textAlign: "right" }}>
              {isDesktop ? (
                <MenuMain current={currentSection} scrollTo={scrollTo} />
              ) : (
                <MenuMobile current={currentSection} scrollTo={scrollTo} />
              )}
            </div>
          </Grid>
        </Grid>
      </Container>
    </Header>
  );
};

export default HeaderMain;
