import { Link } from "react-router-dom";
import { Breadcrumbs } from "@material-ui/core";
import React from "react";
import { useLocation } from "react-router";
import splitPathname from "../../helpers/splitPathname";
import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles({
  breadStyle: {
    color: "grey",
  },
});

const FilAriane = () => {
  const classes = useStyle();

  let location = useLocation();
  const pathnames = splitPathname(location.pathname);
  return (
    <Breadcrumbs aria-label="breadcrumb">
      {pathnames.map((pathname) => (
        <Link
          key={pathname}
          className={classes.breadStyle}
          color="inherit"
          to={
            location.pathname.substring(
              0,
              location.pathname.indexOf(pathname)
            ) + pathname
          }
        >
          {pathname}
        </Link>
      ))}
    </Breadcrumbs>
  );
};
export default FilAriane;
