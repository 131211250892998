import React, { SetStateAction } from "react";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  isWidthUp,
  withWidth,
  WithWidth
} from "@material-ui/core";
import styled from "styled-components";

interface SectionMenuProps {
  title?: string;
  items: { name: string; position: number }[];
  direction: "right" | "left";
  handleClick: (arg: SetStateAction<number>) => void;
}

interface MenuProps extends WithWidth {
  direction: "right" | "left";
}

export const Menu = withWidth()(styled(List).attrs<MenuProps>(props => ({
  direction: props.direction
}))<MenuProps>`
  margin-${({ direction }) => direction}: -20px;
  ${props => {
    if (!isWidthUp("md", props.width)) {
      return `
      display: flex;
      max-width: 100%;
      flex-wrap: wrap;
      justify-content: center;`;
    }
  }}
`);

export const MenuItem = withWidth()(styled(ListItem)<MenuProps>`
  background: rgba(74, 84, 102, 0.05);
  color: rgb(74, 84, 102);
  ${props => {
    if (isWidthUp("md", props.width)) {
      if (props.direction === "right") {
        return `border-radius: 20px 0 0 20px; text-align: right;`;
      } else {
        return `border-radius: 0 20px 20px 0; text-align: left;`;
      }
    } else {
      return `
      border-radius: 20px;
      text-align: center;
      width: auto;
      margin-left: 3px;
      margin-right: 3px;`;
    }
  }}
  padding: 6px 12px;
  margin-top: 15px;
  text-transform: uppercase;
  &:hover {
    background: rgba(74, 84, 102, 0.4);
    color: white;
  }
  &.selected {
    pointer-events: none;
    background: #a7cd45;
    color: white;
  }
`);

const SectionMenu = ({
  title,
  items,
  direction,
  handleClick
}: SectionMenuProps) => {
  return (
    <>
      {title ? (
        <Typography variant="h4" component="h2">
          {title}
        </Typography>
      ) : (
        ""
      )}
      <Menu direction={direction}>
        {items.map((item, key) => (
          <MenuItem
            direction={direction}
            button
            key={key}
            onClick={() => handleClick(item.position)}
          >
            <ListItemText primary={item.name} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default SectionMenu;
