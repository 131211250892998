import React, { useState } from "react";
import { Container } from "@material-ui/core";
import ArticleHeader from "../../components/ArticleHeader";
import SectionContent from "../../components/SectionContent";
import SectionCard from "../../components/SectionCard";
import BackButton from "../../components/BackButton";
import { ArticleDiv } from "../AllArticles";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { getSingleArticle } from "../../REST/getContents";

const mapStateToProps = (state) => ({
  post: state.singleArticleReducer.payload,
});
const withStore = connect(mapStateToProps);
const SingleArticle = ({ post }) => {
  let { postId } = useParams();
  const [loading, setLoading] = useState(false);
  React.useEffect(() => {
    const getpage = async () => {
      await setLoading(true);
      await getSingleArticle(postId);
      await setLoading(false);
    };
    getpage();
    return () => {
      // clean up
    };
  }, [postId]);
  if (loading) return "En chargement...";
  if (!post) return "Chargement...";

  const img = process.env.REACT_APP_API_URL + post.img.url;

  return (
    <>
      <ArticleHeader
        img={post.img ? img : "snsa-welcome.jpg"}
        title={post.title}
      />
      <ArticleDiv>
        <Container>
          <SectionCard opacity={0.9}>
            <SectionContent
              content={{
                text: post.text,
              }}
            />
          </SectionCard>
          <br />
          <BackButton />
        </Container>
      </ArticleDiv>
    </>
  );
};

export default withStore(SingleArticle);
