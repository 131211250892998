import React, { useState } from "react";
import {
  CircularProgress,
  TextField,
  Typography,
  Button,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import CustomTextField from "../Form/CustomTextField";
import testError from "../../helpers/testError";
import CustomSwitch from "../Form/CustomSwitch";
import { useMutation } from "react-admin";
import { useDispatch } from "react-redux";
import { isNotFirstTime } from "../../../store/actions/admin.actions";

const MyAccount = ({ user: { account, ...user } }) => {
  const dispatch = useDispatch();

  const [form, setForm] = useState({
    email: account.email,
    last_name: account.last_name,
    first_name: account.first_name,
    editPass: user.isFirstTime,
  });
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    testError(setError, e, form, setForm);
    return setForm({ ...form, [e.target.name]: e.target.value });
  };

  const [save, { loading, loaded }] = useMutation(
    {
      type: "update",
      resource: "users",
      payload: {
        id: account.id,
        data: form,
      },
    },
    {
      onSuccess: (res) => {
        user.isFirstTime && dispatch(isNotFirstTime(res.lastlogin));
      },
      onFailure: (err) => console.error(err),
    }
  );

  return (
    <>
      <Grid container spacing={3}>
        {user.isFirstTime && (
          <Grid item xs={12} style={{ marginBottom: "20px" }}>
            <Typography variant={"h6"} component={"p"} color="primary">
              Bienvenue ! Il s'agit de votre première connexion, pour pouvoir
              continuer à naviguer sur le centre documentaire, veuillez
              remplacer votre mot de passe de première connexion par un nouveau.
              Merci
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} style={{ marginBottom: "20px" }}>
          <Typography variant={"body1"} component={"p"}>
            Éditez votre profil
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item sm={6} md={4}>
          <TextField
            fullWidth
            label="Votre email"
            value={form.email || ""}
            onChange={handleChange}
            name={"email"}
            variant={"outlined"}
          />
        </Grid>
        <Grid item sm={6} md={4}>
          <TextField
            fullWidth
            label={"Votre nom"}
            value={form.last_name || ""}
            onChange={handleChange}
            name={"last_name"}
            variant={"outlined"}
          />{" "}
        </Grid>
        <Grid item sm={6} md={4}>
          <TextField
            fullWidth
            label={"Votre Prénom"}
            value={form.first_name || ""}
            name={"first_name"}
            onChange={handleChange}
            variant={"outlined"}
          />
        </Grid>
      </Grid>
      <CustomSwitch
        value={form.editPass}
        onChange={handleChange}
        name={"editPass"}
        label={"Voulez-vous éditer le mot de passe ?"}
        items={[
          { value: false, label: "Non" },
          { value: true, label: "Oui" },
        ]}
      />
      {form.editPass && (
        <Grid container spacing={3}>
          <Grid item sm={6}>
            <CustomTextField
              fullWidth
              type={"password"}
              label={"Mot de passe"}
              value={form.password || ""}
              onChange={handleChange}
              variant={"outlined"}
              name={"password"}
              textError={error}
              error={!!error}
            />
          </Grid>
          <Grid item sm={6}>
            <CustomTextField
              fullWidth
              type={"password"}
              value={form.confirm || ""}
              label={"Re-taper le mot de passe"}
              onChange={handleChange}
              variant={"outlined"}
              name={"confirm"}
              textError={error}
              error={!!error}
            />
          </Grid>
        </Grid>
      )}
      <Grid container spacing={3}>
        <Grid item xs={6}>
          {loading ? (
            <CircularProgress />
          ) : loaded ? (
            "Vos modifications ont bien été enregistrées"
          ) : (
            <Button
              variant={"outlined"}
              color={"primary"}
              onClick={save}
              disabled={!!error}
            >
              Enregister
            </Button>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default MyAccount;
