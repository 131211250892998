import React from "react";
import {AppBar, UserMenu, MenuItemLink, usePermissions} from "react-admin";
import SettingsIcon from "@material-ui/icons/Settings";
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {COMPANY_TITLE} from "../../../config/config";

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
    },
    spacer: {
        flex: 1,
    },
});

const ConfigurationMenu = React.forwardRef(({onClick}, ref) => (
    <MenuItemLink
        ref={ref}
        to="/dashboard"
        primaryText="Mon profil"
        leftIcon={<SettingsIcon/>}
        onClick={onClick} // close the menu on click
    />
));

const MyUserMenu = (props) => (
    <UserMenu {...props}>
        <ConfigurationMenu/>
    </UserMenu>
);

const CustomAppBar = (props) => {
    const {permissions} = usePermissions();
    const classes = useStyles();
    return (
        <AppBar loading={"false"} {...props} userMenu={<MyUserMenu/>}>
            <Typography variant={"caption"} className={classes.title}>
                {permissions && permissions.role === "admin"
                    ? "Administration " + COMPANY_TITLE
                    : COMPANY_TITLE + " centre de documentation"}
            </Typography>
            <span className={classes.spacer}/>
        </AppBar>
    );
};
export default CustomAppBar;
