import { Resource } from "react-admin";
import PostIcon from "@material-ui/icons/Book";
import UserIcon from "@material-ui/icons/People";
import React from "react";
import GroupDocumentEdit from "../components/adminGroup/documents/Edit";
import GroupDossierCreate from "../components/adminGroup/dossiers/Create";
import GroupDossierEdit from "../components/adminGroup/dossiers/Edit";
import GroupDocumentCreate from "../components/adminGroup/documents/Create";

export const groupAdminResources = [
  <Resource
    name="documents"
    edit={GroupDocumentEdit}
    create={GroupDocumentCreate}
    icon={PostIcon}
    options={{ label: "Documents" }}
  />,
  <Resource
    name="dossiers"
    edit={GroupDossierEdit}
    create={GroupDossierCreate}
    icon={UserIcon}
    options={{ label: "Dossiers" }}
  />,
];
