import { useAxios } from "./useAxios";
import { AxiosResponse } from "axios";

export const sendMail = async (body) => {
  try {
    const res: AxiosResponse = await useAxios.post("homes/mail", body);
    return res.data;
  } catch (e) {
    console.warn(e);
  }
};
