import { Button, Grid, TextField, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { AUTH_LOGIN } from "ra-core";
import authProvider from "../../authProvider";
import { Redirect } from "react-router";
import store from "../../../store";

const MyLoginPage = () => {
  const [form, setForm] = useState({
    username: "",
    password: "",
  });

  const [erreur, setErreur] = useState();
  const [redirect, setRedirect] = useState();

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleClick = async () => {
    try {
      const res = await authProvider(AUTH_LOGIN, form);
      setRedirect(res);
      return store.dispatch({
        type: "@@router/LOCATION_CHANGE",
        payload: {
          location: {
            pathname: "/dashboard",
            search: "",
            hash: "",
            query: {},
          },
          action: "REPLACE",
        },
      });
    } catch (e) {
      setErreur(e.message);
    }
  };

  return (
    <Grid container direction={"column"} alignItems={"center"} spacing={2}>
      <Grid item>
        <TextField
          onChange={handleChange}
          value={form.username}
          placeholder={"Nom d'utilisateur"}
          name="username"
          variant={"outlined"}
        />
      </Grid>
      <Grid item>
        <TextField
          onChange={handleChange}
          placeholder={"Mot de passe"}
          value={form.password}
          name={"password"}
          type={"password"}
          variant={"outlined"}
        />
      </Grid>
      {erreur && (
        <Typography component={"p"} color={"error"} variant={"body1"}>
          {erreur}
        </Typography>
      )}
      {redirect && <Redirect to="/dashboard" />}
      <Grid item>
        <Button variant={"contained"} color={"primary"} onClick={handleClick}>
          S'identifier
        </Button>
      </Grid>
    </Grid>
  );
};

export default MyLoginPage;
