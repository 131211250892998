// @ts-ignore
import React, { ReactComponentElement, ReactElement } from "react";
import EditMenu from "./EditMenu";
// @ts-ignore
import { usePermissions } from "react-admin";
import { createStyles, IconButton, makeStyles, Theme } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { ROLE } from "../../domain/role";
const initialState = {
  mouseX: null,
  mouseY: null,
  isEditor: false,
  dossierID: null,
  documentID: null,
};

interface RightClickProps {
  children: React.ReactNode;
  dossierID?: Number;
  isEditor?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    // @ts-ignore
    mybtn: {
      margin: theme.spacing(1),
      position: "fixed !important",
      right: "2%",
      bottom: "2%",
      background: `${theme.palette.primary.main} !important`,
      color: `white !important`,
      transition: "1s",
      "&:hover": {
        background: `${theme.palette.secondary.main} !important`,
      },
    },
  })
);

interface RightClickState {
  mouseX: null | number;
  mouseY: null | number;
  dossierID: null | number;
  documentID: null | number;
  isEditor: boolean;
}

const RightClick = ({ children, dossierID, isEditor }: RightClickProps) => {
  const [state, setState] = React.useState<RightClickState>(initialState);

  const classes = useStyles();
  const { permissions } = usePermissions();
  if (
    permissions &&
    permissions.role !== ROLE.admin &&
    permissions.role !== ROLE.groupAdmin
  )
    return children;

  const allowedToEdit =
    isEditor ||
    state.isEditor === "true" ||
    (permissions && permissions.role === ROLE.admin);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!allowedToEdit) return;
    const element = event.target && event.target.offsetParent;
    const dosEl = element && element.getElementsByTagName("a")[0];
    const docEl = element && element.getElementsByTagName("div")[0];

    let id = element && {
      dossier:
        (element && element.getAttribute("data-dossierid")) ||
        (dosEl && dosEl.getAttribute("data-dossierid")),
      document:
        (element && element.getAttribute("data-documentid")) ||
        (docEl && docEl.getAttribute("data-documentid")),
      isEditor:
        (element && element.getAttribute("data-isEditor")) ||
        (dosEl && dosEl.getAttribute("data-isEditor")),
    };
    event.preventDefault();
    setState({
      dossierID: id && id.dossier,
      documentID: id && id.document,
      isEditor: id && id.isEditor,
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  };

  const handleClose = () => {
    setState(initialState);
  };

  return (
    <>
      {allowedToEdit && (
        <IconButton
          aria-label="delete"
          onClick={handleClick}
          //@ts-ignore
          className={classes.mybtn}
        >
          <AddIcon fontSize="large" />
        </IconButton>
      )}
      <div
        onContextMenu={handleClick}
        style={{ cursor: allowedToEdit && "context-menu" }}
      >
        {children}
        {allowedToEdit && (
          <EditMenu
            dossierID={dossierID}
            handleClose={handleClose}
            state={state}
          />
        )}
      </div>
    </>
  );
};
export default RightClick;
