import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_ERROR,
  AUTH_CHECK,
  AUTH_GET_PERMISSIONS,
} from "react-admin";
import { setCookies, USER } from "./domain/User/user";
import { setUser } from "../store/actions/admin.actions";
import store from "../store";
import { logout } from "./domain/User/logout";
import Cookies from "./helpers/Cookies/Cookies";
import getUser from "./query/getUser.query";

export default async (type, params) => {
  if (type === AUTH_LOGIN) {
    const { username, password } = params;
    const request = new Request(process.env.REACT_APP_API_URL + "/auth/local", {
      method: "POST",
      body: JSON.stringify({ username, password }),
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    return fetch(request)
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        if (response.statusCode < 200 || response.statusCode >= 300) {
          throw new Error(response.message[0].messages[0].message);
        }

        setCookies(response);
        store.dispatch(setUser(USER(response.user)));
        return Promise.resolve(true);
      });
  }

  if (type === AUTH_LOGOUT) {
    logout();
  }

  if (type === AUTH_ERROR) {
    const status = params.status;
    if (status === 401 || status === 403) {
      logout();
      return Promise.reject();
    }
    return Promise.resolve();
  }

  if (type === AUTH_CHECK) {
    if (Cookies.getCookie("token")) {
      return Promise.resolve();
    }
    console.info("Déconnecté, aucun token trouvé");
    return Promise.reject({ redirectTo: "/" });
  }

  if (type === AUTH_GET_PERMISSIONS) {
    if (Cookies.getCookie("token")) {
      let { userReducer: user } = store.getState();

      if (!user.isAuthent) {
        const response = await getUser(Cookies.getCookie("userID"));
        store.dispatch(setUser(USER(response.data)));
        const { userReducer } = store.getState();

        return Promise.resolve({
          role: userReducer.role,
          groups: userReducer.groups,
          dossiers: userReducer.dossiers,
          navigation: userReducer.navigation,
        });
      }

      if (user.isAuthent) {
        return Promise.resolve({
          role: user.role,
          groups: user.groups,
          dossiers: user.dossiers,
          navigation: user.navigation,
        });
      }
    }

    if (!Cookies.getCookie("token")) return Promise.reject("Aucun token");
  }

  return Promise.resolve();
};
