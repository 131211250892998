import BarlowRegularWoff2 from "./barlow/barlow-regular.woff2";
import BarlowSemiBoldWoff2 from "./barlow/barlow-600.woff2";
import BarlowBoldWoff2 from "./barlow/barlow-800.woff2";

export const barlowRegular = {
  fontFamily: "Barlow",
  fontStyle: "normal",
  fontWeight: 400,
  src: `
    local('Barlow'),
    local('Barlow-Regular'),
    url(${BarlowRegularWoff2}) format('woff2')
  `
};

export const barlowSemiBold = {
  fontFamily: "Barlow-SemiBold",
  fontStyle: "normal",
  fontWeight: 600,
  src: `
    local('Barlow-SemiBold'),
    url(${BarlowSemiBoldWoff2}) format('woff2')
  `
};

export const barlowBold = {
  fontFamily: "Barlow-Bold",
  fontStyle: "normal",
  fontWeight: 800,
  src: `
    local('Barlow-SemiBold'),
    url(${BarlowBoldWoff2}) format('woff2')
  `
};
