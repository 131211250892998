// @ts-ignore
import React from "react";
import { Route } from "react-router-dom";
import Dossier from "../admin/components/Dossier";
import Dashboard from "../admin/components/Dashboard";
import { contentResourceTypes } from "../admin/resources/admin/content";

export const ROUTES_CUSTOM_RA: JSX.Element[] = [
  <Route exact path={"/dossier"} component={Dossier} />,
  <Route path={"/dossier/:slug"} component={Dossier} />,
  <Route path={"/dashboard"} component={Dashboard} />,
];

export const ROUTES_ADMIN: string[] = [
  "/dossier",
  "/dossier/:slug",
  "/dashboard",
  "/admin",
  "/login",
  "/documents",
  "/user-groups",
  "/dossiers",
  "/users",
  ...contentResourceTypes.map((link) => "/" + link),
];
export const ROUTES_PUBLIC: string[] = [
  "/",
  "/welcome",
  "/qui-sommes-nous",
  "/adherents",
  "/metiers",
  "/chiffres",
  "/actualites",
  "/footer",
  "/copyright",
];
