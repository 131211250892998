import React from "react";
import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { LinkTypeMap } from "@material-ui/core/Link/Link";
import { menuItems } from "./mock";
import { useSelector } from "react-redux";
import { getUser } from "../../store/selectors/user.selector";

const StyledLink = styled(Link)`
  margin-left: 20px;
  font-size: 16px;
  @media (min-width: 960px) {
    margin-left: 25px;
  }
  text-transform: uppercase;
  text-decoration: none !important;
  &:hover {
    color: ${(props) => props.theme.colors.blue2};
  }
  &:active {
    color: ${(props) => props.theme.colors.grey};
  }
` as OverridableComponent<LinkTypeMap>;

interface MenuMainProps {
  scrollTo: (string) => void;
  current?: string;
}

const MenuMain = ({ current, scrollTo }: MenuMainProps) => {
  const user = useSelector(getUser);
  return (
    <Typography align="right">
      {menuItems.map(
        (menuItem) =>
          menuItem.enabled && (
            <StyledLink
              component={RouterLink}
              onClick={(e) => {
                if (menuItem.route === "/") {
                  scrollTo && scrollTo(menuItem.fragments);
                }
              }}
              to={`${menuItem.route}${menuItem.fragments || ""}`}
              target={"_self"}
              color="primary"
              key={menuItem.name}
            >
              {menuItem.name}
            </StyledLink>
          )
      )}
      <StyledLink
        component={RouterLink}
        to="/dashboard"
        target="_self"
        color="primary"
        key="Centre Documentaire"
      >
        {user.isAuthent ? "Mon compte" : "Espace membres"}
      </StyledLink>
    </Typography>
  );
};

export default MenuMain;
