import React from "react";
import { Create, SimpleForm, TextInput } from "react-admin";
import { getParams } from "../../../../helpers/getParamsId";
import { Button, Typography } from "@material-ui/core";
import { useHistory } from "react-router";
import { redirect } from "../../../../helpers/redirect";

const DossierCreate = (props) => {
  const params = getParams(props);
  console.log(params);
  const { goBack } = useHistory();

  const PostTitle = () => {
    return <span>Créer un nouveau dossier</span>;
  };
  return (
    <>
      <Typography
        component={"h1"}
        variant={"h2"}
        align={"center"}
        style={{ padding: "30px" }}
      >
        Ajouter un document
      </Typography>
      <Button variant={"outlined"} onClick={() => goBack()}>
        Revenir
      </Button>

      <Create undoable={false} title={<PostTitle />} {...props}>
        <SimpleForm redirect={() => redirect(params)}>
          <TextInput source="name" label={"Nom du Dossier"} />
          <TextInput
            style={{ display: "none" }}
            name="parent"
            label={"dossiers"}
            defaultValue={params.id}
            disabled
          />
        </SimpleForm>
      </Create>
    </>
  );
};

export default DossierCreate;
