import React from "react";
import { Edit, SimpleForm, TextInput, FileInput, FileField } from "react-admin";
import { Button, Typography } from "@material-ui/core";
import { useHistory } from "react-router";
import { getParams } from "../../../../helpers/getParamsId";
import { redirect } from "../../../../helpers/redirect";

const GroupDocumentEdit = (props) => {
  const { goBack } = useHistory();
  const params = getParams(props);

  return (
    <>
      <Typography
        component={"h1"}
        variant={"h2"}
        align={"center"}
        style={{ padding: "30px" }}
      >
        Modifier le document
      </Typography>
      <Button variant={"outlined"} onClick={() => goBack()}>
        Revenir
      </Button>
      <Edit undoable={false} {...props}>
        <SimpleForm redirect={() => redirect(params)}>
          <TextInput source="title" label={"Titre"} />
          <TextInput source="text" label={"Description"} multiline fullWidth />
          <FileInput source="media" label="Ajouter document">
            <FileField source={"url"} title={"name"} />
          </FileInput>
          <Typography variant={"caption"} component={"span"}>
            Cliquez sur le titre du document pour télécharger
          </Typography>
        </SimpleForm>
      </Edit>
    </>
  );
};

export default GroupDocumentEdit;
