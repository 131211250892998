import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { Typography, useMediaQuery } from "@material-ui/core";
import {
  getResources,
  MenuItemLink,
  usePermissions,
  useQuery,
  Loading,
} from "react-admin";
import { withRouter } from "react-router-dom";
import LabelIcon from "@material-ui/icons/Label";
import { resourceArrays } from "./resourceArrays";
import Disclaimer from "../../components/Disclaimer";
import { ROLE } from "../../domain/role";
import { Links } from "./Links";
import FolderIcon from "@material-ui/icons/Folder";
import getAllDossier from "../../query/allDossiers.query";
import { Error } from "../../components/Error";
import { getUser } from "../../../store/selectors/user.selector";

const CustomMenu = ({ onMenuClick, logout }) => {
  const resources = useSelector(getResources);
  const userReducer = useSelector(getUser);
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const open = useSelector((state) => state.admin.ui.sidebarOpen);

  const { permissions } = usePermissions();
  const { data, loading, error } = useQuery(getAllDossier);
  if (loading) return <Loading />;
  if (error) return <Error message={error.message} />;
  const { docs, contents } = resourceArrays(resources);

  console.log(data, permissions.role);

  return (
    <div>
      <MenuItemLink
        to="/dashboard"
        primaryText="Dashboard"
        leftIcon={<LabelIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />

      <MenuItemLink
        to={`/dossier`}
        primaryText={"Accueil"}
        leftIcon={<FolderIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      {(permissions && permissions.role === ROLE.admin) ||
      permissions.role === ROLE.membre ||
      permissions.role === ROLE.groupAdmin ? (
        <Links
          onMenuClick={onMenuClick}
          open={open}
          resources={data}
          title={"Dossiers"}
        />
      ) : (permissions && permissions.role === ROLE.membre) ||
        (permissions.role === ROLE.groupAdmin && userReducer.isFirstTime) ? (
        <Disclaimer />
      ) : null}

      {permissions && permissions.role === ROLE.admin && (
        <>
          <Links
            onMenuClick={onMenuClick}
            open={open}
            resources={docs}
            title={"Gestion des documents"}
          />
          <Links
            onMenuClick={onMenuClick}
            open={open}
            resources={contents}
            title={"Contenus du site"}
          />
        </>
      )}

      {isXSmall && logout}
    </div>
  );
};

export default withRouter(React.memo(CustomMenu));
