import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
  ReferenceInput,
} from "react-admin";
import { Button, Typography } from "@material-ui/core";
import { useHistory } from "react-router";
import { getParams } from "../../../../helpers/getParamsId";
import { redirectAdmin } from "../../../../helpers/redirect";

const DossierEdit = (props) => {
  const { goBack } = useHistory();
  const params = getParams(props);

  return (
    <>
      <Typography
        component={"h1"}
        variant={"h2"}
        align={"center"}
        style={{ padding: "30px" }}
      >
        Modifier un dossier
      </Typography>

      <Button variant={"outlined"} onClick={() => goBack()}>
        Revenir
      </Button>

      <Edit undoable={false} {...props}>
        <SimpleForm redirect={() => redirectAdmin(params, props.basePath)}>
          <TextInput source="name" label={"Nom du Dossier"} />
          <TextInput disabled source="slug" label={"lien"} />
          <ArrayInput
            source="documents"
            label={"Choisissez les documents à ajouter dans le groupe"}
          >
            <SimpleFormIterator>
              <ReferenceInput
                source="id"
                reference={"documents"}
                label={"Nom du document"}
              >
                <SelectInput optionText="title" />
              </ReferenceInput>
            </SimpleFormIterator>
          </ArrayInput>
          <ArrayInput
            source="user_groups"
            label={"Choisissez les groupes à ajouter au dossier"}
          >
            <SimpleFormIterator>
              <ReferenceInput
                source="id"
                reference={"user-groups"}
                label={"Nom du groupe"}
                perPage={100}
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
            </SimpleFormIterator>
          </ArrayInput>
          <ReferenceInput
            source="parent.id"
            reference="dossiers"
            label={"Choisissez le dossier parent"}
            perPage={100}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
        </SimpleForm>
      </Edit>
    </>
  );
};

export default DossierEdit;
