import { Box, makeStyles, TextField, Typography } from "@material-ui/core";
// @ts-ignore
import React, { useCallback } from "react";
import DateIntervalInput from "../DateIntervalInput/DateIntervalInput";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles({
  paperStyle: {
    marginTop: "20px",
    padding: "30px",
  },
  margin: {
    margin: "10px",
  },
});

interface FilterProps {
  setFilters: (any: any) => void;
  filters: any;
  withDate?: boolean;
}

export const Filters = React.memo(
  ({ setFilters, filters, withDate = false }: FilterProps) => {
    // @ts-ignore
    const classes = useStyles();
    const handleChange = useCallback(
      (e) => {
        !e.target.value
          ? delete filters[e.target.name] && setFilters({ ...filters })
          : setFilters({ ...filters, [e.target.name]: e.target.value });
      },
      [filters]
    );

    return (
      <Paper className={classes.paperStyle} elevation={1}>
        <Grid container spacing={3} justify={"center"}>
          <Grid item xs={12}>
            <Typography
              variant={"h5"}
              component={"h1"}
              color={"primary"}
              align={"center"}
            >
              Recherche par critères :
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          justify={"center"}
          alignContent={"center"}
          alignItems={"center"}
        >
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              variant={"filled"}
              name={"_q"}
              value={filters.title}
              onChange={handleChange}
              label={"Titre ou description"}
            />
          </Grid>
          {withDate && (
            <Grid item md={9} xs={12}>
              <DateIntervalInput
                handleChange={handleChange}
                debut={filters.eventdate_gte}
                fin={filters.eventdate_lte}
              />
            </Grid>
          )}
        </Grid>
        <Box component="span" m={5} />
      </Paper>
    );
  }
);
