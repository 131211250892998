import React from "react";
import {
  BooleanInput,
  Create,
  ImageField,
  ImageInput,
  SimpleForm,
  TextInput,
} from "react-admin";

export const CreatePosts = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="title" />
        <BooleanInput source="enabled" />
        <TextInput source="text" fullWidth multiline />
        <ImageInput source="img" label="Ajouter une image" accept="image/*">
          <ImageField source={"url"} title={"name"} />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
};
