import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
  ReferenceInput,
  FileInput,
  FileField,
  DateInput,
} from "react-admin";
import { Button, Typography } from "@material-ui/core";
import { useHistory } from "react-router";
import { getParams } from "../../../../helpers/getParamsId";
import { redirectAdmin } from "../../../../helpers/redirect";

const DocumentEdit = (props) => {
  const { goBack } = useHistory();
  const params = getParams(props);

  return (
    <>
      <Typography
        component={"h1"}
        variant={"h2"}
        align={"center"}
        style={{ padding: "30px" }}
      >
        Modifier le document
      </Typography>
      <Button variant={"outlined"} onClick={() => goBack()}>
        Revenir
      </Button>
      <Edit undoable={false} {...props}>
        <SimpleForm redirect={() => redirectAdmin(params, props.basePath)}>
          <TextInput source="title" label={"Titre"} />
          <TextInput source="text" label={"Description"} multiline fullWidth />
          <FileInput source="media" label="Ajouter document">
            <FileField source={"url"} title={"name"} />
          </FileInput>
          <DateInput source="eventdate" label={"Date de l'évenement"} />
          <Typography variant={"caption"} component={"span"}>
            Cliquez sur le titre du document pour télécharger
          </Typography>
          <ArrayInput
            source="dossiers"
            label={"Ajoutez le document à un dossier"}
          >
            <SimpleFormIterator>
              <ReferenceInput
                source="id"
                reference={"dossiers"}
                label={"Nom du dossier parents"}
                perPage={100}
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleForm>
      </Edit>
    </>
  );
};

export default DocumentEdit;
