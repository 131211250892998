import React from "react";
import get from "lodash/get";

const LinkField = ({
  className,
  source,
  record = {},
  prefix = null,
  target = "self",
  ...rest
}) => (
  <a
    className={className}
    href={prefix + get(record, source)}
    target={target}
    {...rest}
  >
    {get(record, source)}
  </a>
);

export default LinkField;
