import React from "react";
import { Member } from "../../types/member";
import styled from "styled-components";
import VCard from "../VCard";

interface MemberModalProps {
  member: Member;
  className?: string;
}

const MemberModal = styled(({ member, className }: MemberModalProps) => (
  <div className={className}>
    <VCard
      imagePath={
        member.photo && process.env.REACT_APP_MEDIA_URL + member.photo.url
      }
      firstName={member.first_name}
      lastName={member.last_name}
      role={member.role}
      note={member.text}
    />
  </div>
))`
  & .fn {
    font-size: 16px;
    font-weight: bold;
  }
  & .role {
    font-weight: bold;
    font-size: 16px;
  }
  & img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    display: block;
    margin: 20px auto 20px auto;
  }
`;
export default MemberModal;
