import { connectRouter, RouterRootState } from "connected-react-router";
import { combineReducers } from "redux";
import { History } from "history";
import { adminReducer } from "react-admin";
import homeReducer from "./home.reducer";
import pageReducer from "./pages.reducer";
import articlesReducer from "./articles.reducer";
import singleArticleReducer from "./article.reducer";
import userReducer from "./user.reducer";

const rootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    userReducer,
    homeReducer,
    pageReducer,
    articlesReducer,
    singleArticleReducer,
    admin: adminReducer,
  });

export interface AppState extends RouterRootState {}

export default rootReducer;
