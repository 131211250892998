export const sendContent = (payload) => ({
  type: "STORECONTENT",
  payload,
});
export const sendPageContent = (payload) => ({
  type: "PAGE_CONTENT",
  payload,
});
export const sendArticleContent = (payload) => ({
  type: "ALL_ARTICLE_CONTENT",
  payload,
});
export const sendSingleArticleContent = (payload) => ({
  type: "SINGLE_ARTICLE_CONTENT",
  payload,
});
