import React from "react";
import { DocumentList } from "./DocumentList";
import Grid from "@material-ui/core/Grid";
import { DossierList } from "./DossierList";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Box, createStyles, Typography } from "@material-ui/core";
import { Error } from "../Error";
import getDossier from "../../query/dossiers.query";
import { useQuery, Loading } from "react-admin";
import RightClick from "../RightClick/RightClick";
import AucunDossier from "../Disclaimer/AucunDossier";

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      background: "white",
      border: "1px solid" + theme.palette.primary.main,
      padding: "10px",
      margin: "30px 0 30px",
    },
  })
);

export const DossierContents = ({ filters, pathname }) => {
  const classes = useStyles();

  const { data, loading, error } = useQuery(getDossier(pathname, filters));

  if (loading) return <Loading />;
  if (error) return <Error message={error.message} />;
  if (data.length === 0 || !data) return <AucunDossier />;
  const dossier = data[0];
  const documents = dossier.documents;

  return (
    <RightClick dossierID={dossier.id} isEditor={dossier.isEditor}>
      <Box className={classes.title}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant={"h5"}
              component={"h1"}
              align={"center"}
              color={"primary"}
            >
              {dossier.name && dossier.name.toUpperCase()}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {dossier.children && <DossierList childDossiers={dossier.children} />}
          {documents && (
            <DocumentList
              documents={documents}
              title={"Documents du dossier :"}
              color={"secondary"}
            />
          )}
        </Grid>
      </Grid>
    </RightClick>
  );
};
