import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ArrayField,
  SingleFieldList,
  ChipField,
  SimpleList,
  DateField,
} from "react-admin";
import LinkField from "../../../LinkField";
import { useMediaQuery } from "@material-ui/core";
import { DocumentFilter } from "../../../SearchField";

const DocumentList = (props) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <List perPage={100} filters={<DocumentFilter />} {...props}>
      {isSmall ? (
        <SimpleList
          primaryText={(record) => record.title}
          secondaryText={(record) => `${record.media && record.media.url}`}
          tertiaryText={(record) =>
            new Date(record.created_at).toLocaleDateString()
          }
        />
      ) : (
        <Datagrid rowClick="edit">
          <TextField source="title" />
          <TextField source="text" />
          <DateField source="eventdate" label={"Date de la commision"} />
          <LinkField
            source="media.url"
            prefix={process.env.REACT_APP_MEDIA_URL}
            target={"_blank"}
          />
          <ArrayField source="dossiers">
            <SingleFieldList>
              <ChipField source="name" />
            </SingleFieldList>
          </ArrayField>
        </Datagrid>
      )}
    </List>
  );
};

export default DocumentList;
