import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
  ReferenceInput,
  PasswordInput,
} from "react-admin";

const UserEdit = (props) => {
  const PostTitle = ({ record }) => {
    return (
      <span>
        Éditer {record ? `${record.first_name} ${record.last_name}` : ""}
      </span>
    );
  };
  return (
    <Edit title={<PostTitle />} {...props}>
      <SimpleForm>
        <TextInput source="username" />
        <TextInput source="email" />
        <TextInput source="first_name" label={"Prénom"} />
        <TextInput source="last_name" label={"Nom de famille"} />
        <BooleanInput source="confirmed" label={"Confirmer l'utilisateur"} />
        <PasswordInput source="password" label={"Mot de passe"} />
        <ReferenceInput
          source="role.id"
          reference={"users-permissions/roles"}
          label={"Choisir le role"}
          sort={{ field: "name", order: "ASC" }}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ArrayInput
          source="user_groups"
          label={"Choisissez un ou plusieurs groupe"}
        >
          <SimpleFormIterator>
            <ReferenceInput
              source="id"
              reference={"user-groups"}
              label={"Nom du Groupe"}
              perPage={100}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput
          source="editors"
          label={
            "Choisissez les groupes dans lesquels l'utilisateur est administrateur"
          }
        >
          <SimpleFormIterator>
            <ReferenceInput
              source="id"
              reference={"user-groups"}
              label={"Nom du Groupe"}
              perPage={100}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};

export default UserEdit;
