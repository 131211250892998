import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ArrayField,
  SingleFieldList,
  ChipField,
} from "react-admin";

const UserGroupList = (props) => (
  <List perPage={100} {...props}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <ArrayField source="dossiers">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField source="users">
        <SingleFieldList>
          <ChipField source="username" />
        </SingleFieldList>
      </ArrayField>
    </Datagrid>
  </List>
);

export default UserGroupList;
