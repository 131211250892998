import { Button } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";

const BackButton = () => {
  return (
    <Link to={"/"}>
      <Button variant={"contained"} color={"primary"}>
        Revenir
      </Button>
    </Link>
  );
};

export default BackButton;
