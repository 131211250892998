import React, { useState } from "react";
import { DossierContents } from "./DossierContents";
import { Filters } from "./Filters";
import { Box, Container } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import FilAriane from "../FilAriane";
import { useLocation } from "react-router";
import AllDossier from "../AllDossier";

const Dossier = () => {
  const { pathname } = useLocation();
  const [filters, setFilters] = useState({});
  return (
    <>
      <Filters filters={filters} setFilters={setFilters} />
      <Box m={3} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FilAriane />
        </Grid>
      </Grid>
      <Container maxWidth={"xl"}>
        {pathname.replaceAll("/", "") === "dossier" ? (
          <AllDossier />
        ) : (
          <DossierContents filters={filters} pathname={pathname} />
        )}
      </Container>
    </>
  );
};

export default Dossier;
