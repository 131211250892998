import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ImageField,
  ImageInput,
} from "react-admin";

export const CreateCompanies = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <TextInput source="url" />
        <TextInput source="tel" />
        <ImageInput source="logo" label="Logo de l'entreprise" accept="image/*">
          <ImageField source={"url"} title={"name"} />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
};
