import React from "react";
import styled from "styled-components";
import {
  Grid,
  isWidthUp,
  Typography,
  WithWidth,
  withWidth,
} from "@material-ui/core";
import Section from "../Section";
import Menu from "../Menu";
import Logo from "../Logo";
import VCard from "../VCard";
import { networksMenuItems, pressAreaMenuItems, vCard } from "./mock";
import { theme } from "../../themes/theme";
import ContactForm from "../ContactForm";
import ColorBackground from "../Background/ColorBackground";
import Modal from "../Modal";

interface FooterTitleProps {
  title: string;
  className?: string;
}

const FooterTitle = styled(({ className, title }: FooterTitleProps) => (
  <Typography className={className} variant="h2" component="h2">
    {title}
  </Typography>
))`
  font-size: 20px;
  line-height: 1.2;
  margin: 20px 0 15px 0;
  text-transform: uppercase;
`;

const FooterLogo = styled(Logo)`
  width: 100%;
  max-width: 150px;
  padding-bottom: 20px;
  & svg path {
    fill: white !important;
  }
`;

const PressAreaMenu = styled(Menu)`
  & a {
    padding: 0 0 10px 0;
  }
`;

const NetworksMenu = styled(Menu)`
  & button {
    padding: 10px;
    background: white;
    height: 50px;
    width: 50px;
    min-width: auto;
    margin-right: 10px;
    border-radius: 50%;
    & svg {
      height: inherit;
      width: inherit;
      fill: ${(props) => props.theme.colors.grey};
    }
  }
`;

interface FooterProps extends WithWidth {
  className?: string;
}

const Footer = withWidth()(styled(({ className }: FooterProps) => {
  return (
    <Section
      backgrounds={<ColorBackground color={theme.colors.grey} />}
      id="footer"
      className={className + " fp-auto-height"}
      alignItems="flex-start"
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <FooterTitle title="Coordonnées" />
          <FooterLogo appearance="simple" />
          <VCard {...vCard} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <FooterTitle title="Espace presse" />
          <Modal
            linkStyle={true}
            title={"Contact presse"}
            content={
              <Typography variant={"body2"} component={"p"}>
                Contact Presse / relations média
                <br />
                Pascale Gillard / Attachée de presse
                <br />
                +33689955295
                <br />
                pascale.gillard@gmail.com
                <br />
              </Typography>
            }
          >
            Contact presse
          </Modal>

          <PressAreaMenu menuItems={pressAreaMenuItems} />
          <FooterTitle title="Sur les réseaux" />
          <NetworksMenu menuItems={networksMenuItems} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FooterTitle title="Contact" />
          <ContactForm />
        </Grid>
      </Grid>
    </Section>
  );
})`
  color: white;
  & * {
    color: white;
  }
  & .MuiGrid-root {
    min-height: auto !important;
    z-index: 2;
    ${(props) => {
      if (isWidthUp("md", props.width)) {
        return `
    padding-top: 50px !important;
    padding-bottom: 50px !important;`;
      }
    }}
  }
`);

export default Footer;
