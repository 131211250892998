import {
  Grid,
  isWidthUp,
  Theme,
  Typography,
  WithWidth,
  withWidth,
} from "@material-ui/core";
import SectionCard from "../../../components/SectionCard";
import { TabPanel } from "../../../components/TabPanel";
import { evalShortcodes } from "../../../helpers/evalShortCode";
import React from "react";
import styled from "styled-components";

interface ContentProps extends WithWidth {
  handleChangeIndex: (index: number) => void;
  position: number;
  muiTheme: Theme;
  className: string;
  content: { aboutSlides: any; shortCodeContent: any };
}

const Content = styled((props: ContentProps) => {
  return (
    <Grid item sm={9} className={props.className}>
      <SectionCard
        opacity={0.8}
        handleChangeIndex={props.handleChangeIndex}
        position={props.position}
        roundedCorners={
          isWidthUp("md", props.width)
            ? [false, true, true, false]
            : [false, false, true, true]
        }
      >
        {props.content &&
          props.content.aboutSlides.map((content, key) => (
            <TabPanel
              position={props.position}
              index={key}
              dir={props.muiTheme.direction}
              key={key}
            >
              <Typography variant="h3" component="h3">
                {content.title}
              </Typography>
              <div className="MuiTypography-body1">
                {evalShortcodes(content.text, props.content.shortCodeContent)}
              </div>
              {content.image && (
                <Grid
                  container
                  style={{ textAlign: "center" }}
                  spacing={1}
                  justify={"space-around"}
                >
                  {content.image.map((img) => (
                    <Grid
                      item
                      style={{
                        width: "30%",
                        height: "200px",
                        overflow: "hidden",
                      }}
                    >
                      <img
                        src={process.env.REACT_APP_API_URL + img.url}
                        alt={img.name}
                        style={{
                          maxHeight: "200px",
                          margin: "25px 15px 10px 25px",
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              )}
            </TabPanel>
          ))}
      </SectionCard>
    </Grid>
  );
})`
  ${(props) => {
    if (!isWidthUp("md", props.width)) {
      return "flex:content; max-width: 100%;";
    }
  }}
`;

export default withWidth()(Content);
