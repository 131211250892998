import MenuItem from "@material-ui/core/MenuItem";
import { Link, useHistory } from "react-router-dom";
import Menu from "@material-ui/core/Menu";
// @ts-ignore
import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: "none",
    color: theme.palette.primary.main,
  },
}));

const EditMenu = ({ state, handleClose, dossierID }) => {
  const classes = useStyles();
  const { location } = useHistory();
  const currentFolder = location.pathname;

  const toAddFolder = dossierID
    ? "/dossiers/create?id=" + dossierID + "&currentFolder=" + currentFolder
    : "/dossiers/create";

  const toEditFolder =
    "/dossiers/" + state.dossierID + "?currentFolder=" + currentFolder;

  const toAddDocument = dossierID
    ? "/documents/create?id=" + dossierID + "&currentFolder=" + currentFolder
    : "/documents/create";

  const toEditDocument =
    "/documents/" + state.documentID + "?currentFolder=" + currentFolder;

  return (
    <Menu
      keepMounted
      open={state.mouseY !== null}
      onClose={handleClose}
      anchorReference="anchorPosition"
      anchorPosition={
        state.mouseY !== null && state.mouseX !== null
          ? { top: state.mouseY, left: state.mouseX }
          : undefined
      }
    >
      <Link className={classes.link} to={toAddFolder}>
        <MenuItem onClick={handleClose} disabled={!dossierID}>
          {" "}
          Ajouter un dossier
        </MenuItem>
      </Link>

      <Link className={classes.link} to={toAddDocument}>
        <MenuItem onClick={handleClose} disabled={!dossierID}>
          Ajouter un document
        </MenuItem>
      </Link>
      {state.dossierID && (
        <Link className={classes.link} to={toEditFolder}>
          <MenuItem onClick={handleClose}>Modifier un dossier</MenuItem>
        </Link>
      )}
      {state.documentID && (
        <Link className={classes.link} to={toEditDocument}>
          <MenuItem onClick={handleClose}>Modifier un document</MenuItem>
        </Link>
      )}
    </Menu>
  );
};
export default EditMenu;
