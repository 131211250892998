import React from "react";
import {
  Container,
  Grid,
  isWidthUp,
  WithWidth,
  withWidth
} from "@material-ui/core";
import {
  GridDirection,
  GridItemsAlignment,
  GridJustification
} from "@material-ui/core/Grid";
import styled from "styled-components";

interface SectionProps {
  id?: string;
  children: JSX.Element[] | JSX.Element;
  backgrounds?: JSX.Element[] | JSX.Element;
  justify?: GridJustification;
  direction?: GridDirection;
  alignItems?: GridItemsAlignment;
  className?: string;
}

const SectionGrid = withWidth()(styled(Grid)`
  ${props => {
    if (isWidthUp("md", props.width)) {
      return `
      padding-top: 75px !important;
      min-height: 100vh !important;`;
    }
  }}
  margin: 0;
  &::before,
  &::after {
    content: "";
    display: block;
    height: 50px;
    width: 100%;
  }
`);

const Section = styled(
  ({
    id,
    children,
    backgrounds,
    justify = "center",
    direction = "row",
    alignItems = "center",
    className
  }: SectionProps) => {
    return (
      <section id={id} className={className}>
        {backgrounds}
        <Container>
          <SectionGrid
            container
            justify={justify}
            direction={direction}
            alignItems={alignItems}
          >
            {children}
          </SectionGrid>
        </Container>
      </section>
    );
  }
)`
  position: relative !important;
`;

export default Section;
