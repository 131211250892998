import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import DescriptionIcon from "@material-ui/icons/Description";
import ListItemText from "@material-ui/core/ListItemText";
import { formatDate } from "../../helpers/formatDate";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import GetAppIcon from "@material-ui/icons/GetApp";
import ListItem from "@material-ui/core/ListItem";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { createStyles } from "@material-ui/core";
import download from "../../helpers/downloadDoc";

const useStyles = (color) =>
  makeStyles((theme) =>
    createStyles({
      avatar: {
        backgroundColor: theme.palette[color].dark,
      },
      bg: {
        background: "#fff",
      },
    })
  );

export const DocumentItem = ({ document, color = "primary" }) => {
  const classes = useStyles(color)();

  return (
    <ListItem
      button
      onClick={() => download(document)}
      className={classes.bg}
      data-documentid={document.id}
    >
      <ListItemAvatar>
        <Avatar className={classes.avatar}>
          <DescriptionIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={document.title + " - " + formatDate(document.eventdate)}
      />
      <ListItemSecondaryAction onClick={() => download(document)}>
        <IconButton edge="end" aria-label="download">
          <GetAppIcon color={color} />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};
