import React, { useState } from "react";
import {
  Box,
  Container,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import ArticleHeader from "../../components/ArticleHeader";
import SectionContent from "../../components/SectionContent";
import SectionCard from "../../components/SectionCard";
import BackButton from "../../components/BackButton";
import { ArticleDiv } from "../../Blog/AllArticles";
import { useParams } from "react-router";
import { getPage } from "../../REST/getContents";
import { connect } from "react-redux";
import DescriptionIcon from "@material-ui/icons/Description";
import { PRESSE_PAGE } from "../../components/MenuMain/mock";

const mapStateToProps = (state) => ({ page: state.pageReducer.payload });
const withStore = connect(mapStateToProps);

const SinglePage = ({ page }) => {
  let { pageId } = useParams();
  const [loading, setLoading] = useState(false);
  React.useEffect(() => {
    const getpage = async () => {
      await setLoading(true);
      await getPage(pageId);
      await setLoading(false);
    };
    getpage();
    return () => {
      // clean up
    };
  }, [pageId]);
  if (loading) return "En chargement...";
  if (!page) return "Chargement...";

  const headerOpt =
    page.url === PRESSE_PAGE
      ? { img: "presse-cover.jpg", height: "70vh" }
      : { img: "snsa-welcome.jpg" };

  return (
    <>
      <ArticleHeader
        img={headerOpt.img}
        title={page.title}
        height={headerOpt.height}
      />
      <ArticleDiv>
        <Container>
          <SectionCard opacity={0.9}>
            <SectionContent
              content={{
                text: page.text,
              }}
            />
            {page.Section &&
              page.Section.map((section) => (
                <Grid container>
                  <Box m={2} />
                  <Grid item xs={12}>
                    <Typography variant={"h3"} component={"h2"}>
                      {section.title}
                    </Typography>
                    <Typography variant={"body1"} component={"p"}>
                      {section.text}
                    </Typography>
                  </Grid>

                  {section.media &&
                    section.media.map((media) => {
                      if (media.ext === ".jpg" || media.ext === ".png")
                        return (
                          <Grid item xs={12} sm={6} md={3}>
                            <img
                              src={process.env.REACT_APP_MEDIA_URL + media.url}
                              alt={"image"}
                              width={"100%"}
                            />
                          </Grid>
                        );
                      return (
                        <Grid item xs={4} sm={3} md={2}>
                          <IconButton
                            aria-label="delete"
                            onClick={() =>
                              window.location.assign(
                                process.env.REACT_APP_MEDIA_URL + media.url
                              )
                            }
                          >
                            <DescriptionIcon fontSize="large" />
                          </IconButton>
                          <Typography variant={"caption"} component={"p"}>
                            {media.name}
                          </Typography>
                        </Grid>
                      );
                    })}
                </Grid>
              ))}
          </SectionCard>
          <BackButton />
        </Container>
      </ArticleDiv>
    </>
  );
};

export default withStore(SinglePage);
