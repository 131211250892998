import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import { isWidthUp, Typography, withWidth } from "@material-ui/core";
import StatIcon from "./StatIcon";
import SectionCard from "../../../../components/SectionCard";

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 775,
      settings: {
        slidesToShow: 3
      }
    },
    {
      breakpoint: 515,
      settings: {
        slidesToShow: 2
      }
    },{
      breakpoint: 400,
      settings: {
        slidesToShow: 1
      }
    }
  ]
};


const Stats = styled(({ className, statData }) => {
  return (
    <Slider {...settings} className={className}>
      {statData.map((data, index: number) => (
        <StatIcon {...data} key={index} />
      ))}
    </Slider>
  );
})`
  margin-top: 20px;
  margin-bottom: 30px;
  & .slick-arrow {
    width: 40px;
    height: 40px;
    &::before {
      font-size: 40px;
      opacity: 0.5;
      color: ${props => props.theme.colors.grey};
    }
  }
  & .slick-next {
    right: -35px;
  }
  & .slick-prev {
    left: -35px;
  }
`;

const LesChiffres = ({ width, statData }) => {
  return (
    <SectionCard
      opacity={0.75}
      roundedCorners={
        isWidthUp("md", width)
          ? [true, true, false, false]
          : [true, true, true, true]
      }
    >
      <Typography variant="h2" component="h2" align="center" gutterBottom>
        Le Secteur de l’Assistance
      </Typography>
      <Typography variant="h3" component="h3" align="center" gutterBottom>
        Les Chiffres clés en 2022
      </Typography>
      <Stats statData={statData} />
    </SectionCard>
  );
};

export default withWidth()(LesChiffres);
