import React from "react";
import { withTheme } from "styled-components";
import { SNSATheme } from "../../../types/styled.d";
import { Grid } from "@material-ui/core";
import ColorBackground from "../../../components/Background/ColorBackground";
import ImageBackground from "../../../components/Background/ImageBackground";
import Section from "../../../components/Section";
import LesChiffres from "./LesChiffres";
import MiniCard from "./MiniCard";

interface StatsProps {
  theme: SNSATheme;
  content: any;
}

const Stats = withTheme(({ theme, content }: StatsProps) => (
  <Section
    id="chiffres"
    backgrounds={
      <>
        <ImageBackground imagePath="snsa-chiffres.jpg" opacity={0.5} fixed />
        <ColorBackground color={theme.colors.orange} opacity={0.2} />
      </>
    }
  >
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <LesChiffres statData={content.stats_stats} />
        </Grid>
        {content.inserts.map(insert => {
          if (insert.section === "chiffre")
            return (
              <Grid item sm={6} key={insert.title}>
                <MiniCard
                  title={insert.title}
                  text={insert.text}
                  link={insert.link}
                />
              </Grid>
            );
        })}
      </Grid>
    </Grid>
  </Section>
));

export default Stats;
