import Grid from "@material-ui/core/Grid";
import { createStyles, Typography } from "@material-ui/core";
import React from "react";
import List from "@material-ui/core/List";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { DocumentItem } from "../../ContentItems/DocumentItem";

const useStyles = makeStyles((theme) =>
  createStyles({
    bg: {
      backgroundColor: theme.palette.background.paper,
    },
    title: {
      margin: theme.spacing(4, 0, 2),
    },
  })
);

export const DocumentList = ({ documents, title, color = "primary" }) => {
  const classes = useStyles();

  return (
    <Grid container justify={"flex-start"} spacing={1}>
      <Grid item xs={12}>
        <Typography variant={"h6"} color={color} className={classes.title}>
          {title}
        </Typography>
      </Grid>
      <Grid container spacing={1}>
        {documents.map(
          (document) =>
            document.media && (
              <Grid item key={document.id} xs={12} md={4} l={3}>
                <List>
                  <DocumentItem color={color} document={document} />
                </List>
              </Grid>
            )
        )}
      </Grid>
    </Grid>
  );
};
