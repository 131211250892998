import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
  ReferenceInput,
} from "react-admin";

const UserGroupCreate = (props) => {
  const PostTitle = () => {
    return <span>Créer un nouveau groupe</span>;
  };
  return (
    <Create title={<PostTitle />} {...props}>
      <SimpleForm>
        <TextInput source="name" label={"Nom du groupe"} />
        <ArrayInput
          source="users"
          label={"Choisissez les utilisateurs à ajouter dans le groupe"}
        >
          <SimpleFormIterator>
            <ReferenceInput
              source="id"
              reference={"users"}
              label={"Nom de l'utilisateur"}
              perPage={200}
            >
              <SelectInput optionText="username" />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput
          source="dossiers"
          label={"Donnez l'accès à un ou plusieurs dossiers"}
        >
          <SimpleFormIterator>
            <ReferenceInput
              source="id"
              reference={"dossiers"}
              label={"Nom du Dossiers"}
              perPage={1000}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
};

export default UserGroupCreate;
