import React from "react";
import styled from "styled-components";

interface HiddenTelLinkProps {
  phoneNumbers: string[];
  className?: string;
}

const HiddenTelLink = styled(
  ({ phoneNumbers, className }: HiddenTelLinkProps) => {
    let telToDisplay = phoneNumbers
      .reverse()
      .join("")
      .replace(/\d\d\+/g, "0");
    return (
      <span
        className={className}
        onClick={(event: React.MouseEvent<HTMLSpanElement>) => {
          event.preventDefault();
          const eventTarget = event.target as HTMLElement;
          const telToLink = eventTarget.innerHTML
            .split("")
            .reverse()
            .join("");

          document.location.href = "tel:" + telToLink;
        }}
      >
        {telToDisplay}
      </span>
    );
  }
)`
  unicode-bidi: bidi-override;
  direction: rtl;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export default HiddenTelLink;
