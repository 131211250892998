import React from "react";
import { List, Datagrid, TextField, SimpleList, UrlField } from "react-admin";
import { useMediaQuery } from "@material-ui/core";

export const ListCompanies = props => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down("sm"));

  return (
    <List {...props}>
      {isSmall ? (
        <SimpleList
          primaryText={record => record.name}
          secondaryText={record =>
            new Date(record.created_at).toLocaleDateString()
          }
        />
      ) : (
        <Datagrid rowClick="edit">
          <TextField source="id" />
          <TextField source="name" />
          <TextField source="location.id" />
          <UrlField source="url" />
          <TextField source="tel" />
        </Datagrid>
      )}
    </List>
  );
};
