import ListItemIcon from "@material-ui/core/ListItemIcon";
import DocumentIcon from "@material-ui/icons/AttachFile";
import ListItemText from "@material-ui/core/ListItemText";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import ListItem from "@material-ui/core/ListItem";
import React from "react";

export const ToggleButton = React.memo(({ setOpenLink, openLink, title }) => {
  const handleClick = () => {
    setOpenLink(!openLink);
  };
  return (
    <ListItem button onClick={handleClick}>
      <ListItemIcon>
        <DocumentIcon />
      </ListItemIcon>
      <ListItemText primary={title} />
      {openLink ? <ExpandLess /> : <ExpandMore />}
    </ListItem>
  );
});
