import React from "react";
import { Login } from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import MyLoginPage from "./MyLoginPage";

const styles = {
  main: { padding: "50px", background: "#015d82", "& h1": { color: "white" } },
};

const CustomLogin = (props) => {
  return (
    <div className={props.classes.main}>
      <Typography variant={"h1"} component={"h1"} align={"center"}>
        Identifiez-vous SVP
      </Typography>
      <Login title={"Centre documentaire"} backgroundImage={null} {...props}>
        {" "}
        <MyLoginPage />{" "}
        <NavLink to={"/"}>
          <Typography
            variant={"body1"}
            color={"primary"}
            align={"center"}
            style={{ margin: "10px" }}
          >
            Revenir à l'accueil
          </Typography>
        </NavLink>
      </Login>
    </div>
  );
};

export default withStyles(styles)(CustomLogin);
