import React from "react";
import { Layout } from "react-admin";
import CustomMenu from "./Menu/index";
import CustomAppBar from "./AppBar";

const CustomLayout = (props) => (
  <Layout {...props} menu={CustomMenu} appBar={CustomAppBar} />
);

export default CustomLayout;
