const dataWorks = [
  {
    title: "Opérations d’Assistance",
    text:
      "CHARGE(E) D’ACCUEIL D’ASSISTANCE\n" +
      "\n" +
      "CHARGE(E) D’ASSISTANCE\n" +
      "\n" +
      "ENCADRANT(E) D’ASSISTANCE\n" +
      "\n" +
      "AUTRES METIERS : SPECIALISTES D’ASSISTANCE\n" +
      "\n" +
      "ASSISTANT(E) ADMINISTRATIF(VE) DE PLATEAUX\n" +
      "\n" +
      "CHARGE(E) DE RESERVATION\n" +
      "\n" +
      "TECHNICIEN(NE) D’ASSISTANCE VEHICULE"
  },
  {
    title: "Activités médicales",
    text:
      "MEDECIN REGULATEUR(TRICE)\n" +
      "\n" +
      "ASSISTANT(E) DE REGULATION MEDICALE\n" +
      "\n" +
      "INFIRMIER(E) DE TRANSPORT MEDICAL\n" +
      "\n" +
      "MEDECIN DE TRANSPORT MEDICAL (F/H)\n" +
      "\n" +
      "INFIRMIER(E) MATERIEL MEDICAL\n" +
      "\n"
  },
  {
    title: "Support et Organisation Assistance",
    text:
      "PLANIFICATEUR (TRICE)\n" +
      "\n" +
      "COORDINATEUR(TRICE) OPERATIONNEL(LE) PLATEAUX\n" +
      "\n" +
      "CHARGE(E) DE METHODES ET PROCEDURES\n" +
      "\n"
  },
  {
    title: "Gestion d’assistance",
    text:
      "GESTIONNAIRE DE DOSSIERS D’ASSISTANCE (F/H)\n" +
      "\n" +
      "GESTIONNAIRE DES FACTURES PRESTATAIRES (F/H)\n" +
      "\n" +
      "CHARGE(E) DE RECLAMATIONS CLIENTS"
  },
  {
    title: "Réseau de prestataires",
    text:
      "CHARGE(E) D’ADMINISTRATION PRESTATAIRES\n" +
      "\n" +
      "ANIMATEUR(TRICE) DE RESEAU DE PRESTATAIRES"
  },
  {
    title: "Commercial et Marketing",
    text:
      "CHARGE(E) DE GESTION COMMERCIALE\n" +
      "\n" +
      "COMMERCIAL(E) CLIENTS ENTREPRISES\n" +
      "\n" +
      "CHEF DE PRODUIT MARKETING (F/H)"
  },
  {
    title: "Informatique et Téléphonie",
    text:
      "TECHNICIEN(NE) INFORMATIQUE\n" +
      "\n" +
      "ADMINISTRATEUR(TRICE) RESEAUX ET SYSTEMES\n" +
      "\n" +
      "ADMINISTRATEUR (TRICE) TELEPHONIE\n" +
      "\n" +
      "ADMINISTRATEUR(TRICE) DE BASE DE DONNEES\n" +
      "\n" +
      "INFORMATICIEN(NE) D’ETUDE ET DEVELOPPEMENT\n" +
      "\n" +
      "CHEF DE PROJET INFORMATIQUE (F/H)"
  },
  {
    title: "Ressources humaines",
    text:
      "GESTIONNAIRE PAIE ET ADMINISTRATION DU PERSONNEL (F/H)\n" +
      "\n" +
      "CHARGE(E) DE RECRUTEMENT ET CARRIERES\n" +
      "\n" +
      "CHARGE(E) DE FORMATION\n" +
      "\n" +
      "FORMATEUR(TRICE) D’ASSISTANCE"
  },
  {
    title: "Autres métiers Support ou Transverses",
    text:
      "SECRETAIRE/ASSISTANT(E)\n" +
      "\n" +
      "CHARGE(E) D’ETUDES\n" +
      "\n" +
      "CHARGE(E) DE COMPTABILITE\n" +
      "\n" +
      "CONTROLEUR DE GESTION (F/H)\n" +
      "\n" +
      "CHARGE(E) DE LA QUALITE\n" +
      "\n" +
      "JURISTE (F/H)\n" +
      "\n"
  }
];

export default dataWorks;
