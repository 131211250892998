import { Grid, isWidthUp, useTheme, withWidth } from "@material-ui/core";
import styled, { withTheme } from "styled-components";
import React, { SetStateAction, useState } from "react";
import ColorBackground from "../../../components/Background/ColorBackground";
import OverlayBackground from "../../../components/Background/OverlayBackground";
import Section from "../../../components/Section";
import Menu from "./Menu";
import Content from "./Content";

const Works = styled(({ theme, className, content }) => {
  const muiTheme = useTheme();
  const [position, setPosition] = useState(0);

  const handleClick = (event: SetStateAction<number>) => {
    setPosition(event);
    window.dispatchEvent(new Event("resize")); // forcing fullpage refresh
  };

  const handleChangeIndex = (index: number) => {
    setPosition(index);
  };
  return (
    <Section
      id="metiers"
      backgrounds={
        <>
          <ColorBackground color={theme.colors.blue2} opacity={0.8} />
          <OverlayBackground overlay="19" opacity={0.1} />
        </>
      }
      className={className}
      alignItems="center"
    >
      <Grid container spacing={2}>
        <Content
          handleChangeIndex={handleChangeIndex}
          position={position}
          muiTheme={muiTheme}
          slides={content.slides}
        />
        <Menu
          handleClick={handleClick}
          menuMetier={content.menuMetier}
          menuAssistance={content.menuAssistance}
        />
      </Grid>
    </Section>
  );
})`
  ${props => {
    if (!isWidthUp("md", props.width)) {
      return "& .MuiGrid-root { flex-direction: column-reverse; }";
    }
  }}
  & h3 {
    padding-bottom: 10px;
  }
  & p + table {
    margin-top: 10px;
  }
  & table {
    width: 100% !important;
    & img {
      width: 100% !important;
    }
  }
`;

export default withWidth()(withTheme(Works));
