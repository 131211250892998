import React from "react";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  position: number;
  index: number;
}

export const TabPanel = ({
  children,
  index,
  position,
  ...other
}: TabPanelProps) => (
  <div
    role="tabpanel"
    hidden={index !== position}
    id={`simple-tabpanel-${position}`}
    aria-labelledby={`simple-tab-${position}`}
    {...other}
  >
    {children}
  </div>
);
