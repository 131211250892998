import React from "react";
import { Grid, isWidthUp, Link, WithWidth, withWidth } from "@material-ui/core";
import {COMPANY_TITLE} from "../../config/config";
import Section from "../Section";
import styled from "styled-components";
import ColorBackground from "../Background/ColorBackground";

interface CopyrightProps extends WithWidth {
  className?: string;
}

const Copyright = withWidth()(styled(({ className }: CopyrightProps) => {
  const date = new Date();
  const year = date.getFullYear();
  return (
    <Section
      backgrounds={<ColorBackground color={"rgba(74, 84, 102, 0.8)"} />}
      className={className + " fp-auto-height"}
      id="section-copyright"
    >
      <Grid item xs={12}>
        Droits réservés {COMPANY_TITLE} / Copyright © {year}
        <br />
        <Link href="/mentions-legales">mentions légales</Link>
      </Grid>
    </Section>
  );
})`
  color: white;
  & a {
    color: white;
    text-decoration: none;
  }
  & .MuiContainer-root {
    position: relative !important;
  }
  & .MuiGrid-root {
    min-height: auto !important;
    z-index: 2;
    ${props => {
      if (isWidthUp("md", props.width)) {
        return `
    padding-top: 20px !important;
    padding-bottom: 20px !important;`;
      }
    }}
  }
`);

export default Copyright;
