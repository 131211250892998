import React from "react";
import styled from "styled-components";
import HiddenMailLink from "../HiddenMailLink";
import HiddenTelLink from "../HiddenTelLink";
import { domainFromUrl } from "../../helpers/domainFromUrl";
import { Typography } from "@material-ui/core";

export interface VCardProps {
  imagePath?: string;
  url?: string;
  organization?: string;
  role?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  tel?: string;
  streetAddress?: string;
  postalCode?: string;
  locality?: string;
  countryName?: string;
  note?: string;
  showImage?: boolean;
  linkOnImage?: boolean;
  showName?: boolean;
  linkOnName?: boolean;
  showOrganization?: boolean;
  linkOnOrganization?: boolean;
  showUrl?: boolean;
  className?: string;
  urLabel?: string;
}

const ImgDiv = styled.div`
  overflow: hidden;
  height: 150px;
  width: 150px;
  margin: 0 auto 10px;
  border-radius: 50%;
  & a {
    display: block;
    height: 150px;
    & img {
      height: 100% !important;
    }
  }
`;
const ProfilPic = styled.img`
  width: 100% !important;
  height: auto !important;
  margin: 0 !important;
  border-radius: 0 !important;
`;

const VCard = styled(
  ({
    imagePath,
    url,
    organization,
    role,
    firstName,
    lastName,
    email,
    tel,
    streetAddress,
    postalCode,
    locality,
    countryName,
    note,
    showImage = true,
    linkOnImage = true,
    showName = true,
    linkOnName = true,
    showOrganization = true,
    linkOnOrganization = true,
    showUrl = true,
    className,
    urLabel,
  }: VCardProps) => {
    const title =
      firstName && lastName
        ? firstName + " " + lastName
        : organization
        ? organization
        : " ";
    const hCardId = "hcard" + (title && "-" + title.toLowerCase());
    return (
      <div className={className}>
        <address id={hCardId} className="vcard">
          {showImage && imagePath && (
            <ImgDiv>
              {linkOnImage && url ? (
                <a href={url} target="_blank">
                  <ProfilPic src={imagePath} alt={title} />
                </a>
              ) : (
                <ProfilPic src={imagePath} alt={title} />
              )}
            </ImgDiv>
          )}
          {showName && (firstName || lastName) && (
            <div>
              {linkOnName && url ? (
                <a className="url fn" href={url} target="_blank">
                  {[firstName, lastName].join(" ")}
                </a>
              ) : (
                <Typography variant={"overline"} component={"p"} className="fn">
                  {" "}
                  {[firstName, lastName].join(" ")}
                </Typography>
              )}
            </div>
          )}
          {showOrganization && organization && (
            <div>
              {linkOnOrganization && url ? (
                <a className="url org" href={url} target="_blank">
                  {organization}
                </a>
              ) : (
                <span className="org">{organization}</span>
              )}
            </div>
          )}
          {role && <div className="role">{role}</div>}
          {streetAddress ||
            postalCode ||
            locality ||
            (countryName && (
              <div className="adr">
                {streetAddress && (
                  <div className="street-address">
                    {streetAddress.split("\n").map((item, key) => {
                      return (
                        <React.Fragment key={key}>
                          {item}
                          <br />
                        </React.Fragment>
                      );
                    })}
                  </div>
                )}
                {postalCode ||
                  (locality && (
                    <div>
                      {postalCode && (
                        <span className="postal-code">{postalCode}</span>
                      )}
                      {locality && <span className="locality">{locality}</span>}
                    </div>
                  ))}
                {countryName && (
                  <div className="country-name">{countryName}</div>
                )}
              </div>
            ))}
        </address>
        {email && (
          <div>
            <HiddenMailLink emailLetters={email.split("")} />
          </div>
        )}
        {tel && (
          <div>
            <HiddenTelLink
              phoneNumbers={tel
                .split(/(\d{2})/)
                .join(" ")
                .split("")}
            />
          </div>
        )}
        {url && showUrl && (
          <div>
            <a href={url} target="_blank">
              {urLabel ? urLabel : domainFromUrl(url)}
            </a>
          </div>
        )}
        {note && (
          <Typography variant={"body1"} component={"p"}>
            {note}
          </Typography>
        )}
      </div>
    );
  }
)`
  & address {
    font-style: normal;
  }
`;

export default VCard;
