type Slug = string;

const getDossier = (path: Slug[], filters: object) => {
  return {
    type: "getList",
    resource: "findOneBySlug",
    payload: {
      filter: {
        path: path,
        ...filters,
      },
      sort: { field: "id", order: "DESC" },
      pagination: { page: 1, perPage: 20 },
    },
  };
};
export default getDossier;
