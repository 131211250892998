import {Resource} from "react-admin";
import PostIcon from "@material-ui/icons/Book";
import React from "react";
import {capLetter} from "../../../helpers/capLetter";
import compo from "../../components/admin/contents/index";

export const contentResourceTypes = [
  "commissions",
  "companies",
  "members",
  "periods",
  "posts",
  "slides",
  "stats",
  "member-categories",
];
const title = [
  "Les commissions",
  "Les compagnies",
  "Les membres",
  "Périodes (historique)",
  "Blog post",
  "Slides",
  "Statistiques",
];

export const frontResources = contentResourceTypes.map((type, i) => {
  if (type === "member-categories") return <Resource name={type} />;
  let capType = capLetter(type);

  return (
    <Resource
      name={type}
      list={compo["List" + capType]}
      edit={compo["Edit" + capType]}
      create={compo["Create" + capType]}
      icon={PostIcon}
      options={{ label: title[i] }}
    />
  );
});
