import React from "react";
import { Admin } from "react-admin";
import authProvider from "./authProvider";
import CustomLayout from "./Layout";
import { history } from "../store";
import { ROUTES_CUSTOM_RA } from "../routes";
import { dataProvider } from "./dataProvider";
import { adminResources } from "./resources/admin";
import { memberRessource } from "./resources/member";
import CustomLogin from "./components/Login";
import { ROLE } from "./domain/role";
import { groupAdminResources } from "./resources/groupAdmin";

const AdminSnsa = () => {
  return (
    <Admin
      customRoutes={ROUTES_CUSTOM_RA}
      layout={CustomLayout}
      authProvider={authProvider}
      dataProvider={dataProvider}
      history={history}
      loginPage={CustomLogin}
    >
      {(permissions) => {
        console.log("permissions", permissions);
        return permissions.role === ROLE.admin
          ? adminResources
          : permissions.role === ROLE.groupAdmin
          ? groupAdminResources
          : memberRessource;
      }}
    </Admin>
  );
};

export default AdminSnsa;
