import React from "react";
import { DateInput, Filter, TextInput } from "react-admin";

export const DocumentFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Par titre" source="q" alwaysOn />
    <DateInput source="eventdate_gte" label={"Date debut"} />
    <DateInput source="eventdate_lte" label={"Date fin"} />
  </Filter>
);
