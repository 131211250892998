import { UserState } from "../../../store/reducers/user.reducer";
import Cookies from "../../helpers/Cookies/Cookies";

export const setCookies = (response) => {
  Cookies.setCookie("token", response.jwt, Cookies.duration);
  Cookies.setCookie("userID", response.user.id, Cookies.duration);
  Cookies.setCookie("role", response.user.role.name, Cookies.duration);
};

const getNavigation = (user_groups) => {
  const userDossiers = user_groups.reduce((p, c) => [...p, ...c.dossiers], []);
  let navigation = [];

  if (userDossiers.length > 0) {
    //seulement les dossiers de niveau 0 consituent la nav
    userDossiers.forEach(
      (dossier) =>
        !dossier.parent &&
        navigation.push({ href: dossier.slug, name: dossier.name })
    );
  }
  return navigation;
};

export const USER = (user): UserState => {
  return {
    lastlogin: user.lastlogin,
    isFirstTime: !user.lastlogin,
    isAuthent: true,
    groups: user.user_groups,
    role: user.role.name,
    navigation: getNavigation(user.user_groups),
    account: {
      id: user.id,
      last_name: user.last_name,
      first_name: user.first_name,
      email: user.email,
    },
  };
};
