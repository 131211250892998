import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  ImageField,
  ImageInput,
} from "react-admin";

export const EditPosts = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="title" />
        <BooleanInput source="enabled" />
        <TextInput source="text" fullWidth multiline />
        <ImageInput source="img" label="Ajouter une image" accept="image/*">
          <ImageField source={"url"} title={"name"} />
        </ImageInput>
      </SimpleForm>
    </Edit>
  );
};
