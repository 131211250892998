import React from "react";
import {
  ArrayInput,
  Edit,
  ImageField,
  ImageInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from "react-admin";

export const EditMembers = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="first_name" label={"Prénom"} />
        <TextInput source="last_name" labe={"Nom"} />
        <TextInput source="role" />
        <TextInput source="text" fullWidth multiline />
        <ImageInput source="photo" label="Photo du membre" accept="image/*">
          <ImageField source={"url"} title={"Nom de l'image"} />
        </ImageInput>

        <ArrayInput
          source="member_categories"
          label={"Choisissez une ou plusieurs catégories"}
        >
          <SimpleFormIterator>
            <ReferenceInput
              source="id"
              reference={"member-categories"}
              label={"Nom de la catégorie"}
            >
              <SelectInput optionText="title" />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};
