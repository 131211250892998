import React, { useState } from "react";
import { Typography } from "@material-ui/core";
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import MuiExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import { withStyles } from "@material-ui/core/styles";
import { theme } from "../../themes/theme";
import dataWorks from "./mock";

const ExpansionPanel = withStyles({
  root: {
    border: "none",
    borderRadius: "0 0 5px 0",
    marginBottom: "5px",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0
    },
    "&:before": {
      display: "none"
    },
    "&$expanded": {
      margin: "auto"
    }
  },
  expanded: {}
})(MuiExpansionPanel);
const ExpansionPanelSummary = withStyles({
  root: {
    padding: "15px",
    backgroundColor: "white",
    border: "none",
    textTransform: "uppercase",
    margin: 0,
    minHeight: 30,
    "&$expanded": {
      minHeight: 30
    }
  },
  content: {
    margin: 0,
    "&$expanded": {
      margin: 0
    }
  },
  expanded: {}
})(MuiExpansionPanelSummary);
const ExpansionPanelDetails = withStyles(muiTheme => ({
  root: {
    padding: "15px",
    background: theme.colors.grey,
    border: "none"
  }
}))(MuiExpansionPanelDetails);

const EmploiTab = () => {
  const [expanded, setExpanded] = useState("panel0");
  const handleChange = (panel: any) => (event: any, newExpanded: any) => {
    setExpanded(newExpanded ? panel : false);
  };
  const panels: any = [];
  dataWorks.map((data, key) => {
    panels.push(
      <ExpansionPanel
        square
        expanded={expanded === "panel" + key}
        onChange={handleChange("panel" + key)}
        key={key}
      >
        <ExpansionPanelSummary
          key={"S" + key}
          aria-controls={"panel" + key + "d-content"}
          id={"panel" + key + "d-header"}
        >
          <Typography>{data.title}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails key={"D" + key}>
          <Typography style={{ color: "white" }}>{data.text}</Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  });
  return <div>{panels}</div>;
};

export default EmploiTab;
