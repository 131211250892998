export interface User {
  isAuthent: boolean;
  groups?: string[];
  role?: "membre" | "admin" | string;
  account?: {
    id: number;
    last_name: string;
    first_name: string;
    email: string;
  };
  isFirstTime?: boolean;
  lastlogin?: string;
  dossiers?: string[];
  navigation?: string[];
}

export interface UserState extends User {}

interface ActionUser {
  type: "SET_USER" | "FIRST_TIME";
  payload: any;
}

export const initialUserState: UserState = { isAuthent: false };

const userReducer = (state = initialUserState, action: ActionUser) => {
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        ...action.payload,
      };
    case "FIRST_TIME":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
