import React from "react";
import { Typography } from "@material-ui/core";
import MemberList from "./MemberList";

const OrgaChart = ({ members }) => {
  const filterM = (id) =>
    members.filter((member) =>
      member.member_categories.find((cat) => cat.id === id)
    );
  const bureauMembers = filterM(1);
  const adminMembers = filterM(2);

  return (
    <div>
      <Typography variant="h4" component="h4">
        Conseil d'administration
      </Typography>
      <MemberList members={adminMembers} />

      <Typography variant="h4" component="h4">
        Bureau
      </Typography>
      <MemberList members={bureauMembers} />
    </div>
  );
};

export default OrgaChart;
