import React from "react";
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  EmailField,
  ArrayField,
  SingleFieldList,
  ChipField,
} from "react-admin";

const UserList = (props) => (
  <List perPage={100} {...props} sort={{ field: "last_name", order: "ASC" }}>
    <Datagrid rowClick="edit">
      <TextField source="last_name" />
      <TextField source="first_name" />
      <TextField source="username" />
      <TextField source="role.name" sortable={false} label={"Role"} />
      <EmailField source="email" />
      <BooleanField source="confirmed" />
      <ArrayField source="user_groups">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
    </Datagrid>
  </List>
);

export default UserList;
