import React, { MutableRefObject, Ref } from "react";
import News from "./sections/News";
import Adherents from "./sections/Adherents";
import Stats from "./sections/Stats";
import Works from "./sections/Works";
import About from "./sections/About";
import Welcome from "./sections/Welcome";
import Copyright from "../components/Copyright";
import Footer from "../components/Footer";
import HeaderMain from "../components/HeaderMain";
import { getContents } from "../REST/getContents";
import { connect } from "react-redux";
import { menuItems } from "../components/MenuMain/mock";
import { ScrollInfo, useScrollEvent } from "../useScrollEvent";

const mapStateToProps = (state) => ({
  content: state.homeReducer.payload,
});

const withStore = connect(mapStateToProps);
const getClosest = () =>
  [...menuItems].sort((a, b) => {
    const firstElementAbsoluteOffset =
      document.querySelector(`#${a.fragments}`).getClientRects()[0].y &&
      Math.abs(document.querySelector(`#${a.fragments}`).getClientRects()[0].y);
    const secondElementAbsoluteOffset =
      document.querySelector(`#${b.fragments}`).getClientRects()[0].y &&
      Math.abs(document.querySelector(`#${b.fragments}`).getClientRects()[0].y);
    return firstElementAbsoluteOffset - secondElementAbsoluteOffset;
  })[0];

const getCurrentIndex = () =>
  Math.max(
    menuItems.findIndex(
      (item) => `#/${item.fragments}` === window.location.hash
    ),
    0
  );
const getPrevious = () => menuItems[(getCurrentIndex() || 1) - 1];
const getNext = () => menuItems[getCurrentIndex() + 1];

// Ugly, but waiting hopefully for https://github.com/w3c/csswg-drafts/issues/3744#issuecomment-564821823
const restoreScrollListening = (enableListening, delay) => {
  setTimeout(enableListening, delay);
};

const scrollTo = (
  disableScrollListeningRef: MutableRefObject<boolean>,
  delay = 1500
) => (id: string) => {
  disableScrollListeningRef.current = true;
  document.querySelector(`#${id}`).scrollIntoView({ behavior: "smooth" });
  restoreScrollListening(
    () => (disableScrollListeningRef.current = false),
    delay
  );
};
const scrollToPrevious = (
  disableScrollListeningRef: MutableRefObject<boolean>
) => {
  const previous = getPrevious();
  scrollTo(disableScrollListeningRef, 1000)(previous.fragments);
  window.history.pushState({}, previous.name, `#/${previous.fragments}`);
};
const scrollToNext = (disableScrollListeningRef: MutableRefObject<boolean>) => {
  const next = getNext();
  if (next) {
    scrollTo(disableScrollListeningRef, 1000)(next.fragments);
    window.history.pushState({}, next.name, `#/${next.fragments}`);
  }
};
const scrollToClosest = (
  disableScrollListeningRef: MutableRefObject<boolean>
) => {
  const closest = getClosest();
  scrollTo(disableScrollListeningRef, 500)(closest.fragments);
  window.history.pushState({}, closest.name, `#/${closest.fragments}`);
};

const Home = ({ content }) => {
  const disableScrollListening = React.useRef<boolean>(true);
  React.useEffect(() => {
    getContents();
  }, []);

  useScrollEvent({
    disableScrollListeningRef: disableScrollListening,
    onScrollEnd: (scrollInfo: ScrollInfo) => {
      if (scrollInfo.distance.y < 800) {
        if (scrollInfo.direction.y === "up") {
          scrollToPrevious(disableScrollListening);
        }
        if (scrollInfo.direction.y === "down") {
          scrollToNext(disableScrollListening);
        }
      } else {
        scrollToClosest(disableScrollListening);
      }
    },
  });

  if (!content) return "Chargement...";

  return (
    <>
      <HeaderMain scrollTo={scrollTo(disableScrollListening)} />
      <Welcome
        content={{
          welcome_title: content.welcome_title,
          welcome_text: content.welcome_text,
        }}
      />
      <About
        content={{
          menuAbout:
            (content.menus &&
              content.menus.about &&
              content.menus.about.links) ||
            [],
          aboutSlides: content.about_slides,
          commissions: content.commissions,
          members: content.members,
          periods: content.periods,
        }}
      />
      <Adherents
        content={{
          companies_title: content.companies_title,
          companies_text: content.companies_text,
          companies: content.companies_companies,
        }}
      />
      <Works
        content={{
          slides: content.works_slides,
          menuMetier: content.menus.metier,
          menuAssistance: content.menus.assistance,
        }}
      />
      <Stats
        content={{ stats_stats: content.stats_stats, inserts: content.inserts }}
      />
      <News posts={content.articles} />
      <Footer />
      <Copyright />
    </>
  );
};

export default withStore(Home);
