import Cookies from "./helpers/Cookies/Cookies";
import axios from "axios";

const formatFilesForProvider = (
  resource,
  params,
  convertDataRequestToHTTP,
  convertHTTPResponse,
  apiUrl,
  httpClient,
  type
) => {
  if (!params || !params.data) return null;
  if (
    resource === "documents" ||
    resource === "members" ||
    resource === "users" ||
    resource === "posts" ||
    resource === "companies"
  ) {
    if (
      (params.data.photo && params.data.photo.rawFile) ||
      (params.data.logo && params.data.logo.rawFile) ||
      (params.data.img && params.data.img.rawFile) ||
      (params.data.media && params.data.media.rawFile)
    ) {
      let modelReq;
      if (params.data.photo) {
        modelReq = "photo";
      }
      if (params.data.logo) {
        modelReq = "logo";
      }
      if (params.data.media) {
        modelReq = "media";
      }
      if (params.data.img) {
        modelReq = "img";
      }

      const fileToSend = params.data[modelReq].rawFile;

      let formData = new FormData();
      formData.append("files", fileToSend, fileToSend.name);
      const token = Cookies.getCookie("token");
      return axios
        .post(apiUrl + "/upload", formData, {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then((response) => {
          params.data[modelReq] = response.data[0].id;
          const { url, options } = convertDataRequestToHTTP(
            type,
            resource,
            params
          );
          return httpClient(url, options).then((response) => {
            return convertHTTPResponse(response, type, resource, params);
          });
        });
    }
  }
  return null;
};

export default formatFilesForProvider;
