import React from "react";
import { List, Datagrid, TextField, SimpleList } from "react-admin";
import { useMediaQuery } from "@material-ui/core";

export const ListCommissions = (props) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <List {...props}>
      {isSmall ? (
        <SimpleList
          primaryText={(record) => record.name}
          secondaryText={(record) =>
            new Date(record.created_at).toLocaleDateString()
          }
        />
      ) : (
        <Datagrid rowClick="edit">
          <TextField source="title" />
          <TextField source="text" />
          <TextField source="order" />
        </Datagrid>
      )}
    </List>
  );
};
