import React from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { Post, Tag } from "./mock";
import styled from "styled-components";
import { getExcerpt } from "../../helpers/getExcerpt";
import { Link } from "react-router-dom";

interface PostCardProps {
  content: Post;
}

const ImgPostCard = styled.img`
  max-width: 100%;
`;

const TagButton = styled(({ url, title, index, className }) => (
  <Button className={className} href={url} key={index}>
    {title}
  </Button>
))`
  color: white;
  background: ${(props) => props.theme.colors.orange};
  margin: 0 5px 0 0;
  border-radius: 20px;
  padding: 3px 10px 4px 10px;
`;

const PostCard = ({
  content: { title, description, img, post_categories, id },
}: PostCardProps) => {
  return (
    <Grid container spacing={2} direction={"row"}>
      {img && (
        <Grid item md={3}>
          <ImgPostCard
            src={
              process.env.REACT_APP_MEDIA_URL + img.url || "snsa-welcome.jpg"
            }
            onError={(e) => (e.target.src = "snsa-welcome.jpg")}
            alt={img.alt}
          />
        </Grid>
      )}
      <Grid item md={9}>
        <Typography variant={"h5"} component={"h3"}>
          {title}
        </Typography>
        {description && (
          <Typography variant={"body1"} component={"p"}>
            {getExcerpt(description, 200)}
          </Typography>
        )}
        <Grid container spacing={2} style={{ paddingTop: "10px" }}>
          <Grid item xs={9}>
            {post_categories &&
              post_categories.map((tag: Tag, index: number) => (
                <TagButton
                  key={index}
                  index={index}
                  title={tag.title}
                  url={tag.url}
                />
              ))}
          </Grid>
          <Grid item xs={3}>
            <Link to={"articles/" + id}>
              <Button variant={"outlined"}>Voir l'article</Button>
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PostCard;
