import Logo from "../Logo";
import styled from "styled-components";

interface HeaderLogoProps {
  isTop: boolean;
  isDesktop: boolean;
}

const getLogoReducedHeight = ({ isDesktop }) => {
  return isDesktop ? 70 : 50;
};
const getLogoNormalHeight = ({ isDesktop }) => {
  return isDesktop ? 100 : 80;
};
const getLogoHeight = ({ isTop, isDesktop }) => {
  return isTop
    ? getLogoNormalHeight({ isDesktop })
    : getLogoReducedHeight({ isDesktop });
};
const getLogoReducedPadding = ({ isDesktop }) => {
  return isDesktop ? "10px 0" : "8px 0";
};
const getLogoNormalPadding = ({ isDesktop }) => {
  return isDesktop ? "15px 0" : "12px 0";
};
const getLogoPadding = ({ isTop, isDesktop }) => {
  return isTop
    ? getLogoNormalPadding({ isDesktop })
    : getLogoReducedPadding({ isDesktop });
};

const getCatchphraseDisplay = ({ isTop }: HeaderLogoProps) => {
  return isTop ? 1 : 0;
};

const HeaderLogo = styled(Logo)<HeaderLogoProps>`
  height: ${getLogoHeight}px;
  padding: ${getLogoPadding};
  transition: all 0.25s;
  & g:nth-child(2) {
    transition: opacity 0.25s;
    opacity: ${getCatchphraseDisplay};
  }
`;

export default HeaderLogo;
