import React from "react";
import styled from "styled-components";
import { withTheme } from "styled-components";
import { Grid, Typography } from "@material-ui/core";
import ColorBackground from "../../../components/Background/ColorBackground";
import SectionCard from "../../../components/SectionCard";
import Section from "../../../components/Section";
import CompaniesCarousel from "../../../components/CompaniesCarousel";

const TitleCard = styled(({ className, content }) => (
  <div className={className}>
    <SectionCard opacity={0.8} roundedCorners={[true, true, false, false]}>
      <Typography variant="h2" component="h2" align="center">
        {content.companies_title}
      </Typography>
      <Typography variant="body1" component="p" align="center">
        {content.companies_text}
      </Typography>
    </SectionCard>
  </div>
))`
  margin-bottom: 16px !important;
  & h2 {
    margin-bottom: 8px !important;
  }
`;

const Adherents = ({ theme, content }) => (
  <Section
    id="adherents"
    backgrounds={
      <ColorBackground color={theme.colors.greyLighter} opacity={0.4} />
    }
  >
    <Grid item xs={12}>
      <TitleCard content={content} />
      <SectionCard opacity={0.75} roundedCorners={[false, false, true, true]}>
        <CompaniesCarousel companies={content.companies} />
      </SectionCard>
    </Grid>
  </Section>
);

export default withTheme(Adherents);
