import React from "react";
import { Create, SimpleForm, TextInput } from "react-admin";

export const CreateStats = props => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="label" />
        <TextInput source="value" />
      </SimpleForm>
    </Create>
  );
};
