import React from "react";
import { Edit, SimpleForm, TextInput } from "react-admin";

export const EditSlides = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="title" fullWidth />
        <TextInput source="text" multiline fullWidth />
      </SimpleForm>
    </Edit>
  );
};
