import React from "react";
import { Container, Grid, Typography } from "@material-ui/core";
import SectionCard from "../SectionCard";
import styled from "styled-components";

interface ArtHeaderProps {
  img: string;
  title: string;
  height?: string;
}

interface ArtContainerHeaderProps {
  img: string;
}

const ArtContainerHeader = styled.div<ArtContainerHeaderProps>`
  background-image: url(${(props) => props.img || "snsa-welcome.jpg"});
  background-size: cover;
  background-position: center center;
  height: ${(props) => props.height || "45vh"};
`;
const ArticleHeader = ({ img, title }: ArtHeaderProps) => (
  <ArtContainerHeader img={img}>
    <Grid
      container
      justify={"center"}
      alignItems={"center"}
      style={{ height: "100%", margin: "auto" }}
    >
      <Grid item xs={12}>
        <Container>
          <SectionCard roundedCorners={[true, true, true, true]}>
            <Typography variant={"h1"} component={"h1"} align={"center"}>
              {title}
            </Typography>
          </SectionCard>
        </Container>
      </Grid>
    </Grid>
  </ArtContainerHeader>
);

export default ArticleHeader;
