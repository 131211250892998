import { documentRessourceTypes } from "../../resources/admin/document";
import { contentResourceTypes } from "../../resources/admin/content";

export const resourceArrays = (resources) => {
  const docs = [];
  const contents = [];
  resources.forEach(
    (res) =>
      (documentRessourceTypes.includes(res.name) &&
        res.name !== "users-permissions/roles" &&
        docs.push(res)) ||
      (contentResourceTypes.includes(res.name) && contents.push(res))
  );
  return {
    contents,
    docs,
  };
};
