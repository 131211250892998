import { useAxios } from "./useAxios";
import store from "../store";
import {
  sendArticleContent,
  sendContent,
  sendPageContent,
  sendSingleArticleContent,
} from "../store/actions/contents.action";

export const getContents = async () => {
  try {
   const res = await useAxios.get("homes");
    store.dispatch(sendContent(res.data));
  } catch (e) {
    throw new Error(e);
  }
};

export const getPage = async (url) => {
  let pageContent;
  try {
    pageContent = await useAxios.get("pages/" + url);
  } catch (e) {
    throw new Error(e);
  }
  store.dispatch(sendPageContent(pageContent.data[0]));
};
export const getArticles = async () => {
  let content;
  try {
    content = await useAxios.get("posts");
  } catch (e) {
    throw new Error(e);
  }
  await store.dispatch(sendArticleContent(content.data));
};
export const getSingleArticle = async (id) => {
  let content;
  try {
    content = await useAxios.get("posts/" + id);
  } catch (e) {
    throw new Error(e);
  }
  await store.dispatch(sendSingleArticleContent(content.data));
};
