import React from "react";
import { Create, NumberInput, SimpleForm, TextInput } from "react-admin";

export const CreateCommissions = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="title" fullWidth />
        <TextInput source="text" multiline fullWidth />
        <NumberInput source="order" />
      </SimpleForm>
    </Create>
  );
};
