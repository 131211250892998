import { Typography } from "@material-ui/core";
import React from "react";

const AucunDossier = () => {
  return (
    <div style={{ padding: "10px" }}>
      <Typography variant={"body1"} color={"primary"} component={"p"}>
        Aucun dossier disponible
      </Typography>
      <Typography variant={"body2"} color={"primary"} component={"p"}>
        Veuillez contacter l'administrateur pour toute question
      </Typography>
    </div>
  );
};
export default AucunDossier;
