import {COMPANY_TITLE} from "../../config/config";

export const PRESSE_PAGE = "espace-presse";
export const PRESSE_PAGE_LINK = "pages/" + PRESSE_PAGE;

export const menuItems = [
  {
    name: "Qui sommes-nous",
    route: "/",
    fragments: "qui-sommes-nous",
    enabled: true,
  },
  {
    name: "Les membres",
    route: "/",
    fragments: "adherents",
    enabled: true,
  },
  {
    name: "Métiers",
    route: "/",
    fragments: "metiers",
    enabled: true,
  },
  {
    name: "Chiffres clés",
    route: "/",
    fragments: "chiffres",
    enabled: true,
  },
  {
    name: "Actualités",
    route: "/",
    fragments: "actualites",
    enabled: true,
  },
  {
    name: "Presse",
    route: "/" + PRESSE_PAGE_LINK,
    enabled: true,
  },
];
