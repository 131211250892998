import React from "react";
import { Edit, SimpleForm, TextInput } from "react-admin";
import { Button, Typography } from "@material-ui/core";
import { useHistory } from "react-router";
import { getParams } from "../../../../helpers/getParamsId";
import { redirect } from "../../../../helpers/redirect";

const GroupDossierEdit = (props) => {
  const { goBack } = useHistory();
  const dossierID = props.id;
  const params = getParams(props);

  return (
    <>
      <Typography
        component={"h1"}
        variant={"h2"}
        align={"center"}
        style={{ padding: "30px" }}
      >
        Modifier un dossier
      </Typography>
      <Button variant={"outlined"} onClick={goBack}>
        Revenir
      </Button>
      <Edit undoable={false} {...props}>
        <SimpleForm redirect={() => redirect(params)}>
          <TextInput source="name" label={"Nom du Dossier"} />
          <TextInput disabled source="slug" label={"lien"} />
          <TextInput
            style={{ display: "none" }}
            name="parent"
            label={"dossiers"}
            defaultValue={dossierID}
            disabled
          />
        </SimpleForm>
      </Edit>
    </>
  );
};

export default GroupDossierEdit;
