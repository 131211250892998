import { SNSATheme } from "../types/styled";

export const theme: SNSATheme = {
  colors: {
    blue1: "#3955a2",
    blue1Ligther: "#5397eb",
    blue1Darker: "#2a3877",
    blue2: "#0b73b6",
    blue2Ligther: "#37aee0",
    blue2Darker: "#095d97",
    green: "#43d1b0",
    greenLighter: "#90cebf",
    greenDarker: "#5cb19d",
    grey: "#4a5466",
    greyLighter: "#8293b3",
    greyDarker: "#2f3540",
    yellow: "#fbcb2f",
    yellowLighter: "#fbdb73",
    yellowDarker: "#ec9600",
    orange: "#e1ae4a",
    orangeLighter: "#e5ce89",
    orangeDarker: "#e9a41e"
  }
};

export default theme;
