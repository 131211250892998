const initialState = { payload: undefined };

const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case "STORECONTENT":
      return {
        payload: {
          ...state.payload,
          ...action.payload
        }
      };
    default:
      return state;
  }
};

export default homeReducer;
