const splitPathname = (pathname) => {
  const splitedPathname = pathname.split("/");
  return splitedPathname;
};
export default splitPathname;

export const currentPath = (pathname) => {
  const splitedPathname = splitPathname(pathname);
  return splitedPathname[splitedPathname.length - 1];
};
