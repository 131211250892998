import { CreateCommissions } from "./commissions/Create";
import { ListCommissions } from "./commissions/List";
import { EditCommissions } from "./commissions/Edit";
import { CreateCompanies } from "./companies/Create";
import { ListCompanies } from "./companies/List";
import { EditCompanies } from "./companies/Edit";

import { CreateMembers } from "./members/Create";
import { ListMembers } from "./members/List";
import { EditMembers } from "./members/Edit";
import { CreatePeriods } from "./periods/Create";
import { ListPeriods } from "./periods/List";
import { EditPeriods } from "./periods/Edit";
import { CreatePosts } from "./posts/Create";
import { ListPosts } from "./posts/List";
import { EditPosts } from "./posts/Edit";
import { CreateSlides } from "./slides/Create";
import { ListSlides } from "./slides/List";
import { EditSlides } from "./slides/Edit";
import { CreateStats } from "./stats/Create";
import { ListStats } from "./stats/List";
import { EditStats } from "./stats/Edit";

export default {
  CreateCommissions,
  ListCommissions,
  EditCommissions,
  CreateCompanies,
  ListCompanies,
  EditCompanies,
  CreateMembers,
  ListMembers,
  EditMembers,
  CreatePeriods,
  ListPeriods,
  EditPeriods,
  CreatePosts,
  ListPosts,
  EditPosts,
  CreateSlides,
  ListSlides,
  EditSlides,
  CreateStats,
  ListStats,
  EditStats,
};
