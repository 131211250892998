import React, { useState } from "react";
import styled, { withTheme } from "styled-components";
import { useTheme } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Menu from "./Menu";
import GradientBackground from "../../../components/Background/GradientBackground";
import OverlayBackground from "../../../components/Background/OverlayBackground";
import Section from "../../../components/Section";
import Content from "./Content";

const About = styled(({ theme, className, content }) => {
  const muiTheme = useTheme();
  const [position, setPosition] = useState(0);
  const handleClick = (event: number) => {
    setPosition(event === 0 ? 0 : event - 1);
    window.dispatchEvent(new Event("resize")); // forcing fullpage refresh
  };
  const handleChangeIndex = (index: number) => {
    setPosition(index);
  };
  return (
    <Section
      id="qui-sommes-nous"
      alignItems="center"
      className={className}
      backgrounds={
        <>
          <GradientBackground
            color1={theme.colors.greenDarker}
            color2={theme.colors.green}
            deg={45}
            opacity={0.6}
          />
          <OverlayBackground overlay="16" opacity={0.2} />
        </>
      }
    >
      <Grid container spacing={2}>
        <Menu handleClick={handleClick} menuAbout={content.menuAbout} />
        <Content
          handleChangeIndex={handleChangeIndex}
          position={position}
          muiTheme={muiTheme}
          content={{
            aboutSlides: content.aboutSlides,
            shortCodeContent: {
              commissions: content.commissions,
              members: content.members,
              periods: content.periods
            }
          }}
        />
      </Grid>
    </Section>
  );
})`
  & h3 {
    padding-bottom: 10px;
  }
  & p + table {
    margin-top: 10px;
  }
  & table {
    width: 100% !important;
    & img {
      width: 100% !important;
    }
  }
`;

export default withTheme(About);
