import React from "react";
import { Member } from "../../types/member";
import MemberProfile from "./MemberProfile";
import MemberModal from "./MemberModal";
import styled from "styled-components";
import Modal from "../Modal";
import { Grid } from "@material-ui/core";
import alphabetic from "../../helpers/alphabetic";

interface MemberListProps {
  members: Member[];
  className?: string;
}

const MemberList = styled(({ members, className }: MemberListProps) => {
  alphabetic(members, "last_name");

  return (
    <Grid container className={className}>
      {members.map((member: Member, key: number) => (
        <Grid key={key} item md={2} sm={3} xs={6}>
          <Modal content={<MemberModal member={member} />}>
            <MemberProfile member={member} />
          </Modal>
        </Grid>
      ))}
    </Grid>
  );
})`
  display: flex;
  flex-wrap: wrap;
`;

export default MemberList;
