import React, { useState } from "react";
import {
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import styled from "styled-components";
import { menuItems } from "../MenuMain/mock";
import MenuIcon from "@material-ui/icons/Menu";
import { Link } from "react-router-dom";

const MenuContainer = styled.div`
  width: 200px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  margin: 0 20px 0 20px;
`;

const MenuMobile = ({ current, scrollTo }) => {
  const [open, setOpen] = useState();
  return (
    <>
      <Button onClick={() => setOpen(true)}>
        <MenuIcon />
      </Button>
      <Drawer anchor={"right"} open={open} onClose={() => setOpen(false)}>
        <MenuContainer role="presentation" style={{ padding: "20px" }}>
          <List>
            {menuItems.map((menuItem, index: number) => {
              return (
                <StyledLink
                  to={`${menuItem.route}${menuItem.fragments || ""}`}
                  key={index}
                  onClick={() => {
                    if (menuItem.route === "/") {
                      scrollTo(menuItem.fragments);
                    }
                  }}
                  style={
                    current === menuItem.fragments
                      ? { fontWeight: "bold" }
                      : null
                  }
                >
                  <ListItem button>
                    <ListItemText primary={menuItem.name} />
                  </ListItem>
                </StyledLink>
              );
            })}
            <StyledLink to="/dashboard">
              <ListItem button>
                <ListItemText primary={"Espace membres"} />
              </ListItem>{" "}
            </StyledLink>
          </List>
        </MenuContainer>
      </Drawer>
    </>
  );
};

export default MenuMobile;
