import Background, { BackgroundProps } from "./";
import styled from "styled-components";

interface GradientBackgroundProps extends BackgroundProps {
  deg: number;
  color1: string;
  color2: string;
}

const GradientBackground = styled(Background)<GradientBackgroundProps>`
  background: ${({ deg, color1, color2 }) =>
    `linear-gradient(${deg}deg, ${color1} 0%, ${color2} 100%)`};
`;

export default GradientBackground;
