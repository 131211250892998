import React, { ReactNodeArray } from "react";
import Historic from "../components/Historic";
import OrgaChart from "../components/OrgaChart";
import Commissions from "../components/Commissions";

import reactStringReplace from "react-string-replace";
import WorksTab from "../components/EmploiTab";

type TextPart = string | JSX.Element;

export const evalShortcodes = (
  text: TextPart | TextPart[],
  content = undefined
) => {
  if (typeof text !== "string") return text;

  let replacedText: string | ReactNodeArray;
  const historicRegExp = /(\[historique\])/gm;
  replacedText = reactStringReplace(text, historicRegExp, (match) => (
    <Historic key={match} periods={content.periods} />
  ));
  const orgaChartRegExp = /(\[organigramme\])/gm;
  replacedText = reactStringReplace(replacedText, orgaChartRegExp, (match) => (
    <OrgaChart key={match} members={content.members} />
  ));
  const commissionsRegExp = /(\[commissions\])/gm;
  replacedText = reactStringReplace(
    replacedText,
    commissionsRegExp,
    (match) => <Commissions key={match} content={content.commissions} />
  );
  const worksTabRegExp = /(\[toggletab\])/gm;
  replacedText = reactStringReplace(replacedText, worksTabRegExp, (match) => (
    <WorksTab key={match} />
  ));
  return replacedText;
};
