import React from "react";
import styled from "styled-components";

export interface BackgroundProps {
  opacity?: number;
  className?: string;
  children?: JSX.Element[] | JSX.Element;
}

const Background = styled(({ className, children }: BackgroundProps) => (
  <div className={className}>{children}</div>
))<BackgroundProps>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  pointer-events: none;
  overflow: hidden;
  ${props => props.opacity && `opacity:${props.opacity};`};
`;
export default Background;
