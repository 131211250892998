import { useTheme, useMediaQuery } from "@material-ui/core";
import Point from "./Point";
import React from "react";

const Points = ({ periods }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const periodCount = periods.length;
  const startYear = periods[0].year;
  const endYear = periods[periodCount - 1].year;
  const yearInterval = endYear - startYear; // 56
  const lineCount = isDesktop ? 9 : 5;
  const lineMinX = 60.5; // 50.5 initialy
  const lineMaxX = isDesktop ? 750.5 : 350.5;
  const lineWidth = lineMaxX - lineMinX; // 700
  const totalWidth = lineWidth * lineCount; // 3500
  const oneYearWidth = totalWidth / yearInterval; // 62.5
  const points = [];
  const minDistanceBetweenZeroYear = isDesktop ? 40 : 40; // val to adjust if overflow
  const minDistanceBetweenOneYear = isDesktop ? 105 : 75; // val to adjust if overflow
  const minDistanceBetweenPoints = isDesktop ? 160 : 125; // val to adjust if overflow
  const maxDistanceBetweenPoints = isDesktop ? 225 : 205; // val to adjust if overflow
  let lineXListener: number;
  let lineCountListener = 1;
  let distanceBetweenLines = 100;
  let previousYear: number;

  periods.map((period, index: number) => {
    const currentYear = period.year;
    let x: number;
    if (index === 0) {
      x = lineMinX - 10;
    } else if (lineXListener === 0) {
      x = lineMinX;
    } else {
      const yearDifference = currentYear - previousYear;
      if (yearDifference === 0) {
        x = lineXListener + minDistanceBetweenZeroYear;
      } else if (yearDifference === 1) {
        x = lineXListener + minDistanceBetweenOneYear;
      } else if (yearDifference > 1) {
        let distanceWithPreviousPoint = yearDifference * oneYearWidth;
        if (distanceWithPreviousPoint >= maxDistanceBetweenPoints)
          distanceWithPreviousPoint = maxDistanceBetweenPoints;
        x = lineXListener + maxDistanceBetweenPoints;
      } else {
        x = lineXListener + minDistanceBetweenPoints;
      }
    }
    previousYear = currentYear;
    lineXListener = x;
    if (lineXListener > lineMaxX) {
      lineXListener = lineMinX;
      x = lineMinX;
      lineCountListener++;
    }
    if (lineCountListener % 2 === 0) x = lineMaxX - x + 30; // inverse on even line
    const y = lineCountListener * distanceBetweenLines - 50.5;
    points.push(
      <Point x={x} y={y} title={period.title} text={period.text} key={index} />
    );
  });
  return <g id="points">{points}</g>;
};

export default Points;
