import axios from "axios";
import Cookies from "../admin/helpers/Cookies/Cookies";

export const useAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 10000,
  headers: {
    Accept: "application/json",
  },
});
