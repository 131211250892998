import SectionCard from "../../../components/SectionCard";
import SectionMenu from "../../../components/SectionMenu";
import React, { SetStateAction } from "react";
import { Grid, isWidthUp, withWidth, WithWidth } from "@material-ui/core";
import styled from "styled-components";

interface Menu extends WithWidth {
  handleClick: (event: SetStateAction<number>) => void;
  className: string;
  menuAbout: { name: string; position: number }[];
}

const Menu = styled((props: Menu) => {
  return (
    <Grid item sm={3} className={props.className}>
      <SectionCard
        opacity={0.8}
        roundedCorners={
          isWidthUp("md", props.width)
            ? [false, false, false, true]
            : [true, true, false, false]
        }
      >
        <SectionMenu
          title="Qui sommes-nous ?"
          items={props.menuAbout}
          handleClick={props.handleClick}
          direction="right"
        />
      </SectionCard>
    </Grid>
  );
})`
  ${(props) => {
    if (!isWidthUp("md", props.width)) {
      return "flex:content; max-width: 100%;";
    }
  }}
`;

export default withWidth()(Menu);
