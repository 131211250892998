import { Resource } from "react-admin";
import DocumentList from "../../components/admin/documents/List";
import DocumentEdit from "../../components/admin/documents/Edit";
import DocumentCreate from "../../components/admin/documents/Create";
import PostIcon from "@material-ui/icons/Book";
import UserGroupList from "../../components/admin/userGroups/List";
import UserGroupEdit from "../../components/admin/userGroups/Edit";
import UserGroupCreate from "../../components/admin/userGroups/Create";
import UserIcon from "@material-ui/icons/People";
import UserList from "../../components/admin/users/List";
import UserEdit from "../../components/admin/users/Edit";
import UserCreate from "../../components/admin/users/Create";
import React from "react";
import DossierList from "../../components/admin/dossiers/List";
import DossierEdit from "../../components/admin/dossiers/Edit";
import DossierCreate from "../../components/admin/dossiers/Create";

export const documentRessourceTypes = [
  "documents",
  "users",
  "user-groups",
  "dossiers",
  "users-permissions/roles",
];

export const documentResources = [
  <Resource
    name="documents"
    list={DocumentList}
    edit={DocumentEdit}
    create={DocumentCreate}
    icon={PostIcon}
    options={{ label: "Documents" }}
  />,
  <Resource
    name="user-groups"
    list={UserGroupList}
    edit={UserGroupEdit}
    create={UserGroupCreate}
    icon={UserIcon}
    options={{ label: "Groupes d'utilisateurs" }}
  />,
  <Resource
    name="dossiers"
    list={DossierList}
    edit={DossierEdit}
    create={DossierCreate}
    icon={UserIcon}
    options={{ label: "Dossiers" }}
  />,
  <Resource name="users-permissions/roles" />,
  <Resource
    name="users"
    list={UserList}
    edit={UserEdit}
    create={UserCreate}
    icon={UserIcon}
    options={{ label: "Utilisateurs" }}
  />,
];
