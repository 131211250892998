const initialState = { payload: [] };

const articlesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ALL_ARTICLE_CONTENT":
      return {
        payload: [...state.payload, ...action.payload]
      };
    default:
      return state;
  }
};

export default articlesReducer;
