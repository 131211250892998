import React from "react";
import styled from "styled-components";

interface HiddenMailLinkProps {
  emailLetters: string[];
  className?: string;
}

const HiddenMailLink = styled(
  ({ emailLetters, className }: HiddenMailLinkProps) => (
    <span
      className={className}
      onClick={(event: React.MouseEvent<HTMLSpanElement>) => {
        event.preventDefault();
        const eventTarget = event.target as HTMLElement;
        const email = eventTarget.innerHTML
          .split("")
          .reverse()
          .join("");
        document.location.href = "mailto:" + email;
      }}
    >
      {emailLetters.reverse().join("")}
    </span>
  )
)`
  unicode-bidi: bidi-override;
  direction: rtl;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export default HiddenMailLink;
