import React from "react";
import {
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemIcon
} from "@material-ui/core";
import Icon from "@mdi/react";

export interface MenuItemProps {
  name: string;
  route: string;
  fragments?: string;
  icon?: any;
  iconOnly?: boolean;
  type?: "link" | "button";
}

const MenuItem = ({
  name,
  route,
  fragments,
  icon,
  iconOnly,
  type
}: MenuItemProps) => {
  const href = route + (fragments ? "/#" + fragments : "");
  return type === "button" ? (
    <Button
      onClick={() => {
        window.location.href = href;
      }}
    >
      {icon && <Icon path={icon} title={name} />}
      {!iconOnly && name}
    </Button>
  ) : (
    <ListItem component="a" href={href}>
      {icon && (
        <ListItemIcon>
          <Icon path={icon} title={name} />
        </ListItemIcon>
      )}
      {!iconOnly && <ListItemText primary={name} />}
    </ListItem>
  );
};

interface MenuProps {
  menuItems: MenuItemProps[];
  className?: string;
}

const Menu = ({ menuItems, className }: MenuProps) => (
  <List className={className}>
    {menuItems.map((menuItem: MenuItemProps, index: number) => (
      <MenuItem {...menuItem} key={index} />
    ))}
  </List>
);

export default Menu;
