import React from "react";
import styled from "styled-components";
import { withTheme } from "styled-components";
import { SNSATheme } from "../../../types/styled.d";
import { Typography, Button, Grid } from "@material-ui/core";
import GradientBackground from "../../../components/Background/GradientBackground";
import OverlayBackground from "../../../components/Background/OverlayBackground";
import SectionCard from "../../../components/SectionCard";
import Section from "../../../components/Section";
import PostCard from "../../../components/PostCard";
import { Link } from "react-router-dom";
import { Post } from "../../../Blog/AllArticles/mock";
import { posts } from "../../../components/PostCard/mock";

interface ActuProps {
  theme: SNSATheme;
  posts: any;
}

const Title = styled(({ className }) => (
  <div className={className}>
    <SectionCard opacity={0.8} roundedCorners={[true, true, false, false]}>
      <Typography variant="h4" component="h2">
        Dernières actualités
      </Typography>
      <Link to={"articles"}>
        <Button variant={"outlined"}>Voir tous les articles</Button>
      </Link>
    </SectionCard>
  </div>
))`
  margin-bottom: 16px !important;
  & h2 {
    display: inline-block;
  }
  & button {
    float: right;
  }
`;

export const Line = styled(({ postsLength, post, index, className }) => (
  <div className={className}>
    <SectionCard
      opacity={0.8}
      roundedCorners={
        index === postsLength - 1
          ? [false, false, true, true]
          : [false, false, false, false]
      }
    >
      <PostCard content={post} />
    </SectionCard>
  </div>
))`
  margin-bottom: 16px !important;
`;

const News = withTheme(({ theme, posts }: ActuProps) => (
  <Section
    id="actualites"
    backgrounds={
      <>
        <GradientBackground
          color1={theme.colors.orangeDarker}
          color2={theme.colors.orangeLighter}
          deg={45}
          opacity={0.9}
        />
        <OverlayBackground overlay="16" opacity={0.2} />
      </>
    }
  >
    <Grid item xs={12}>
      <Title />
      {posts.map((post: Post, index: number) => (
        <Line posts={posts} post={post} index={index} key={index} />
      ))}
    </Grid>
  </Section>
));

export default News;
