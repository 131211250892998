import React from "react";
import styled from "styled-components";


const LogoSvg = ({appearance}) => {
    let viewbox: string;
    const displayLogo = true;
    let displayCatchphrase = false;
    if (appearance === "complet") {
        viewbox = "0 0 1000 382";
    }
    if (appearance === "simple") {
        viewbox = "0 0 541 382";
        displayCatchphrase = false;
    }
    const style = ".cls-1{fill:#eb5e25;}.cls-2{fill:#fdc213;}.cls-3{fill:#80c28f;}.cls-4{fill:#c2348b;}.cls-5{fill:#1e274b;}.cls-6{fill:#233987;}.cls-7{fill:#3e7a6e;}.cls-8{fill:#8b2b74;}.cls-9{fill:#479ad5;}"
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox={viewbox}
        >
            {displayLogo && !displayCatchphrase ? (
                <svg xmlns="http://www.w3.org/2000/svg" id="Calque_1" viewBox="0 0 840.61 337.89">
                    <defs>
                        <style>{style}</style>
                    </defs>
                    <path class="cls-6"
                          d="m86.13,150.36c-.99-.98-2.29-1.48-3.9-1.48s-2.83.49-3.85,1.48c-1.02.99-1.53,2.29-1.53,3.9v29.76c0,2.53-.7,4.82-2.11,6.86-1.41,2.04-3.29,3.66-5.64,4.86-2.36,1.2-5.05,1.79-8.07,1.79s-6.02-.62-8.55-1.85c-2.53-1.23-4.54-3.13-6.01-5.7-1.48-2.57-2.22-5.82-2.22-9.76v-25.96c0-1.54-.51-2.83-1.53-3.85-1.02-1.02-2.31-1.53-3.85-1.53s-2.83.51-3.85,1.53c-1.02,1.02-1.53,2.31-1.53,3.85v25.96c0,5.84,1.11,10.76,3.33,14.78,2.21,4.01,5.24,7.05,9.08,9.13,3.83,2.08,8.18,3.11,13.03,3.11s8.92-1.02,12.61-3.06c2.01-1.11,3.78-2.44,5.33-3.99v1.14c0,1.55.51,2.83,1.53,3.85,1.02,1.02,2.31,1.53,3.85,1.53s2.92-.51,3.9-1.53c.99-1.02,1.48-2.3,1.48-3.85v-47.07c0-1.62-.49-2.92-1.48-3.9Z"/>
                    <path class="cls-6"
                          d="m161.75,160.7c-2.21-4.01-5.24-7.05-9.08-9.13-3.84-2.08-8.18-3.11-13.03-3.11s-8.83,1.02-12.56,3.06c-2.03,1.11-3.82,2.45-5.38,3.99v-1.14c0-1.62-.49-2.92-1.48-3.91-.99-.98-2.29-1.48-3.91-1.48s-2.83.49-3.85,1.48c-1.02.99-1.53,2.29-1.53,3.91v47.07c0,1.55.51,2.83,1.53,3.85,1.02,1.02,2.3,1.53,3.85,1.53s2.92-.51,3.91-1.53c.98-1.02,1.48-2.3,1.48-3.85v-29.76c0-2.6.7-4.91,2.11-6.91,1.41-2,3.31-3.6,5.7-4.8,2.39-1.2,5.07-1.79,8.02-1.79,3.23,0,6.1.62,8.6,1.85,2.5,1.23,4.48,3.11,5.96,5.64,1.48,2.53,2.21,5.8,2.21,9.82v25.96c0,1.55.51,2.83,1.53,3.85,1.02,1.02,2.3,1.53,3.85,1.53s2.83-.51,3.85-1.53c1.02-1.02,1.53-2.3,1.53-3.85v-25.96c0-5.84-1.11-10.76-3.33-14.77Z"/>
                    <path class="cls-3"
                          d="m428.79,151.18c1.12-1.54,2.63-2.46,4.55-2.76,1.92-.3,3.65.1,5.18,1.22,1.54,1.12,2.46,2.63,2.76,4.55.3,1.92-.1,3.64-1.22,5.18-1.12,1.54-2.63,2.46-4.55,2.76-1.92.3-3.64-.1-5.18-1.22-1.54-1.12-2.46-2.63-2.76-4.55-.3-1.92.1-3.64,1.22-5.18Z"/>
                    <path class="cls-6"
                          d="m249.6,207.14c-5.7,0-10.73-1.25-15.09-3.75-4.36-2.5-7.79-5.94-10.29-10.34-2.5-4.4-3.75-9.44-3.75-15.14s1.25-10.85,3.75-15.25c2.5-4.4,5.93-7.84,10.29-10.34,4.36-2.5,9.39-3.75,15.09-3.75s10.62,1.25,14.99,3.75c4.36,2.5,7.79,5.95,10.29,10.34,2.5,4.4,3.75,9.48,3.75,15.25s-1.23,10.75-3.69,15.14c-2.46,4.4-5.87,7.85-10.24,10.34-4.36,2.5-9.39,3.75-15.09,3.75Zm0-9.5c3.66,0,6.89-.85,9.71-2.53,2.81-1.69,5.01-4.01,6.6-6.97,1.58-2.96,2.38-6.37,2.38-10.24s-.79-7.3-2.38-10.29c-1.58-2.99-3.78-5.33-6.6-7.02-2.81-1.69-6.05-2.53-9.71-2.53s-6.89.85-9.71,2.53c-2.81,1.69-5.03,4.03-6.65,7.02-1.62,2.99-2.42,6.42-2.42,10.29s.81,7.28,2.42,10.24c1.62,2.95,3.84,5.28,6.65,6.97,2.81,1.69,6.05,2.53,9.71,2.53Z"/>
                    <path class="cls-6"
                          d="m349.8,160.7c-2.21-4.01-5.24-7.05-9.08-9.13-3.84-2.08-8.18-3.11-13.03-3.11s-8.83,1.02-12.56,3.06c-2.03,1.11-3.82,2.45-5.38,3.99v-1.14c0-1.62-.49-2.92-1.48-3.91-.99-.98-2.29-1.48-3.91-1.48s-2.83.49-3.85,1.48c-1.02.99-1.53,2.29-1.53,3.91v47.07c0,1.55.51,2.83,1.53,3.85,1.02,1.02,2.3,1.53,3.85,1.53s2.92-.51,3.91-1.53c.98-1.02,1.48-2.3,1.48-3.85v-29.76c0-2.6.7-4.91,2.11-6.91,1.41-2,3.31-3.6,5.7-4.8,2.39-1.2,5.07-1.79,8.02-1.79,3.23,0,6.1.62,8.6,1.85,2.5,1.23,4.48,3.11,5.96,5.64,1.48,2.53,2.21,5.8,2.21,9.82v25.96c0,1.55.51,2.83,1.53,3.85,1.02,1.02,2.3,1.53,3.85,1.53s2.83-.51,3.85-1.53c1.02-1.02,1.53-2.3,1.53-3.85v-25.96c0-5.84-1.11-10.76-3.33-14.77Z"/>
                    <path class="cls-9"
                          d="m300.11,230.66c-2.57-4.43-6.03-7.93-10.4-10.5-4.36-2.57-9.29-3.85-14.77-3.85s-10.43,1.29-14.83,3.85c-4.4,2.57-7.88,6.07-10.45,10.5-2.57,4.43-3.85,9.43-3.85,14.99s1.18,10.53,3.54,14.93c2.36,4.4,5.58,7.88,9.66,10.45,4.08,2.57,8.69,3.85,13.83,3.85s9.74-1.28,13.83-3.85c2.51-1.58,4.69-3.51,6.54-5.77v3.93c0,1.55.51,2.83,1.53,3.85,1.02,1.02,2.3,1.53,3.85,1.53s2.83-.51,3.85-1.53,1.53-2.3,1.53-3.85v-23.53c0-5.56-1.29-10.55-3.85-14.99Zm-15.57,32.13c-2.81,1.73-6.01,2.59-9.6,2.59s-6.7-.86-9.55-2.59c-2.85-1.72-5.1-4.08-6.76-7.07s-2.48-6.35-2.48-10.08.83-7.19,2.48-10.19c1.65-2.99,3.9-5.35,6.76-7.07,2.85-1.72,6.03-2.59,9.55-2.59s6.79.86,9.6,2.59c2.81,1.72,5.03,4.08,6.65,7.07,1.62,2.99,2.43,6.39,2.43,10.19s-.81,7.09-2.43,10.08c-1.62,2.99-3.84,5.35-6.65,7.07Z"/>
                    <path class="cls-9"
                          d="m341.95,274.88c-4.93,0-9.48-.76-13.67-2.27-4.19-1.51-7.44-3.43-9.76-5.75-1.06-1.12-1.5-2.41-1.32-3.85.17-1.44.86-2.62,2.06-3.54,1.41-1.12,2.8-1.6,4.17-1.42,1.37.18,2.55.79,3.54,1.85,1.2,1.34,3.11,2.59,5.75,3.75,2.64,1.16,5.57,1.74,8.81,1.74,4.08,0,7.19-.67,9.34-2,2.14-1.34,3.25-3.06,3.32-5.17.07-2.11-.93-3.94-3.01-5.49-2.08-1.55-5.86-2.81-11.34-3.8-7.11-1.41-12.26-3.52-15.46-6.33-3.2-2.81-4.8-6.26-4.8-10.34,0-3.59,1.05-6.56,3.17-8.92,2.11-2.36,4.82-4.12,8.13-5.28,3.31-1.16,6.75-1.74,10.34-1.74,4.64,0,8.76.74,12.35,2.22,3.59,1.48,6.44,3.52,8.55,6.12.98,1.12,1.44,2.3,1.37,3.54-.07,1.23-.67,2.27-1.79,3.11-1.13.78-2.46,1.02-4.01.74-1.55-.28-2.85-.91-3.9-1.9-1.76-1.69-3.66-2.85-5.7-3.48-2.04-.63-4.4-.95-7.07-.95-3.1,0-5.72.53-7.86,1.58-2.15,1.05-3.22,2.6-3.22,4.64,0,1.27.33,2.41,1,3.43.67,1.02,1.99,1.94,3.96,2.74,1.97.81,4.86,1.6,8.65,2.37,5.28,1.06,9.44,2.39,12.51,4.01,3.06,1.62,5.26,3.5,6.6,5.64,1.33,2.15,2,4.63,2,7.44,0,3.24-.86,6.16-2.59,8.76-1.72,2.6-4.26,4.68-7.6,6.23s-7.51,2.32-12.51,2.32Z"/>
                    <path class="cls-9"
                          d="m400.21,274.88c-4.93,0-9.48-.76-13.67-2.27-4.19-1.51-7.44-3.43-9.76-5.75-1.06-1.12-1.5-2.41-1.32-3.85.17-1.44.86-2.62,2.06-3.54,1.41-1.12,2.8-1.6,4.17-1.42,1.37.18,2.55.79,3.54,1.85,1.2,1.34,3.11,2.59,5.75,3.75,2.64,1.16,5.57,1.74,8.81,1.74,4.08,0,7.19-.67,9.34-2,2.14-1.34,3.25-3.06,3.32-5.17.07-2.11-.93-3.94-3.01-5.49-2.08-1.55-5.86-2.81-11.34-3.8-7.11-1.41-12.26-3.52-15.46-6.33-3.2-2.81-4.8-6.26-4.8-10.34,0-3.59,1.05-6.56,3.17-8.92,2.11-2.36,4.82-4.12,8.13-5.28,3.31-1.16,6.75-1.74,10.34-1.74,4.64,0,8.76.74,12.35,2.22,3.59,1.48,6.44,3.52,8.55,6.12.98,1.12,1.44,2.3,1.37,3.54-.07,1.23-.67,2.27-1.79,3.11-1.13.78-2.46,1.02-4.01.74-1.55-.28-2.85-.91-3.9-1.9-1.76-1.69-3.66-2.85-5.7-3.48-2.04-.63-4.4-.95-7.07-.95-3.1,0-5.72.53-7.86,1.58-2.15,1.05-3.22,2.6-3.22,4.64,0,1.27.33,2.41,1,3.43.67,1.02,1.99,1.94,3.96,2.74,1.97.81,4.86,1.6,8.65,2.37,5.28,1.06,9.44,2.39,12.51,4.01,3.06,1.62,5.26,3.5,6.6,5.64,1.33,2.15,2,4.63,2,7.44,0,3.24-.86,6.16-2.59,8.76-1.72,2.6-4.26,4.68-7.6,6.23-3.34,1.55-7.51,2.32-12.51,2.32Z"/>
                    <path class="cls-9"
                          d="m443.9,274.45c-1.55,0-2.83-.51-3.85-1.53-1.02-1.02-1.53-2.3-1.53-3.85v-50.61c0-1.62.51-2.92,1.53-3.91,1.02-.98,2.3-1.48,3.85-1.48s2.92.49,3.9,1.48c.99.99,1.48,2.29,1.48,3.91v50.61c0,1.55-.49,2.83-1.48,3.85-.99,1.02-2.29,1.53-3.9,1.53Z"/>
                    <path class="cls-9"
                          d="m491.28,274.88c-4.93,0-9.48-.76-13.67-2.27-4.19-1.51-7.44-3.43-9.76-5.75-1.06-1.12-1.5-2.41-1.32-3.85.17-1.44.86-2.62,2.06-3.54,1.41-1.12,2.8-1.6,4.17-1.42,1.37.18,2.55.79,3.54,1.85,1.2,1.34,3.11,2.59,5.75,3.75,2.64,1.16,5.57,1.74,8.81,1.74,4.08,0,7.19-.67,9.34-2,2.14-1.34,3.25-3.06,3.32-5.17.07-2.11-.93-3.94-3.01-5.49-2.08-1.55-5.86-2.81-11.34-3.8-7.11-1.41-12.26-3.52-15.46-6.33-3.2-2.81-4.8-6.26-4.8-10.34,0-3.59,1.05-6.56,3.17-8.92,2.11-2.36,4.82-4.12,8.13-5.28,3.31-1.16,6.75-1.74,10.34-1.74,4.64,0,8.76.74,12.35,2.22,3.59,1.48,6.44,3.52,8.55,6.12.98,1.12,1.44,2.3,1.37,3.54-.07,1.23-.67,2.27-1.79,3.11-1.13.78-2.46,1.02-4.01.74-1.55-.28-2.85-.91-3.9-1.9-1.76-1.69-3.66-2.85-5.7-3.48-2.04-.63-4.4-.95-7.07-.95-3.1,0-5.72.53-7.86,1.58-2.15,1.05-3.22,2.6-3.22,4.64,0,1.27.33,2.41,1,3.43.67,1.02,1.99,1.94,3.96,2.74,1.97.81,4.86,1.6,8.65,2.37,5.28,1.06,9.44,2.39,12.51,4.01,3.06,1.62,5.26,3.5,6.6,5.64,1.33,2.15,2,4.63,2,7.44,0,3.24-.86,6.16-2.59,8.76-1.72,2.6-4.26,4.68-7.6,6.23s-7.51,2.32-12.51,2.32Z"/>
                    <path class="cls-9"
                          d="m556.34,265.38c-.88-.99-1.95-1.48-3.22-1.48h-3.59c-2.25,0-4.15-.95-5.7-2.85-1.55-1.9-2.32-4.26-2.32-7.07v-26.07h8.97c1.48,0,2.67-.42,3.59-1.27.91-.85,1.37-1.9,1.37-3.17,0-1.41-.46-2.53-1.37-3.38-.91-.84-2.11-1.27-3.59-1.27h-8.97v-15.2c0-1.54-.49-2.81-1.48-3.8-.99-.99-2.25-1.48-3.8-1.48s-2.81.49-3.8,1.48c-.99.99-1.48,2.25-1.48,3.8v15.2h-4.86c-1.48,0-2.67.42-3.59,1.27-.91.85-1.37,1.97-1.37,3.38,0,1.27.46,2.32,1.37,3.17.91.84,2.11,1.27,3.59,1.27h4.86v26.07c0,3.87.81,7.35,2.43,10.45,1.62,3.1,3.83,5.54,6.65,7.33,2.81,1.79,5.98,2.69,9.5,2.69h1.9c1.83,0,3.33-.49,4.49-1.48,1.16-.99,1.74-2.25,1.74-3.8s-.44-2.81-1.32-3.8Z"/>
                    <path class="cls-9"
                          d="m622.24,248.02c.91-.88,1.37-1.99,1.37-3.33,0-5.56-1.04-10.46-3.11-14.72-2.08-4.25-5.08-7.6-9.02-10.02-3.94-2.43-8.65-3.64-14.14-3.64s-10.48,1.25-14.77,3.75c-4.29,2.5-7.65,5.95-10.08,10.34-2.43,4.4-3.64,9.48-3.64,15.25s1.28,10.75,3.85,15.14c2.57,4.4,6.12,7.85,10.66,10.34,4.54,2.5,9.73,3.75,15.57,3.75,3.23,0,6.63-.6,10.18-1.79,3.55-1.2,6.53-2.74,8.92-4.64,1.2-.91,1.76-2.01,1.69-3.27-.07-1.27-.7-2.42-1.9-3.48-.91-.77-1.99-1.14-3.22-1.11-1.23.03-2.37.44-3.43,1.21-1.41,1.06-3.25,1.99-5.54,2.8-2.29.81-4.52,1.21-6.7,1.21-4.01,0-7.56-.88-10.66-2.64-3.1-1.76-5.53-4.15-7.28-7.18-1.18-2.03-1.95-4.25-2.34-6.65h40.12c1.41,0,2.57-.44,3.48-1.32Zm-35.2-20c2.85-1.76,6.28-2.64,10.29-2.64,3.52,0,6.51.81,8.97,2.43,2.46,1.62,4.36,3.82,5.7,6.59.95,1.98,1.57,4.14,1.89,6.49h-35.13c.36-2.01.96-3.9,1.8-5.64,1.48-3.06,3.64-5.47,6.49-7.23Z"/>
                    <path class="cls-9"
                          d="m689.78,218.1c-.99-.98-2.29-1.48-3.9-1.48s-2.83.49-3.85,1.48c-1.02.99-1.53,2.29-1.53,3.9v29.76c0,2.53-.7,4.82-2.11,6.86-1.41,2.04-3.29,3.66-5.64,4.86-2.36,1.2-5.05,1.79-8.07,1.79s-6.02-.62-8.55-1.85c-2.53-1.23-4.54-3.13-6.01-5.7-1.48-2.57-2.22-5.82-2.22-9.76v-25.96c0-1.54-.51-2.83-1.53-3.85-1.02-1.02-2.31-1.53-3.85-1.53s-2.83.51-3.85,1.53c-1.02,1.02-1.53,2.31-1.53,3.85v25.96c0,5.84,1.11,10.76,3.33,14.78,2.21,4.01,5.24,7.05,9.08,9.13,3.83,2.08,8.18,3.11,13.03,3.11s8.92-1.02,12.61-3.06c2.01-1.11,3.78-2.44,5.33-3.99v1.14c0,1.55.51,2.83,1.53,3.85,1.02,1.02,2.31,1.53,3.85,1.53s2.92-.51,3.9-1.53c.99-1.02,1.48-2.3,1.48-3.85v-47.07c0-1.62-.49-2.92-1.48-3.9Z"/>
                    <path class="cls-9"
                          d="m748.94,218.15c-2.08-1.37-5.22-2.06-9.45-2.06s-8.09.99-11.61,2.96c-2.37,1.33-4.4,2.97-6.12,4.92v-1.97c0-1.76-.44-3.08-1.32-3.96-.88-.88-2.2-1.32-3.96-1.32s-2.99.46-3.9,1.37c-.91.92-1.37,2.22-1.37,3.9v47.18c0,1.76.46,3.08,1.37,3.96.91.88,2.22,1.32,3.9,1.32s3.08-.46,3.96-1.37c.88-.91,1.32-2.22,1.32-3.9v-30.82c0-3.23,1.04-5.91,3.11-8.02,2.08-2.11,4.91-3.55,8.5-4.33,3.59-.78,7.63-.7,12.14.21.91.21,1.76.25,2.53.11.77-.14,1.46-.47,2.06-1,.6-.53,1-1.28,1.21-2.27.49-1.9-.3-3.54-2.37-4.91Z"/>
                    <path class="cls-9"
                          d="m784.45,274.88c-4.93,0-9.48-.76-13.67-2.27-4.19-1.51-7.44-3.43-9.76-5.75-1.06-1.12-1.5-2.41-1.32-3.85.17-1.44.86-2.62,2.06-3.54,1.41-1.12,2.8-1.6,4.17-1.42,1.37.18,2.55.79,3.54,1.85,1.2,1.34,3.11,2.59,5.75,3.75,2.64,1.16,5.57,1.74,8.81,1.74,4.08,0,7.19-.67,9.34-2,2.14-1.34,3.25-3.06,3.32-5.17.07-2.11-.93-3.94-3.01-5.49s-5.86-2.81-11.34-3.8c-7.11-1.41-12.26-3.52-15.46-6.33-3.2-2.81-4.8-6.26-4.8-10.34,0-3.59,1.05-6.56,3.17-8.92,2.11-2.36,4.82-4.12,8.13-5.28,3.31-1.16,6.75-1.74,10.34-1.74,4.64,0,8.76.74,12.35,2.22,3.59,1.48,6.44,3.52,8.55,6.12.98,1.12,1.44,2.3,1.37,3.54-.07,1.23-.67,2.27-1.79,3.11-1.13.78-2.46,1.02-4.01.74-1.55-.28-2.85-.91-3.9-1.9-1.76-1.69-3.66-2.85-5.7-3.48-2.04-.63-4.4-.95-7.07-.95-3.1,0-5.72.53-7.86,1.58-2.15,1.05-3.22,2.6-3.22,4.64,0,1.27.33,2.41,1,3.43.67,1.02,1.99,1.94,3.96,2.74,1.97.81,4.86,1.6,8.65,2.37,5.28,1.06,9.44,2.39,12.51,4.01,3.06,1.62,5.26,3.5,6.6,5.64,1.33,2.15,2,4.63,2,7.44,0,3.24-.86,6.16-2.59,8.76-1.72,2.6-4.26,4.68-7.6,6.23-3.34,1.55-7.51,2.32-12.51,2.32Z"/>
                    <path class="cls-9"
                          d="m149.09,272.08c-2.72,0-5.18-.64-7.36-1.91-2.18-1.27-3.91-3.01-5.18-5.21-1.27-2.2-1.91-4.68-1.91-7.44s.58-5.23,1.75-7.41c1.17-2.18,2.77-3.91,4.79-5.18,2.02-1.27,4.31-1.91,6.86-1.91,2.06,0,3.96.43,5.71,1.28,1.75.86,3.21,2.02,4.4,3.48v-14.14c0-.8.25-1.45.76-1.94.51-.49,1.14-.73,1.91-.73s1.45.25,1.94.73c.49.49.73,1.13.73,1.94v23.88c0,2.76-.64,5.24-1.91,7.44-1.27,2.2-2.99,3.94-5.16,5.21-2.16,1.27-4.61,1.91-7.33,1.91Zm0-4.71c1.78,0,3.37-.43,4.76-1.28,1.4-.85,2.5-2.03,3.3-3.53.8-1.5,1.2-3.18,1.2-5.03s-.4-3.56-1.2-5.03c-.8-1.47-1.9-2.63-3.3-3.48-1.4-.85-2.98-1.28-4.76-1.28s-3.32.43-4.74,1.28c-1.41.86-2.53,2.02-3.35,3.48-.82,1.47-1.23,3.14-1.23,5.03s.41,3.53,1.23,5.03c.82,1.5,1.94,2.68,3.35,3.53,1.41.86,2.99,1.28,4.74,1.28Z"/>
                    <path class="cls-9"
                          d="m198.41,258.75c.45-.44.68-.99.68-1.65,0-2.76-.52-5.19-1.54-7.3-1.03-2.11-2.52-3.77-4.48-4.97-1.96-1.2-4.29-1.81-7.02-1.81s-5.2.62-7.33,1.86c-2.13,1.24-3.8,2.95-5,5.13-1.2,2.18-1.81,4.7-1.81,7.57s.64,5.33,1.91,7.51c1.27,2.18,3.04,3.89,5.29,5.13,2.25,1.24,4.83,1.86,7.72,1.86,1.6,0,3.29-.3,5.05-.89,1.76-.59,3.24-1.36,4.42-2.3.59-.45.87-1,.84-1.62-.04-.63-.35-1.2-.94-1.73-.45-.38-.99-.57-1.6-.55-.61.02-1.18.22-1.7.6-.7.52-1.62.99-2.75,1.39-1.13.4-2.24.6-3.32.6-1.99,0-3.75-.44-5.29-1.31-1.54-.87-2.74-2.06-3.61-3.56-.58-1-.97-2.11-1.16-3.3h19.91c.7,0,1.27-.22,1.73-.65Zm-17.46-9.92c1.41-.87,3.12-1.31,5.11-1.31,1.74,0,3.23.4,4.45,1.2,1.22.8,2.16,1.89,2.83,3.27.47.98.78,2.06.93,3.22h-17.42c.18-1,.46-1.94.88-2.8.73-1.52,1.81-2.71,3.22-3.59Z"/>
                    <path class="cls-9"
                          d="m216.79,272.08c-2.44,0-4.7-.37-6.78-1.13-2.08-.75-3.69-1.7-4.84-2.85-.52-.56-.74-1.19-.65-1.91.09-.71.43-1.3,1.02-1.75.7-.56,1.39-.79,2.07-.71.68.09,1.26.39,1.75.92.59.66,1.54,1.28,2.85,1.86,1.31.58,2.77.86,4.37.86,2.02,0,3.57-.33,4.63-1,1.06-.66,1.61-1.52,1.65-2.57.03-1.05-.46-1.95-1.49-2.72-1.03-.77-2.91-1.4-5.63-1.88-3.53-.7-6.08-1.75-7.67-3.14-1.59-1.4-2.38-3.11-2.38-5.13,0-1.78.52-3.26,1.57-4.42,1.05-1.17,2.39-2.04,4.03-2.62,1.64-.58,3.35-.86,5.13-.86,2.3,0,4.35.37,6.13,1.1,1.78.73,3.19,1.75,4.24,3.04.49.56.72,1.14.68,1.75-.04.61-.33,1.13-.89,1.54-.56.38-1.22.51-1.99.37-.77-.14-1.41-.45-1.94-.94-.87-.84-1.82-1.41-2.83-1.73-1.01-.31-2.18-.47-3.51-.47-1.54,0-2.84.26-3.9.79-1.07.52-1.6,1.29-1.6,2.3,0,.63.17,1.2.5,1.7.33.51.99.96,1.96,1.36.98.4,2.41.79,4.29,1.18,2.62.52,4.69,1.19,6.2,1.99,1.52.8,2.61,1.74,3.27,2.8.66,1.07,1,2.3,1,3.69,0,1.61-.43,3.05-1.28,4.35-.86,1.29-2.11,2.32-3.77,3.09-1.66.77-3.73,1.15-6.21,1.15Z"/>
                    <path class="cls-6"
                          d="m439.61,191.07c1.54-1.12,3.26-1.52,5.18-1.22,1.92.31,3.43,1.23,4.55,2.76,1.12,1.54,1.52,3.26,1.22,5.18-.3,1.92-1.22,3.43-2.76,4.55-1.54,1.12-3.26,1.52-5.18,1.22s-3.43-1.22-4.55-2.76c-1.12-1.54-1.52-3.26-1.22-5.18.3-1.92,1.22-3.43,2.76-4.55Z"/>
                    <path class="cls-1"
                          d="m219.58,107.39c-1.81-.59-3.15-1.74-4.03-3.47-.88-1.73-1.03-3.5-.44-5.31.59-1.81,1.75-3.15,3.47-4.03,1.73-.88,3.5-1.03,5.3-.44,1.81.59,3.15,1.75,4.03,3.47.88,1.73,1.03,3.5.44,5.3-.59,1.81-1.75,3.15-3.48,4.03-1.73.88-3.5,1.03-5.3.44Z"/>
                    <path class="cls-7"
                          d="m254.17,84.77c-1.54-1.12-2.46-2.63-2.76-4.55-.3-1.92.1-3.65,1.22-5.18,1.12-1.54,2.63-2.46,4.55-2.76,1.92-.3,3.64.1,5.18,1.22,1.54,1.12,2.46,2.63,2.76,4.55.3,1.92-.1,3.64-1.22,5.18-1.12,1.54-2.63,2.46-4.55,2.76-1.92.3-3.64-.1-5.18-1.22Z"/>
                    <path class="cls-8"
                          d="m294.06,73.95c-1.12-1.54-1.52-3.26-1.22-5.18.31-1.92,1.23-3.43,2.76-4.55,1.54-1.12,3.26-1.52,5.18-1.22,1.92.3,3.43,1.22,4.55,2.76,1.12,1.54,1.52,3.26,1.22,5.18-.3,1.92-1.22,3.43-2.76,4.55-1.54,1.12-3.26,1.52-5.18,1.22-1.92-.3-3.43-1.22-4.55-2.76Z"/>
                    <path class="cls-5"
                          d="m335.34,75.99c-.59-1.81-.44-3.57.44-5.3.88-1.73,2.23-2.89,4.03-3.48,1.81-.59,3.57-.44,5.3.44s2.89,2.22,3.47,4.03c.59,1.81.44,3.57-.44,5.3-.88,1.73-2.22,2.89-4.03,3.47-1.81.59-3.57.44-5.31-.44-1.73-.88-2.88-2.22-3.47-4.03Z"/>
                    <path class="cls-2"
                          d="m373.97,90.68c0-1.9.68-3.54,2.06-4.91,1.37-1.37,3.01-2.06,4.91-2.06,1.9,0,3.54.69,4.91,2.06,1.37,1.37,2.06,3.01,2.06,4.91,0,1.9-.69,3.54-2.06,4.91-1.37,1.37-3.01,2.06-4.91,2.06-1.9,0-3.54-.69-4.91-2.06-1.37-1.37-2.06-3.01-2.06-4.91Z"/>
                    <path class="cls-4"
                          d="m406.17,116.59c.59-1.81,1.74-3.15,3.47-4.03,1.73-.88,3.5-1.03,5.31-.44,1.81.59,3.15,1.75,4.03,3.47.88,1.73,1.03,3.5.44,5.3-.59,1.81-1.75,3.15-3.47,4.03-1.73.88-3.5,1.03-5.3.44-1.81-.59-3.15-1.75-4.03-3.48-.88-1.73-1.03-3.5-.44-5.3Z"/>
                    <path class="cls-9"
                          d="m193.67,139.59c-1.9,0-3.54-.68-4.91-2.06-1.37-1.37-2.06-3.01-2.06-4.91s.69-3.54,2.06-4.91c1.37-1.37,3.01-2.06,4.91-2.06s3.54.69,4.91,2.06,2.06,3.01,2.06,4.91-.69,3.54-2.06,4.91c-1.37,1.37-3.01,2.06-4.91,2.06Z"/>
                    <path class="cls-6"
                          d="m193.67,206.71c-1.55,0-2.83-.51-3.85-1.53-1.02-1.02-1.53-2.3-1.53-3.85v-46.96c0-1.62.51-2.92,1.53-3.91,1.02-.98,2.3-1.48,3.85-1.48s2.92.49,3.9,1.48c.99.99,1.48,2.29,1.48,3.91v46.96c0,1.55-.49,2.83-1.48,3.85-.99,1.02-2.29,1.53-3.9,1.53Z"/>
                </svg>
            ) : (
                ""
            )}
            {displayCatchphrase ? (
                <svg xmlns="http://www.w3.org/2000/svg" id="Calque_1" viewBox={appearance}>
                    <defs>
                        <style>{style}</style>
                    </defs>
                    <path className="cls-6"
                          d="m150.95,284.36c-.64-1.29-1.56-2.3-2.75-3.02-1.19-.73-2.59-1.09-4.19-1.09s-3.1.38-4.35,1.14c-1.26.76-2.24,1.8-2.96,3.12-.72,1.32-1.08,2.84-1.08,4.57s.38,3.23,1.14,4.56c.76,1.33,1.8,2.37,3.13,3.12,1.33.75,2.86,1.12,4.59,1.12.97,0,1.99-.19,3.07-.57,1.08-.38,1.96-.87,2.66-1.46.3-.23.44-.51.43-.82-.01-.32-.17-.61-.49-.89-.21-.19-.48-.28-.81-.27-.33.01-.62.11-.87.3-.44.38-1.04.7-1.79.95-.75.25-1.48.38-2.2.38-1.25,0-2.35-.27-3.31-.82-.96-.55-1.72-1.31-2.26-2.28-.42-.73-.67-1.55-.77-2.44h12.55c.36,0,.65-.11.89-.33.23-.22.35-.51.35-.87,0-1.65-.32-3.11-.97-4.4Zm-10.1-.92c.9-.55,1.95-.82,3.15-.82,1.14,0,2.12.26,2.94.79.82.53,1.46,1.26,1.9,2.19.32.67.51,1.4.59,2.18h-11.25c.11-.73.3-1.42.61-2.04.48-.98,1.16-1.75,2.06-2.3Z"/>
                    <path className="cls-6"
                          d="m172.42,283.89c-.69-1.2-1.61-2.12-2.79-2.74-1.17-.62-2.5-.93-3.97-.93-1.37,0-2.62.31-3.74.92-.73.4-1.36.91-1.9,1.49v-.86c0-.42-.12-.75-.36-1-.24-.24-.56-.36-.97-.36-.42,0-.75.12-1,.36-.24.24-.36.58-.36,1v14.63c0,.4.12.72.36.97.24.24.57.36,1,.36.4,0,.72-.12.97-.36.24-.24.36-.56.36-.97v-9.21c0-.87.23-1.63.7-2.3.46-.67,1.09-1.19,1.88-1.58.79-.39,1.68-.59,2.68-.59,1.06,0,1.99.21,2.82.62.82.41,1.47,1.02,1.95,1.84s.71,1.86.71,3.15v8.07c0,.38.13.7.38.95s.58.38.98.38c.38,0,.7-.13.95-.38s.38-.57.38-.95v-8.07c0-1.75-.34-3.23-1.03-4.43Z"/>
                    <path className="cls-6"
                          d="m186.24,297.88c-1.31,0-2.58-.22-3.81-.65-1.23-.43-2.22-1.05-2.96-1.85-.25-.27-.36-.59-.32-.95.04-.36.21-.65.51-.89.34-.25.68-.36,1.03-.32.35.04.64.19.87.44.46.55,1.11.99,1.93,1.31.82.33,1.73.49,2.72.49,1.46,0,2.53-.25,3.21-.74.69-.5,1.04-1.11,1.06-1.85,0-.72-.34-1.32-1.03-1.8-.69-.49-1.85-.87-3.5-1.14-2.13-.36-3.69-.96-4.67-1.8s-1.47-1.86-1.47-3.04c0-1.08.3-1.97.89-2.69s1.37-1.26,2.33-1.62c.96-.36,2-.54,3.12-.54,1.41,0,2.64.24,3.67.71,1.03.48,1.87,1.11,2.5,1.92.23.3.33.61.3.93-.03.33-.21.59-.52.78-.27.19-.6.25-.97.19-.37-.06-.68-.23-.93-.51-.53-.57-1.13-.99-1.8-1.25s-1.45-.4-2.31-.4c-1.1,0-1.98.22-2.66.65-.68.43-1.01.99-1.01,1.66,0,.46.12.87.36,1.2.24.34.68.64,1.3.9.62.26,1.51.5,2.68.71,1.58.27,2.83.66,3.75,1.16.92.5,1.57,1.07,1.96,1.73.39.65.59,1.38.59,2.18,0,.99-.28,1.86-.84,2.61-.56.75-1.35,1.35-2.36,1.79-1.01.44-2.22.67-3.61.67Z"/>
                    <path className="cls-6"
                          d="m212.94,284.36c-.64-1.29-1.56-2.3-2.75-3.02-1.19-.73-2.59-1.09-4.19-1.09s-3.1.38-4.35,1.14c-1.26.76-2.24,1.8-2.96,3.12-.72,1.32-1.08,2.84-1.08,4.57s.38,3.23,1.14,4.56c.76,1.33,1.8,2.37,3.13,3.12,1.33.75,2.86,1.12,4.59,1.12.97,0,1.99-.19,3.07-.57,1.08-.38,1.96-.87,2.66-1.46.3-.23.44-.51.43-.82-.01-.32-.17-.61-.49-.89-.21-.19-.48-.28-.81-.27-.33.01-.62.11-.87.3-.44.38-1.04.7-1.79.95-.75.25-1.48.38-2.2.38-1.25,0-2.35-.27-3.31-.82-.96-.55-1.72-1.31-2.26-2.28-.42-.73-.67-1.55-.77-2.44h12.55c.36,0,.65-.11.89-.33.23-.22.35-.51.35-.87,0-1.65-.32-3.11-.97-4.4Zm-10.1-.92c.9-.55,1.95-.82,3.15-.82,1.14,0,2.12.26,2.94.79.82.53,1.46,1.26,1.9,2.19.32.67.51,1.4.59,2.18h-11.25c.11-.73.3-1.42.61-2.04.48-.98,1.16-1.75,2.06-2.3Z"/>
                    <path className="cls-6"
                          d="m243.05,283.52c-.54-1.07-1.28-1.88-2.23-2.45-.95-.57-2.04-.85-3.26-.85-1.33,0-2.52.3-3.56.89-.86.49-1.56,1.15-2.12,1.96-.5-.84-1.15-1.51-1.95-1.99-.95-.57-2.04-.85-3.26-.85s-2.35.3-3.32.89c-.5.3-.94.67-1.33,1.1v-.43c0-.4-.12-.73-.36-.98-.24-.25-.56-.38-.97-.38-.42,0-.75.13-1,.38-.24.25-.36.58-.36.98v14.63c0,.4.12.72.36.97.24.24.57.36,1,.36.4,0,.72-.12.97-.36.24-.24.36-.56.36-.97v-9.21c0-1.33.4-2.41,1.2-3.23.8-.82,1.83-1.23,3.07-1.23,1.16,0,2.12.4,2.87,1.2.75.8,1.12,1.93,1.12,3.39v9.09c0,.4.13.72.38.97.25.24.57.36.95.36.4,0,.72-.12.97-.36.24-.24.36-.56.36-.97v-9.09c0-1.37.4-2.48,1.19-3.32.79-.84,1.81-1.27,3.06-1.27s2.14.4,2.88,1.2c.74.8,1.11,1.93,1.11,3.39v9.09c0,.4.13.72.38.97.25.24.58.36.98.36.38,0,.7-.12.95-.36.25-.24.38-.56.38-.97v-9.09c0-1.46-.27-2.72-.81-3.78Z"/>
                    <path className="cls-6"
                          d="m259.24,297.88c-1.65,0-3.12-.39-4.43-1.16-1.31-.77-2.35-1.81-3.12-3.13-.77-1.32-1.17-2.8-1.19-4.45v-14.78c0-.42.12-.75.36-1,.24-.24.57-.36,1-.36.4,0,.72.12.97.36.24.24.36.58.36,1v9.05c.7-.95,1.6-1.71,2.72-2.3,1.12-.58,2.36-.87,3.74-.87,1.6,0,3.04.39,4.31,1.16,1.27.77,2.26,1.82,2.99,3.15.73,1.33,1.09,2.83,1.09,4.5s-.39,3.2-1.16,4.53c-.77,1.33-1.82,2.38-3.13,3.15-1.32.77-2.82,1.16-4.51,1.16Zm0-2.41c1.18,0,2.24-.29,3.18-.86.94-.57,1.68-1.33,2.22-2.3.54-.96.81-2.05.81-3.28s-.27-2.29-.81-3.26-1.28-1.74-2.22-2.3c-.94-.56-2-.84-3.18-.84s-2.18.28-3.12.84c-.94.56-1.67,1.32-2.2,2.3-.53.97-.79,2.06-.79,3.26s.26,2.32.79,3.28c.53.96,1.26,1.73,2.2,2.3.94.57,1.98.86,3.12.86Z"/>
                    <path className="cls-6"
                          d="m278.01,297.72c-.89,0-1.67-.24-2.34-.71-.68-.48-1.2-1.13-1.58-1.98-.38-.84-.57-1.82-.57-2.91v-17.79c0-.4.12-.72.36-.97.24-.24.56-.36.97-.36.38,0,.69.12.93.36.24.24.36.56.36.97v17.79c0,.87.17,1.58.52,2.14.35.56.8.84,1.35.84h.79c.34,0,.61.12.82.36.21.24.32.55.32.93,0,.4-.15.72-.46.97-.31.24-.7.36-1.19.36h-.29Z"/>
                    <path className="cls-6"
                          d="m298.99,284.36c-.64-1.29-1.56-2.3-2.75-3.02-1.19-.73-2.59-1.09-4.19-1.09s-3.1.38-4.35,1.14c-1.26.76-2.24,1.8-2.96,3.12-.72,1.32-1.08,2.84-1.08,4.57s.38,3.23,1.14,4.56c.76,1.33,1.8,2.37,3.13,3.12,1.33.75,2.86,1.12,4.59,1.12.97,0,1.99-.19,3.07-.57,1.08-.38,1.96-.87,2.66-1.46.3-.23.44-.51.43-.82-.01-.32-.17-.61-.49-.89-.21-.19-.48-.28-.81-.27-.33.01-.62.11-.87.3-.44.38-1.04.7-1.79.95-.75.25-1.48.38-2.2.38-1.25,0-2.35-.27-3.31-.82-.96-.55-1.72-1.31-2.26-2.28-.42-.73-.67-1.55-.77-2.44h12.55c.36,0,.65-.11.89-.33.23-.22.35-.51.35-.87,0-1.65-.32-3.11-.97-4.4Zm-10.1-.92c.9-.55,1.95-.82,3.15-.82,1.14,0,2.12.26,2.94.79.82.53,1.46,1.26,1.9,2.19.32.67.51,1.4.59,2.18h-11.25c.11-.73.3-1.42.61-2.04.48-.98,1.16-1.75,2.06-2.3Z"/>
                    <path className="cls-6"
                          d="m317.3,305.13c-.42,0-.75-.12-1-.36-.24-.24-.36-.58-.36-1v-14.79c.02-1.65.42-3.13,1.19-4.46.77-1.33,1.81-2.37,3.12-3.13,1.31-.76,2.79-1.14,4.43-1.14s3.19.39,4.51,1.16c1.32.77,2.36,1.82,3.13,3.15.77,1.33,1.16,2.84,1.16,4.53s-.37,3.17-1.11,4.5c-.74,1.33-1.74,2.38-2.99,3.15-1.26.77-2.69,1.16-4.29,1.16-1.37,0-2.62-.29-3.74-.87-1.12-.58-2.03-1.35-2.72-2.3v9.05c0,.42-.12.75-.36,1-.24.24-.56.36-.97.36Zm7.38-9.66c1.18,0,2.24-.28,3.18-.84.94-.56,1.68-1.32,2.22-2.3.54-.97.81-2.06.81-3.26s-.27-2.32-.81-3.28-1.27-1.73-2.2-2.3c-.93-.57-1.98-.85-3.17-.85s-2.21.28-3.15.84c-.94.56-1.67,1.32-2.2,2.3-.53.97-.79,2.07-.79,3.29s.26,2.29.79,3.26c.53.97,1.26,1.74,2.18,2.3.93.56,1.97.84,3.13.84Z"/>
                    <path className="cls-6"
                          d="m347.47,297.88c-1.71,0-3.22-.38-4.54-1.14-1.32-.76-2.36-1.8-3.12-3.13-.76-1.33-1.14-2.84-1.14-4.53s.38-3.23,1.14-4.56c.76-1.33,1.8-2.37,3.12-3.13,1.32-.76,2.83-1.14,4.54-1.14s3.19.38,4.5,1.14c1.31.76,2.34,1.81,3.1,3.13.76,1.33,1.15,2.85,1.17,4.56,0,1.69-.38,3.2-1.14,4.53-.76,1.33-1.8,2.37-3.12,3.13-1.32.76-2.82,1.14-4.51,1.14Zm0-2.41c1.18,0,2.24-.28,3.17-.84.93-.56,1.66-1.32,2.18-2.28.53-.96.79-2.05.79-3.28s-.26-2.32-.79-3.29c-.53-.97-1.26-1.74-2.18-2.3-.93-.56-1.98-.84-3.17-.84s-2.24.28-3.18.84c-.94.56-1.68,1.32-2.22,2.3s-.81,2.07-.81,3.29.27,2.32.81,3.28c.54.96,1.28,1.72,2.22,2.28.94.56,2,.84,3.18.84Z"/>
                    <path className="cls-6"
                          d="m377.71,280.77c-.25-.24-.58-.36-.98-.36s-.72.12-.97.36c-.24.24-.36.56-.36.97v9.21c0,.87-.23,1.63-.7,2.3-.46.66-1.1,1.19-1.9,1.58-.8.39-1.7.59-2.69.59s-1.96-.21-2.79-.62-1.48-1.02-1.96-1.84c-.49-.81-.73-1.86-.73-3.15v-8.07c0-.38-.13-.7-.38-.95-.25-.25-.57-.38-.95-.38s-.7.13-.95.38c-.25.25-.38.57-.38.95v8.07c0,1.75.34,3.23,1.01,4.43.68,1.2,1.6,2.12,2.77,2.74,1.17.62,2.51.93,4,.93,1.37,0,2.62-.31,3.74-.92.74-.4,1.36-.91,1.9-1.5v.87c0,.42.12.75.36,1,.24.24.56.36.97.36s.73-.12.98-.36c.25-.24.38-.57.38-1v-14.63c0-.4-.13-.72-.38-.97Z"/>
                    <path className="cls-6"
                          d="m395.55,280.69c-.56-.32-1.38-.48-2.45-.48-1.22,0-2.35.3-3.37.89-.79.46-1.46,1.05-2.01,1.76v-1.13c0-.42-.11-.75-.33-.98-.22-.23-.54-.35-.97-.35s-.75.12-.98.35c-.23.23-.35.56-.35.98v14.66c0,.42.12.75.35.98.23.23.56.35.98.35s.74-.12.97-.35c.22-.23.33-.56.33-.98v-9.4c0-.97.29-1.8.85-2.5.57-.7,1.33-1.2,2.3-1.5.96-.31,2.06-.39,3.31-.24.34.04.64.06.9.06s.5-.06.7-.19c.2-.13.33-.34.4-.63.15-.55-.06-.98-.62-1.3Z"/>
                    <path className="cls-6"
                          d="m415.6,297.72c-.89,0-1.67-.24-2.34-.71-.68-.48-1.2-1.13-1.58-1.98-.38-.84-.57-1.82-.57-2.91v-17.79c0-.4.12-.72.36-.97.24-.24.56-.36.97-.36.38,0,.69.12.93.36.24.24.36.56.36.97v17.79c0,.87.17,1.58.52,2.14.35.56.8.84,1.35.84h.79c.34,0,.61.12.82.36.21.24.32.55.32.93,0,.4-.15.72-.46.97-.31.24-.7.36-1.19.36h-.29Z"/>
                    <path className="cls-6"
                          d="m420.13,281.19c-.27,0-.5-.13-.68-.38-.18-.25-.21-.54-.08-.85.34-.78.54-1.43.6-1.95.06-.52.07-.97.02-1.35-.05-.38-.12-.74-.21-1.09-.08-.35-.13-.73-.13-1.16,0-.53.17-.95.51-1.27.34-.32.75-.47,1.23-.47.55,0,.99.24,1.33.71.34.48.51,1.11.51,1.92,0,.51-.11,1.09-.32,1.74-.21.65-.48,1.3-.79,1.95-.32.64-.65,1.17-1,1.58-.35.41-.68.62-1,.62Z"/>
                    <path className="cls-6"
                          d="m444.4,284.55c-.77-1.33-1.82-2.38-3.13-3.15-1.32-.77-2.81-1.16-4.48-1.16s-3.13.39-4.46,1.16c-1.33.77-2.38,1.82-3.15,3.15-.77,1.33-1.16,2.84-1.16,4.53s.37,3.17,1.11,4.5c.74,1.33,1.74,2.38,3.01,3.15,1.27.77,2.7,1.16,4.31,1.16s2.92-.36,4.13-1.09c.91-.54,1.67-1.23,2.3-2.07v1.67c0,.4.13.72.4.97.26.24.6.36,1,.36.38,0,.7-.12.95-.36.25-.24.38-.56.38-.97v-7.31c-.02-1.69-.42-3.2-1.19-4.53Zm-2.25,7.79c-.54.97-1.27,1.74-2.18,2.3-.92.56-1.97.84-3.15.84s-2.21-.28-3.15-.84c-.94-.56-1.68-1.32-2.23-2.3-.55-.97-.82-2.06-.82-3.26s.27-2.32.82-3.28c.55-.96,1.29-1.73,2.23-2.3.94-.57,1.99-.85,3.15-.85s2.23.29,3.15.85c.92.57,1.65,1.34,2.18,2.3s.81,2.05.81,3.28-.27,2.29-.81,3.26Z"/>
                    <path className="cls-6"
                          d="m465.72,281.24c-.11-.31-.33-.54-.67-.71-.32-.15-.64-.17-.98-.06s-.57.32-.7.63l-5.84,12.8-5.9-12.8c-.17-.3-.41-.5-.71-.62s-.62-.09-.93.08c-.34.17-.56.4-.68.7-.12.3-.1.59.05.89l6.93,14.69c.27.59.69.89,1.24.89.59,0,1.02-.3,1.3-.89l6.87-14.72c.13-.27.14-.56.03-.87Z"/>
                    <path className="cls-6"
                          d="m484.79,284.36c-.64-1.29-1.56-2.3-2.75-3.02-1.19-.73-2.59-1.09-4.19-1.09s-3.1.38-4.35,1.14c-1.26.76-2.24,1.8-2.96,3.12-.72,1.32-1.08,2.84-1.08,4.57s.38,3.23,1.14,4.56c.76,1.33,1.8,2.37,3.13,3.12,1.33.75,2.86,1.12,4.59,1.12.97,0,1.99-.19,3.07-.57,1.08-.38,1.96-.87,2.66-1.46.3-.23.44-.51.43-.82-.01-.32-.17-.61-.49-.89-.21-.19-.48-.28-.81-.27-.33.01-.62.11-.87.3-.44.38-1.04.7-1.79.95s-1.48.38-2.2.38c-1.25,0-2.35-.27-3.31-.82-.96-.55-1.72-1.31-2.26-2.28-.42-.73-.67-1.55-.77-2.44h12.55c.36,0,.65-.11.89-.33.23-.22.35-.51.35-.87,0-1.65-.32-3.11-.97-4.4Zm-12.76,3.42c.11-.73.31-1.42.61-2.04.48-.98,1.16-1.75,2.06-2.3.9-.55,1.95-.82,3.15-.82,1.14,0,2.12.26,2.94.79.82.53,1.46,1.26,1.9,2.19.32.67.51,1.4.59,2.18h-11.25Z"/>
                    <path className="cls-6"
                          d="m506.26,283.89c-.69-1.2-1.61-2.12-2.79-2.74-1.17-.62-2.5-.93-3.97-.93-1.37,0-2.62.31-3.74.92-.73.4-1.36.91-1.9,1.49v-.86c0-.42-.12-.75-.36-1-.24-.24-.56-.36-.97-.36-.42,0-.75.12-1,.36s-.36.58-.36,1v14.63c0,.4.12.72.36.97.24.24.57.36,1,.36s.72-.12.97-.36c.24-.24.36-.56.36-.97v-9.21c0-.87.23-1.63.7-2.3.46-.67,1.09-1.19,1.88-1.58s1.68-.59,2.68-.59c1.06,0,1.99.21,2.82.62.82.41,1.47,1.02,1.95,1.84s.71,1.86.71,3.15v8.07c0,.38.13.7.38.95s.58.38.98.38c.38,0,.7-.13.95-.38s.38-.57.38-.95v-8.07c0-1.75-.34-3.23-1.03-4.43Z"/>
                    <path className="cls-6"
                          d="m515.74,277.27c-.51,0-.94-.18-1.3-.55-.36-.37-.54-.81-.54-1.31s.18-.96.55-1.3c.37-.34.8-.51,1.28-.51s.91.17,1.28.51c.37.34.55.77.55,1.3s-.18.94-.54,1.31c-.36.37-.79.55-1.3.55Zm.03,20.45c-.4,0-.73-.12-.98-.36-.25-.24-.38-.57-.38-1v-14.6c0-.42.13-.75.38-1,.25-.24.58-.36.98-.36s.72.12.97.36c.24.24.36.58.36,1v14.6c0,.42-.12.75-.36,1s-.56.36-.97.36Z"/>
                    <path className="cls-6"
                          d="m535.01,280.69c-.56-.32-1.38-.48-2.45-.48-1.22,0-2.35.3-3.37.89-.79.46-1.46,1.05-2.01,1.76v-1.13c0-.42-.11-.75-.33-.98-.22-.23-.54-.35-.97-.35s-.75.12-.98.35c-.23.23-.35.56-.35.98v14.66c0,.42.12.75.35.98.23.23.56.35.98.35s.74-.12.97-.35c.22-.23.33-.56.33-.98v-9.4c0-.97.29-1.8.86-2.5.57-.7,1.33-1.2,2.3-1.5.96-.31,2.06-.39,3.31-.24.34.04.64.06.9.06s.5-.06.7-.19c.2-.13.33-.34.4-.63.15-.55-.06-.98-.62-1.3Z"/>
                    <path className="cls-6"
                          d="m559.08,297.88c-1.67,0-3.17-.39-4.5-1.16-1.33-.77-2.38-1.82-3.15-3.15-.77-1.33-1.16-2.84-1.16-4.53s.37-3.17,1.11-4.5c.74-1.33,1.74-2.38,3.01-3.15,1.27-.77,2.7-1.16,4.31-1.16,1.37,0,2.61.29,3.72.87,1.11.58,2.01,1.35,2.71,2.3v-9.05c0-.42.13-.75.4-1,.26-.24.59-.36.97-.36.4,0,.73.12.98.36.25.24.38.58.38,1v14.78c-.04,1.65-.45,3.13-1.22,4.45-.77,1.32-1.81,2.36-3.12,3.13-1.31.77-2.79,1.16-4.43,1.16Zm0-2.41c1.18,0,2.23-.29,3.15-.86.92-.57,1.65-1.33,2.18-2.3.54-.96.81-2.05.81-3.28s-.27-2.29-.81-3.26-1.27-1.74-2.18-2.3c-.92-.56-1.97-.84-3.15-.84s-2.21.28-3.15.84c-.94.56-1.68,1.32-2.23,2.3-.55.97-.82,2.06-.82,3.26s.27,2.32.82,3.28c.55.96,1.29,1.73,2.23,2.3.94.57,1.99.86,3.15.86Z"/>
                    <path className="cls-6"
                          d="m589.27,284.36c-.64-1.29-1.56-2.3-2.75-3.02-1.19-.73-2.59-1.09-4.19-1.09s-3.1.38-4.35,1.14c-1.26.76-2.24,1.8-2.96,3.12-.72,1.32-1.08,2.84-1.08,4.57s.38,3.23,1.14,4.56c.76,1.33,1.8,2.37,3.13,3.12,1.33.75,2.86,1.12,4.59,1.12.97,0,1.99-.19,3.07-.57,1.08-.38,1.96-.87,2.66-1.46.3-.23.44-.51.43-.82-.01-.32-.17-.61-.49-.89-.21-.19-.48-.28-.81-.27-.33.01-.62.11-.87.3-.44.38-1.04.7-1.79.95s-1.48.38-2.2.38c-1.25,0-2.35-.27-3.31-.82-.96-.55-1.72-1.31-2.26-2.28-.42-.73-.67-1.55-.77-2.44h12.55c.36,0,.65-.11.89-.33.23-.22.35-.51.35-.87,0-1.65-.32-3.11-.97-4.4Zm-10.1-.92c.9-.55,1.95-.82,3.15-.82,1.14,0,2.12.26,2.94.79.82.53,1.46,1.26,1.9,2.19.32.67.51,1.4.59,2.18h-11.25c.11-.73.31-1.42.61-2.04.48-.98,1.16-1.75,2.06-2.3Z"/>
                    <path className="cls-6"
                          d="m610.08,297.72c-.89,0-1.67-.24-2.34-.71-.68-.48-1.2-1.13-1.58-1.98-.38-.84-.57-1.82-.57-2.91v-17.79c0-.4.12-.72.36-.97.24-.24.56-.36.97-.36.38,0,.69.12.93.36.24.24.36.56.36.97v17.79c0,.87.17,1.58.52,2.14.35.56.8.84,1.35.84h.79c.34,0,.61.12.82.36.21.24.32.55.32.93,0,.4-.15.72-.46.97-.31.24-.7.36-1.19.36h-.29Z"/>
                    <path className="cls-6"
                          d="m614.61,281.19c-.27,0-.5-.13-.68-.38-.18-.25-.21-.54-.08-.85.34-.78.54-1.43.6-1.95s.07-.97.02-1.35c-.05-.38-.12-.74-.21-1.09-.08-.35-.13-.73-.13-1.16,0-.53.17-.95.51-1.27.34-.32.75-.47,1.23-.47.55,0,.99.24,1.33.71.34.48.51,1.11.51,1.92,0,.51-.11,1.09-.32,1.74-.21.65-.48,1.3-.79,1.95-.32.64-.65,1.17-1,1.58-.35.41-.68.62-1,.62Z"/>
                    <path className="cls-6"
                          d="m638.88,284.55c-.77-1.33-1.82-2.38-3.13-3.15-1.32-.77-2.81-1.16-4.48-1.16s-3.13.39-4.46,1.16c-1.33.77-2.38,1.82-3.15,3.15-.77,1.33-1.16,2.84-1.16,4.53s.37,3.17,1.11,4.5c.74,1.33,1.74,2.38,3.01,3.15,1.27.77,2.7,1.16,4.31,1.16s2.92-.36,4.13-1.09c.91-.54,1.67-1.23,2.3-2.07v1.67c0,.4.13.72.4.97.26.24.6.36,1,.36.38,0,.7-.12.95-.36.25-.24.38-.56.38-.97v-7.31c-.02-1.69-.42-3.2-1.19-4.53Zm-2.25,7.79c-.54.97-1.27,1.74-2.18,2.3-.92.56-1.97.84-3.15.84s-2.21-.28-3.15-.84c-.94-.56-1.68-1.32-2.23-2.3-.55-.97-.82-2.06-.82-3.26s.27-2.32.82-3.28c.55-.96,1.29-1.73,2.23-2.3.94-.57,1.99-.85,3.15-.85s2.23.29,3.15.85c.92.57,1.65,1.34,2.18,2.3s.81,2.05.81,3.28-.27,2.29-.81,3.26Z"/>
                    <path className="cls-6"
                          d="m652.22,297.88c-1.31,0-2.58-.22-3.82-.65-1.23-.43-2.22-1.05-2.96-1.85-.25-.27-.36-.59-.32-.95.04-.36.21-.65.51-.89.34-.25.68-.36,1.03-.32s.64.19.87.44c.46.55,1.11.99,1.93,1.31.82.33,1.73.49,2.72.49,1.46,0,2.53-.25,3.21-.74.69-.5,1.04-1.11,1.06-1.85,0-.72-.34-1.32-1.03-1.8s-1.85-.87-3.5-1.14c-2.13-.36-3.69-.96-4.67-1.8-.98-.84-1.47-1.86-1.47-3.04,0-1.08.3-1.97.89-2.69.59-.72,1.37-1.26,2.33-1.62.96-.36,2-.54,3.12-.54,1.41,0,2.64.24,3.67.71,1.03.48,1.87,1.11,2.5,1.92.23.3.33.61.3.93-.03.33-.21.59-.52.78-.27.19-.6.25-.97.19-.37-.06-.68-.23-.93-.51-.53-.57-1.13-.99-1.8-1.25-.68-.26-1.45-.4-2.31-.4-1.1,0-1.98.22-2.66.65-.68.43-1.01.99-1.01,1.66,0,.46.12.87.36,1.2.24.34.68.64,1.3.9.62.26,1.51.5,2.68.71,1.58.27,2.83.66,3.75,1.16.92.5,1.57,1.07,1.96,1.73.39.65.59,1.38.59,2.18,0,.99-.28,1.86-.84,2.61-.56.75-1.35,1.35-2.36,1.79-1.01.44-2.22.67-3.61.67Z"/>
                    <path className="cls-6"
                          d="m670.62,297.88c-1.31,0-2.58-.22-3.82-.65-1.23-.43-2.22-1.05-2.96-1.85-.25-.27-.36-.59-.32-.95.04-.36.21-.65.51-.89.34-.25.68-.36,1.03-.32s.64.19.87.44c.46.55,1.11.99,1.93,1.31.82.33,1.73.49,2.72.49,1.46,0,2.53-.25,3.21-.74.69-.5,1.04-1.11,1.06-1.85,0-.72-.34-1.32-1.03-1.8s-1.85-.87-3.5-1.14c-2.13-.36-3.69-.96-4.67-1.8-.98-.84-1.47-1.86-1.47-3.04,0-1.08.3-1.97.89-2.69.59-.72,1.37-1.26,2.33-1.62.96-.36,2-.54,3.12-.54,1.41,0,2.64.24,3.67.71,1.03.48,1.87,1.11,2.5,1.92.23.3.33.61.3.93-.03.33-.21.59-.52.78-.27.19-.6.25-.97.19-.37-.06-.68-.23-.93-.51-.53-.57-1.13-.99-1.8-1.25-.68-.26-1.45-.4-2.31-.4-1.1,0-1.98.22-2.66.65-.68.43-1.01.99-1.01,1.66,0,.46.12.87.36,1.2.24.34.68.64,1.3.9.62.26,1.51.5,2.68.71,1.58.27,2.83.66,3.75,1.16.92.5,1.57,1.07,1.96,1.73.39.65.59,1.38.59,2.18,0,.99-.28,1.86-.84,2.61s-1.35,1.35-2.36,1.79c-1.01.44-2.22.67-3.61.67Z"/>
                    <path className="cls-6"
                          d="m684.67,277.27c-.51,0-.94-.18-1.3-.55-.36-.37-.54-.81-.54-1.31s.18-.96.55-1.3c.37-.34.8-.51,1.28-.51s.91.17,1.28.51c.37.34.55.77.55,1.3s-.18.94-.54,1.31c-.36.37-.79.55-1.3.55Zm.03,20.45c-.4,0-.73-.12-.98-.36-.25-.24-.38-.57-.38-1v-14.6c0-.42.13-.75.38-1,.25-.24.58-.36.98-.36s.72.12.97.36c.24.24.36.58.36,1v14.6c0,.42-.12.75-.36,1s-.56.36-.97.36Z"/>
                    <path className="cls-6"
                          d="m699.58,297.88c-1.31,0-2.58-.22-3.82-.65-1.23-.43-2.22-1.05-2.96-1.85-.25-.27-.36-.59-.32-.95.04-.36.21-.65.51-.89.34-.25.68-.36,1.03-.32s.64.19.87.44c.46.55,1.11.99,1.93,1.31.82.33,1.73.49,2.72.49,1.46,0,2.53-.25,3.21-.74.69-.5,1.04-1.11,1.06-1.85,0-.72-.34-1.32-1.03-1.8s-1.85-.87-3.5-1.14c-2.13-.36-3.69-.96-4.67-1.8-.98-.84-1.47-1.86-1.47-3.04,0-1.08.3-1.97.89-2.69.59-.72,1.37-1.26,2.33-1.62.96-.36,2-.54,3.12-.54,1.41,0,2.64.24,3.67.71,1.03.48,1.87,1.11,2.5,1.92.23.3.33.61.3.93-.03.33-.21.59-.52.78-.27.19-.6.25-.97.19-.37-.06-.68-.23-.93-.51-.53-.57-1.13-.99-1.8-1.25-.68-.26-1.45-.4-2.31-.4-1.1,0-1.98.22-2.66.65-.68.43-1.01.99-1.01,1.66,0,.46.12.87.36,1.2.24.34.68.64,1.3.9.62.26,1.51.5,2.68.71,1.58.27,2.83.66,3.75,1.16.92.5,1.57,1.07,1.96,1.73.39.65.59,1.38.59,2.18,0,.99-.28,1.86-.84,2.61-.56.75-1.35,1.35-2.36,1.79-1.01.44-2.22.67-3.61.67Z"/>
                    <path className="cls-6"
                          d="m720.29,295.44c-.23-.25-.52-.38-.86-.38h-.95c-.89,0-1.62-.33-2.2-.98-.58-.65-.87-1.48-.87-2.47v-8.26h3.29c.36,0,.65-.11.89-.33.23-.22.35-.49.35-.81,0-.34-.12-.62-.35-.84-.23-.22-.53-.33-.89-.33h-3.29v-4.81c0-.38-.13-.7-.38-.95-.25-.25-.57-.38-.95-.38-.4,0-.72.13-.97.38-.24.25-.36.57-.36.95v4.81h-1.87c-.36,0-.65.11-.89.33-.23.22-.35.5-.35.84,0,.32.12.59.35.81.23.22.53.33.89.33h1.87v8.26c0,1.16.25,2.21.74,3.13.5.93,1.18,1.66,2.04,2.18.87.53,1.85.79,2.94.79h.63c.44,0,.81-.12,1.09-.36.29-.24.43-.56.43-.97,0-.38-.12-.7-.35-.95Z"/>
                    <path className="cls-6"
                          d="m741.36,284.55c-.77-1.33-1.82-2.38-3.13-3.15-1.32-.77-2.81-1.16-4.48-1.16s-3.13.39-4.46,1.16c-1.33.77-2.38,1.82-3.15,3.15-.77,1.33-1.16,2.84-1.16,4.53s.37,3.17,1.11,4.5c.74,1.33,1.74,2.38,3.01,3.15,1.27.77,2.7,1.16,4.31,1.16s2.92-.36,4.13-1.09c.91-.54,1.67-1.23,2.3-2.07v1.67c0,.4.13.72.4.97.26.24.6.36,1,.36.38,0,.7-.12.95-.36.25-.24.38-.56.38-.97v-7.31c-.02-1.69-.42-3.2-1.19-4.53Zm-2.25,7.79c-.54.97-1.27,1.74-2.18,2.3-.92.56-1.97.84-3.15.84s-2.21-.28-3.15-.84c-.94-.56-1.68-1.32-2.23-2.3-.55-.97-.82-2.06-.82-3.26s.27-2.32.82-3.28c.55-.96,1.29-1.73,2.23-2.3.94-.57,1.99-.85,3.15-.85s2.23.29,3.15.85c.92.57,1.65,1.34,2.18,2.3s.81,2.05.81,3.28-.27,2.29-.81,3.26Z"/>
                    <path className="cls-6"
                          d="m763.68,283.89c-.69-1.2-1.61-2.12-2.79-2.74-1.17-.62-2.5-.93-3.97-.93-1.37,0-2.62.31-3.74.92-.73.4-1.36.91-1.9,1.49v-.86c0-.42-.12-.75-.36-1-.24-.24-.56-.36-.97-.36-.42,0-.75.12-1,.36s-.36.58-.36,1v14.63c0,.4.12.72.36.97.24.24.57.36,1,.36s.72-.12.97-.36c.24-.24.36-.56.36-.97v-9.21c0-.87.23-1.63.7-2.3.46-.67,1.09-1.19,1.88-1.58s1.68-.59,2.68-.59c1.06,0,1.99.21,2.82.62.82.41,1.47,1.02,1.95,1.84s.71,1.86.71,3.15v8.07c0,.38.13.7.38.95s.58.38.98.38c.38,0,.7-.13.95-.38s.38-.57.38-.95v-8.07c0-1.75-.34-3.23-1.03-4.43Z"/>
                    <path className="cls-6"
                          d="m779.14,297.88c-1.67,0-3.16-.39-4.46-1.16-1.31-.77-2.34-1.82-3.09-3.15-.75-1.33-1.12-2.83-1.12-4.5s.36-3.2,1.09-4.53c.73-1.33,1.72-2.38,2.98-3.15,1.26-.77,2.71-1.16,4.35-1.16,1.27,0,2.44.25,3.51.74,1.08.5,2.03,1.24,2.85,2.23.25.3.34.6.27.9-.07.31-.27.58-.59.81-.25.17-.53.23-.84.17s-.59-.22-.84-.49c-1.16-1.31-2.62-1.96-4.37-1.96-1.16,0-2.18.27-3.06.82-.88.55-1.56,1.3-2.04,2.26-.49.96-.73,2.07-.73,3.34s.26,2.32.78,3.28c.52.96,1.23,1.72,2.15,2.28.92.56,1.97.84,3.15.84.8,0,1.52-.11,2.17-.32.64-.21,1.22-.54,1.73-.98.3-.23.59-.36.89-.38.3-.02.57.06.82.25.27.25.43.54.48.86.04.32-.06.6-.32.86-1.54,1.41-3.46,2.12-5.76,2.12Z"/>
                    <path className="cls-6"
                          d="m806.01,284.36c-.64-1.29-1.56-2.3-2.75-3.02-1.19-.73-2.59-1.09-4.19-1.09s-3.1.38-4.35,1.14c-1.26.76-2.24,1.8-2.96,3.12-.72,1.32-1.08,2.84-1.08,4.57s.38,3.23,1.14,4.56c.76,1.33,1.8,2.37,3.13,3.12,1.33.75,2.86,1.12,4.59,1.12.97,0,1.99-.19,3.07-.57,1.08-.38,1.96-.87,2.66-1.46.3-.23.44-.51.43-.82-.01-.32-.17-.61-.49-.89-.21-.19-.48-.28-.81-.27-.33.01-.62.11-.87.3-.44.38-1.04.7-1.79.95s-1.48.38-2.2.38c-1.25,0-2.35-.27-3.31-.82-.96-.55-1.72-1.31-2.26-2.28-.42-.73-.67-1.55-.77-2.44h12.55c.36,0,.65-.11.89-.33.23-.22.35-.51.35-.87,0-1.65-.32-3.11-.97-4.4Zm-10.1-.92c.9-.55,1.95-.82,3.15-.82,1.14,0,2.12.26,2.94.79.82.53,1.46,1.26,1.9,2.19.32.67.51,1.4.59,2.18h-11.25c.11-.73.31-1.42.61-2.04.48-.98,1.16-1.75,2.06-2.3Z"/>
                    <path className="cls-6"
                          d="m86.13,120.53c-.99-.98-2.29-1.48-3.9-1.48s-2.83.49-3.85,1.48c-1.02.99-1.53,2.29-1.53,3.9v29.76c0,2.53-.7,4.82-2.11,6.86-1.41,2.04-3.29,3.66-5.64,4.86-2.36,1.2-5.05,1.79-8.07,1.79s-6.02-.62-8.55-1.85c-2.53-1.23-4.54-3.13-6.01-5.7-1.48-2.57-2.22-5.82-2.22-9.76v-25.96c0-1.54-.51-2.83-1.53-3.85-1.02-1.02-2.31-1.53-3.85-1.53s-2.83.51-3.85,1.53c-1.02,1.02-1.53,2.31-1.53,3.85v25.96c0,5.84,1.11,10.76,3.33,14.78,2.21,4.01,5.24,7.05,9.08,9.13,3.83,2.08,8.18,3.11,13.03,3.11s8.92-1.02,12.61-3.06c2.01-1.11,3.78-2.44,5.33-3.99v1.14c0,1.55.51,2.83,1.53,3.85,1.02,1.02,2.31,1.53,3.85,1.53s2.92-.51,3.9-1.53c.99-1.02,1.48-2.3,1.48-3.85v-47.07c0-1.62-.49-2.92-1.48-3.9Z"/>
                    <path className="cls-6"
                          d="m161.75,130.87c-2.21-4.01-5.24-7.05-9.08-9.13-3.84-2.08-8.18-3.11-13.03-3.11s-8.83,1.02-12.56,3.06c-2.03,1.11-3.82,2.45-5.38,3.99v-1.14c0-1.62-.49-2.92-1.48-3.91-.99-.98-2.29-1.48-3.91-1.48s-2.83.49-3.85,1.48c-1.02.99-1.53,2.29-1.53,3.91v47.07c0,1.55.51,2.83,1.53,3.85,1.02,1.02,2.3,1.53,3.85,1.53s2.92-.51,3.91-1.53c.98-1.02,1.48-2.3,1.48-3.85v-29.76c0-2.6.7-4.91,2.11-6.91,1.41-2,3.31-3.6,5.7-4.8,2.39-1.2,5.07-1.79,8.02-1.79,3.23,0,6.1.62,8.6,1.85,2.5,1.23,4.48,3.11,5.96,5.64,1.48,2.53,2.21,5.8,2.21,9.82v25.96c0,1.55.51,2.83,1.53,3.85,1.02,1.02,2.3,1.53,3.85,1.53s2.83-.51,3.85-1.53c1.02-1.02,1.53-2.3,1.53-3.85v-25.96c0-5.84-1.11-10.76-3.33-14.77Z"/>
                    <path className="cls-3"
                          d="m428.79,121.35c1.12-1.54,2.63-2.46,4.55-2.76,1.92-.3,3.65.1,5.18,1.22,1.54,1.12,2.46,2.63,2.76,4.55.3,1.92-.1,3.64-1.22,5.18-1.12,1.54-2.63,2.46-4.55,2.76-1.92.3-3.64-.1-5.18-1.22-1.54-1.12-2.46-2.63-2.76-4.55-.3-1.92.1-3.64,1.22-5.18Z"/>
                    <path className="cls-6"
                          d="m249.6,177.31c-5.7,0-10.73-1.25-15.09-3.75-4.36-2.5-7.79-5.94-10.29-10.34-2.5-4.4-3.75-9.44-3.75-15.14s1.25-10.85,3.75-15.25c2.5-4.4,5.93-7.84,10.29-10.34,4.36-2.5,9.39-3.75,15.09-3.75s10.62,1.25,14.99,3.75c4.36,2.5,7.79,5.95,10.29,10.34,2.5,4.4,3.75,9.48,3.75,15.25s-1.23,10.75-3.69,15.14c-2.46,4.4-5.87,7.85-10.24,10.34-4.36,2.5-9.39,3.75-15.09,3.75Zm0-9.5c3.66,0,6.89-.85,9.71-2.53,2.81-1.69,5.01-4.01,6.6-6.97,1.58-2.96,2.38-6.37,2.38-10.24s-.79-7.3-2.38-10.29c-1.58-2.99-3.78-5.33-6.6-7.02-2.81-1.69-6.05-2.53-9.71-2.53s-6.89.85-9.71,2.53c-2.81,1.69-5.03,4.03-6.65,7.02-1.62,2.99-2.42,6.42-2.42,10.29s.81,7.28,2.42,10.24c1.62,2.95,3.84,5.28,6.65,6.97,2.81,1.69,6.05,2.53,9.71,2.53Z"/>
                    <path className="cls-6"
                          d="m349.8,130.87c-2.21-4.01-5.24-7.05-9.08-9.13-3.84-2.08-8.18-3.11-13.03-3.11s-8.83,1.02-12.56,3.06c-2.03,1.11-3.82,2.45-5.38,3.99v-1.14c0-1.62-.49-2.92-1.48-3.91-.99-.98-2.29-1.48-3.91-1.48s-2.83.49-3.85,1.48c-1.02.99-1.53,2.29-1.53,3.91v47.07c0,1.55.51,2.83,1.53,3.85,1.02,1.02,2.3,1.53,3.85,1.53s2.92-.51,3.91-1.53c.98-1.02,1.48-2.3,1.48-3.85v-29.76c0-2.6.7-4.91,2.11-6.91,1.41-2,3.31-3.6,5.7-4.8,2.39-1.2,5.07-1.79,8.02-1.79,3.23,0,6.1.62,8.6,1.85,2.5,1.23,4.48,3.11,5.96,5.64,1.48,2.53,2.21,5.8,2.21,9.82v25.96c0,1.55.51,2.83,1.53,3.85,1.02,1.02,2.3,1.53,3.85,1.53s2.83-.51,3.85-1.53c1.02-1.02,1.53-2.3,1.53-3.85v-25.96c0-5.84-1.11-10.76-3.33-14.77Z"/>
                    <path className="cls-9"
                          d="m300.11,200.83c-2.57-4.43-6.03-7.93-10.4-10.5-4.36-2.57-9.29-3.85-14.77-3.85s-10.43,1.29-14.83,3.85c-4.4,2.57-7.88,6.07-10.45,10.5-2.57,4.43-3.85,9.43-3.85,14.99s1.18,10.53,3.54,14.93c2.36,4.4,5.58,7.88,9.66,10.45,4.08,2.57,8.69,3.85,13.83,3.85s9.74-1.28,13.83-3.85c2.51-1.58,4.69-3.51,6.54-5.77v3.93c0,1.55.51,2.83,1.53,3.85,1.02,1.02,2.3,1.53,3.85,1.53s2.83-.51,3.85-1.53,1.53-2.3,1.53-3.85v-23.53c0-5.56-1.29-10.55-3.85-14.99Zm-15.57,32.13c-2.81,1.73-6.01,2.59-9.6,2.59s-6.7-.86-9.55-2.59c-2.85-1.72-5.1-4.08-6.76-7.07s-2.48-6.35-2.48-10.08.83-7.19,2.48-10.19c1.65-2.99,3.9-5.35,6.76-7.07,2.85-1.72,6.03-2.59,9.55-2.59s6.79.86,9.6,2.59c2.81,1.72,5.03,4.08,6.65,7.07,1.62,2.99,2.43,6.39,2.43,10.19s-.81,7.09-2.43,10.08c-1.62,2.99-3.84,5.35-6.65,7.07Z"/>
                    <path className="cls-9"
                          d="m341.95,245.05c-4.93,0-9.48-.76-13.67-2.27-4.19-1.51-7.44-3.43-9.76-5.75-1.06-1.12-1.5-2.41-1.32-3.85.17-1.44.86-2.62,2.06-3.54,1.41-1.12,2.8-1.6,4.17-1.42,1.37.18,2.55.79,3.54,1.85,1.2,1.34,3.11,2.59,5.75,3.75,2.64,1.16,5.57,1.74,8.81,1.74,4.08,0,7.19-.67,9.34-2,2.14-1.34,3.25-3.06,3.32-5.17.07-2.11-.93-3.94-3.01-5.49-2.08-1.55-5.86-2.81-11.34-3.8-7.11-1.41-12.26-3.52-15.46-6.33-3.2-2.81-4.8-6.26-4.8-10.34,0-3.59,1.05-6.56,3.17-8.92,2.11-2.36,4.82-4.12,8.13-5.28,3.31-1.16,6.75-1.74,10.34-1.74,4.64,0,8.76.74,12.35,2.22,3.59,1.48,6.44,3.52,8.55,6.12.98,1.12,1.44,2.3,1.37,3.54-.07,1.23-.67,2.27-1.79,3.11-1.13.78-2.46,1.02-4.01.74-1.55-.28-2.85-.91-3.9-1.9-1.76-1.69-3.66-2.85-5.7-3.48-2.04-.63-4.4-.95-7.07-.95-3.1,0-5.72.53-7.86,1.58-2.15,1.05-3.22,2.6-3.22,4.64,0,1.27.33,2.41,1,3.43.67,1.02,1.99,1.94,3.96,2.74,1.97.81,4.86,1.6,8.65,2.37,5.28,1.06,9.44,2.39,12.51,4.01,3.06,1.62,5.26,3.5,6.6,5.64,1.33,2.15,2,4.63,2,7.44,0,3.24-.86,6.16-2.59,8.76-1.72,2.6-4.26,4.68-7.6,6.23s-7.51,2.32-12.51,2.32Z"/>
                    <path className="cls-9"
                          d="m400.21,245.05c-4.93,0-9.48-.76-13.67-2.27-4.19-1.51-7.44-3.43-9.76-5.75-1.06-1.12-1.5-2.41-1.32-3.85.17-1.44.86-2.62,2.06-3.54,1.41-1.12,2.8-1.6,4.17-1.42,1.37.18,2.55.79,3.54,1.85,1.2,1.34,3.11,2.59,5.75,3.75,2.64,1.16,5.57,1.74,8.81,1.74,4.08,0,7.19-.67,9.34-2,2.14-1.34,3.25-3.06,3.32-5.17.07-2.11-.93-3.94-3.01-5.49-2.08-1.55-5.86-2.81-11.34-3.8-7.11-1.41-12.26-3.52-15.46-6.33-3.2-2.81-4.8-6.26-4.8-10.34,0-3.59,1.05-6.56,3.17-8.92,2.11-2.36,4.82-4.12,8.13-5.28,3.31-1.16,6.75-1.74,10.34-1.74,4.64,0,8.76.74,12.35,2.22,3.59,1.48,6.44,3.52,8.55,6.12.98,1.12,1.44,2.3,1.37,3.54-.07,1.23-.67,2.27-1.79,3.11-1.13.78-2.46,1.02-4.01.74-1.55-.28-2.85-.91-3.9-1.9-1.76-1.69-3.66-2.85-5.7-3.48-2.04-.63-4.4-.95-7.07-.95-3.1,0-5.72.53-7.86,1.58-2.15,1.05-3.22,2.6-3.22,4.64,0,1.27.33,2.41,1,3.43.67,1.02,1.99,1.94,3.96,2.74,1.97.81,4.86,1.6,8.65,2.37,5.28,1.06,9.44,2.39,12.51,4.01,3.06,1.62,5.26,3.5,6.6,5.64,1.33,2.15,2,4.63,2,7.44,0,3.24-.86,6.16-2.59,8.76-1.72,2.6-4.26,4.68-7.6,6.23-3.34,1.55-7.51,2.32-12.51,2.32Z"/>
                    <path className="cls-9"
                          d="m443.9,244.62c-1.55,0-2.83-.51-3.85-1.53-1.02-1.02-1.53-2.3-1.53-3.85v-50.61c0-1.62.51-2.92,1.53-3.91,1.02-.98,2.3-1.48,3.85-1.48s2.92.49,3.9,1.48c.99.99,1.48,2.29,1.48,3.91v50.61c0,1.55-.49,2.83-1.48,3.85-.99,1.02-2.29,1.53-3.9,1.53Z"/>
                    <path className="cls-9"
                          d="m491.28,245.05c-4.93,0-9.48-.76-13.67-2.27-4.19-1.51-7.44-3.43-9.76-5.75-1.06-1.12-1.5-2.41-1.32-3.85.17-1.44.86-2.62,2.06-3.54,1.41-1.12,2.8-1.6,4.17-1.42,1.37.18,2.55.79,3.54,1.85,1.2,1.34,3.11,2.59,5.75,3.75,2.64,1.16,5.57,1.74,8.81,1.74,4.08,0,7.19-.67,9.34-2,2.14-1.34,3.25-3.06,3.32-5.17.07-2.11-.93-3.94-3.01-5.49-2.08-1.55-5.86-2.81-11.34-3.8-7.11-1.41-12.26-3.52-15.46-6.33-3.2-2.81-4.8-6.26-4.8-10.34,0-3.59,1.05-6.56,3.17-8.92,2.11-2.36,4.82-4.12,8.13-5.28,3.31-1.16,6.75-1.74,10.34-1.74,4.64,0,8.76.74,12.35,2.22,3.59,1.48,6.44,3.52,8.55,6.12.98,1.12,1.44,2.3,1.37,3.54-.07,1.23-.67,2.27-1.79,3.11-1.13.78-2.46,1.02-4.01.74-1.55-.28-2.85-.91-3.9-1.9-1.76-1.69-3.66-2.85-5.7-3.48-2.04-.63-4.4-.95-7.07-.95-3.1,0-5.72.53-7.86,1.58-2.15,1.05-3.22,2.6-3.22,4.64,0,1.27.33,2.41,1,3.43.67,1.02,1.99,1.94,3.96,2.74,1.97.81,4.86,1.6,8.65,2.37,5.28,1.06,9.44,2.39,12.51,4.01,3.06,1.62,5.26,3.5,6.6,5.64,1.33,2.15,2,4.63,2,7.44,0,3.24-.86,6.16-2.59,8.76-1.72,2.6-4.26,4.68-7.6,6.23s-7.51,2.32-12.51,2.32Z"/>
                    <path className="cls-9"
                          d="m556.34,235.55c-.88-.99-1.95-1.48-3.22-1.48h-3.59c-2.25,0-4.15-.95-5.7-2.85-1.55-1.9-2.32-4.26-2.32-7.07v-26.07h8.97c1.48,0,2.67-.42,3.59-1.27.91-.85,1.37-1.9,1.37-3.17,0-1.41-.46-2.53-1.37-3.38-.91-.84-2.11-1.27-3.59-1.27h-8.97v-15.2c0-1.54-.49-2.81-1.48-3.8-.99-.99-2.25-1.48-3.8-1.48s-2.81.49-3.8,1.48c-.99.99-1.48,2.25-1.48,3.8v15.2h-4.86c-1.48,0-2.67.42-3.59,1.27-.91.85-1.37,1.97-1.37,3.38,0,1.27.46,2.32,1.37,3.17.91.84,2.11,1.27,3.59,1.27h4.86v26.07c0,3.87.81,7.35,2.43,10.45,1.62,3.1,3.83,5.54,6.65,7.33,2.81,1.79,5.98,2.69,9.5,2.69h1.9c1.83,0,3.33-.49,4.49-1.48,1.16-.99,1.74-2.25,1.74-3.8s-.44-2.81-1.32-3.8Z"/>
                    <path className="cls-9"
                          d="m622.24,218.19c.91-.88,1.37-1.99,1.37-3.33,0-5.56-1.04-10.46-3.11-14.72-2.08-4.25-5.08-7.6-9.02-10.02-3.94-2.43-8.65-3.64-14.14-3.64s-10.48,1.25-14.77,3.75c-4.29,2.5-7.65,5.95-10.08,10.34-2.43,4.4-3.64,9.48-3.64,15.25s1.28,10.75,3.85,15.14c2.57,4.4,6.12,7.85,10.66,10.34,4.54,2.5,9.73,3.75,15.57,3.75,3.23,0,6.63-.6,10.18-1.79,3.55-1.2,6.53-2.74,8.92-4.64,1.2-.91,1.76-2.01,1.69-3.27-.07-1.27-.7-2.42-1.9-3.48-.91-.77-1.99-1.14-3.22-1.11-1.23.03-2.37.44-3.43,1.21-1.41,1.06-3.25,1.99-5.54,2.8-2.29.81-4.52,1.21-6.7,1.21-4.01,0-7.56-.88-10.66-2.64-3.1-1.76-5.53-4.15-7.28-7.18-1.18-2.03-1.95-4.25-2.34-6.65h40.12c1.41,0,2.57-.44,3.48-1.32Zm-35.2-20c2.85-1.76,6.28-2.64,10.29-2.64,3.52,0,6.51.81,8.97,2.43,2.46,1.62,4.36,3.82,5.7,6.59.95,1.98,1.57,4.14,1.89,6.49h-35.13c.36-2.01.96-3.9,1.8-5.64,1.48-3.06,3.64-5.47,6.49-7.23Z"/>
                    <path className="cls-9"
                          d="m689.78,188.27c-.99-.98-2.29-1.48-3.9-1.48s-2.83.49-3.85,1.48c-1.02.99-1.53,2.29-1.53,3.9v29.76c0,2.53-.7,4.82-2.11,6.86-1.41,2.04-3.29,3.66-5.64,4.86-2.36,1.2-5.05,1.79-8.07,1.79s-6.02-.62-8.55-1.85c-2.53-1.23-4.54-3.13-6.01-5.7-1.48-2.57-2.22-5.82-2.22-9.76v-25.96c0-1.54-.51-2.83-1.53-3.85-1.02-1.02-2.31-1.53-3.85-1.53s-2.83.51-3.85,1.53c-1.02,1.02-1.53,2.31-1.53,3.85v25.96c0,5.84,1.11,10.76,3.33,14.78,2.21,4.01,5.24,7.05,9.08,9.13,3.83,2.08,8.18,3.11,13.03,3.11s8.92-1.02,12.61-3.06c2.01-1.11,3.78-2.44,5.33-3.99v1.14c0,1.55.51,2.83,1.53,3.85,1.02,1.02,2.31,1.53,3.85,1.53s2.92-.51,3.9-1.53c.99-1.02,1.48-2.3,1.48-3.85v-47.07c0-1.62-.49-2.92-1.48-3.9Z"/>
                    <path className="cls-9"
                          d="m748.94,188.32c-2.08-1.37-5.22-2.06-9.45-2.06s-8.09.99-11.61,2.96c-2.37,1.33-4.4,2.97-6.12,4.92v-1.97c0-1.76-.44-3.08-1.32-3.96-.88-.88-2.2-1.32-3.96-1.32s-2.99.46-3.9,1.37c-.91.92-1.37,2.22-1.37,3.9v47.18c0,1.76.46,3.08,1.37,3.96.91.88,2.22,1.32,3.9,1.32s3.08-.46,3.96-1.37c.88-.91,1.32-2.22,1.32-3.9v-30.82c0-3.23,1.04-5.91,3.11-8.02,2.08-2.11,4.91-3.55,8.5-4.33,3.59-.78,7.63-.7,12.14.21.91.21,1.76.25,2.53.11.77-.14,1.46-.47,2.06-1,.6-.53,1-1.28,1.21-2.27.49-1.9-.3-3.54-2.37-4.91Z"/>
                    <path className="cls-9"
                          d="m784.45,245.05c-4.93,0-9.48-.76-13.67-2.27-4.19-1.51-7.44-3.43-9.76-5.75-1.06-1.12-1.5-2.41-1.32-3.85.17-1.44.86-2.62,2.06-3.54,1.41-1.12,2.8-1.6,4.17-1.42,1.37.18,2.55.79,3.54,1.85,1.2,1.34,3.11,2.59,5.75,3.75,2.64,1.16,5.57,1.74,8.81,1.74,4.08,0,7.19-.67,9.34-2,2.14-1.34,3.25-3.06,3.32-5.17.07-2.11-.93-3.94-3.01-5.49s-5.86-2.81-11.34-3.8c-7.11-1.41-12.26-3.52-15.46-6.33-3.2-2.81-4.8-6.26-4.8-10.34,0-3.59,1.05-6.56,3.17-8.92,2.11-2.36,4.82-4.12,8.13-5.28,3.31-1.16,6.75-1.74,10.34-1.74,4.64,0,8.76.74,12.35,2.22,3.59,1.48,6.44,3.52,8.55,6.12.98,1.12,1.44,2.3,1.37,3.54-.07,1.23-.67,2.27-1.79,3.11-1.13.78-2.46,1.02-4.01.74-1.55-.28-2.85-.91-3.9-1.9-1.76-1.69-3.66-2.85-5.7-3.48-2.04-.63-4.4-.95-7.07-.95-3.1,0-5.72.53-7.86,1.58-2.15,1.05-3.22,2.6-3.22,4.64,0,1.27.33,2.41,1,3.43.67,1.02,1.99,1.94,3.96,2.74,1.97.81,4.86,1.6,8.65,2.37,5.28,1.06,9.44,2.39,12.51,4.01,3.06,1.62,5.26,3.5,6.6,5.64,1.33,2.15,2,4.63,2,7.44,0,3.24-.86,6.16-2.59,8.76-1.72,2.6-4.26,4.68-7.6,6.23-3.34,1.55-7.51,2.32-12.51,2.32Z"/>
                    <path className="cls-9"
                          d="m149.09,242.25c-2.72,0-5.18-.64-7.36-1.91-2.18-1.27-3.91-3.01-5.18-5.21-1.27-2.2-1.91-4.68-1.91-7.44s.58-5.23,1.75-7.41c1.17-2.18,2.77-3.91,4.79-5.18,2.02-1.27,4.31-1.91,6.86-1.91,2.06,0,3.96.43,5.71,1.28,1.75.86,3.21,2.02,4.4,3.48v-14.14c0-.8.25-1.45.76-1.94.51-.49,1.14-.73,1.91-.73s1.45.25,1.94.73c.49.49.73,1.13.73,1.94v23.88c0,2.76-.64,5.24-1.91,7.44-1.27,2.2-2.99,3.94-5.16,5.21-2.16,1.27-4.61,1.91-7.33,1.91Zm0-4.71c1.78,0,3.37-.43,4.76-1.28,1.4-.85,2.5-2.03,3.3-3.53.8-1.5,1.2-3.18,1.2-5.03s-.4-3.56-1.2-5.03c-.8-1.47-1.9-2.63-3.3-3.48-1.4-.85-2.98-1.28-4.76-1.28s-3.32.43-4.74,1.28c-1.41.86-2.53,2.02-3.35,3.48-.82,1.47-1.23,3.14-1.23,5.03s.41,3.53,1.23,5.03c.82,1.5,1.94,2.68,3.35,3.53,1.41.86,2.99,1.28,4.74,1.28Z"/>
                    <path className="cls-9"
                          d="m198.41,228.92c.45-.44.68-.99.68-1.65,0-2.76-.52-5.19-1.54-7.3-1.03-2.11-2.52-3.77-4.48-4.97-1.96-1.2-4.29-1.81-7.02-1.81s-5.2.62-7.33,1.86c-2.13,1.24-3.8,2.95-5,5.13-1.2,2.18-1.81,4.7-1.81,7.57s.64,5.33,1.91,7.51c1.27,2.18,3.04,3.89,5.29,5.13,2.25,1.24,4.83,1.86,7.72,1.86,1.6,0,3.29-.3,5.05-.89,1.76-.59,3.24-1.36,4.42-2.3.59-.45.87-1,.84-1.62-.04-.63-.35-1.2-.94-1.73-.45-.38-.99-.57-1.6-.55-.61.02-1.18.22-1.7.6-.7.52-1.62.99-2.75,1.39-1.13.4-2.24.6-3.32.6-1.99,0-3.75-.44-5.29-1.31-1.54-.87-2.74-2.06-3.61-3.56-.58-1-.97-2.11-1.16-3.3h19.91c.7,0,1.27-.22,1.73-.65Zm-17.46-9.92c1.41-.87,3.12-1.31,5.11-1.31,1.74,0,3.23.4,4.45,1.2,1.22.8,2.16,1.89,2.83,3.27.47.98.78,2.06.93,3.22h-17.42c.18-1,.46-1.94.88-2.8.73-1.52,1.81-2.71,3.22-3.59Z"/>
                    <path className="cls-9"
                          d="m216.79,242.25c-2.44,0-4.7-.37-6.78-1.13-2.08-.75-3.69-1.7-4.84-2.85-.52-.56-.74-1.19-.65-1.91.09-.71.43-1.3,1.02-1.75.7-.56,1.39-.79,2.07-.71.68.09,1.26.39,1.75.92.59.66,1.54,1.28,2.85,1.86,1.31.58,2.77.86,4.37.86,2.02,0,3.57-.33,4.63-1,1.06-.66,1.61-1.52,1.65-2.57.03-1.05-.46-1.95-1.49-2.72-1.03-.77-2.91-1.4-5.63-1.88-3.53-.7-6.08-1.75-7.67-3.14-1.59-1.4-2.38-3.11-2.38-5.13,0-1.78.52-3.26,1.57-4.42,1.05-1.17,2.39-2.04,4.03-2.62,1.64-.58,3.35-.86,5.13-.86,2.3,0,4.35.37,6.13,1.1,1.78.73,3.19,1.75,4.24,3.04.49.56.72,1.14.68,1.75-.04.61-.33,1.13-.89,1.54-.56.38-1.22.51-1.99.37-.77-.14-1.41-.45-1.94-.94-.87-.84-1.82-1.41-2.83-1.73-1.01-.31-2.18-.47-3.51-.47-1.54,0-2.84.26-3.9.79-1.07.52-1.6,1.29-1.6,2.3,0,.63.17,1.2.5,1.7.33.51.99.96,1.96,1.36.98.4,2.41.79,4.29,1.18,2.62.52,4.69,1.19,6.2,1.99,1.52.8,2.61,1.74,3.27,2.8.66,1.07,1,2.3,1,3.69,0,1.61-.43,3.05-1.28,4.35-.86,1.29-2.11,2.32-3.77,3.09-1.66.77-3.73,1.15-6.21,1.15Z"/>
                    <path className="cls-6"
                          d="m439.61,161.24c1.54-1.12,3.26-1.52,5.18-1.22,1.92.31,3.43,1.23,4.55,2.76,1.12,1.54,1.52,3.26,1.22,5.18-.3,1.92-1.22,3.43-2.76,4.55-1.54,1.12-3.26,1.52-5.18,1.22s-3.43-1.22-4.55-2.76c-1.12-1.54-1.52-3.26-1.22-5.18.3-1.92,1.22-3.43,2.76-4.55Z"/>
                    <path className="cls-1"
                          d="m219.58,77.56c-1.81-.59-3.15-1.74-4.03-3.47-.88-1.73-1.03-3.5-.44-5.31.59-1.81,1.75-3.15,3.47-4.03,1.73-.88,3.5-1.03,5.3-.44,1.81.59,3.15,1.75,4.03,3.47.88,1.73,1.03,3.5.44,5.3-.59,1.81-1.75,3.15-3.48,4.03-1.73.88-3.5,1.03-5.3.44Z"/>
                    <path className="cls-7"
                          d="m254.17,54.94c-1.54-1.12-2.46-2.63-2.76-4.55-.3-1.92.1-3.65,1.22-5.18,1.12-1.54,2.63-2.46,4.55-2.76,1.92-.3,3.64.1,5.18,1.22,1.54,1.12,2.46,2.63,2.76,4.55.3,1.92-.1,3.64-1.22,5.18-1.12,1.54-2.63,2.46-4.55,2.76-1.92.3-3.64-.1-5.18-1.22Z"/>
                    <path className="cls-8"
                          d="m294.06,44.12c-1.12-1.54-1.52-3.26-1.22-5.18.31-1.92,1.23-3.43,2.76-4.55,1.54-1.12,3.26-1.52,5.18-1.22,1.92.3,3.43,1.22,4.55,2.76,1.12,1.54,1.52,3.26,1.22,5.18-.3,1.92-1.22,3.43-2.76,4.55-1.54,1.12-3.26,1.52-5.18,1.22-1.92-.3-3.43-1.22-4.55-2.76Z"/>
                    <path className="cls-5"
                          d="m335.34,46.16c-.59-1.81-.44-3.57.44-5.3.88-1.73,2.23-2.89,4.03-3.48,1.81-.59,3.57-.44,5.3.44s2.89,2.22,3.47,4.03c.59,1.81.44,3.57-.44,5.3-.88,1.73-2.22,2.89-4.03,3.47-1.81.59-3.57.44-5.31-.44-1.73-.88-2.88-2.22-3.47-4.03Z"/>
                    <path className="cls-2"
                          d="m373.97,60.85c0-1.9.68-3.54,2.06-4.91,1.37-1.37,3.01-2.06,4.91-2.06,1.9,0,3.54.69,4.91,2.06,1.37,1.37,2.06,3.01,2.06,4.91,0,1.9-.69,3.54-2.06,4.91-1.37,1.37-3.01,2.06-4.91,2.06-1.9,0-3.54-.69-4.91-2.06-1.37-1.37-2.06-3.01-2.06-4.91Z"/>
                    <path className="cls-4"
                          d="m406.17,86.76c.59-1.81,1.74-3.15,3.47-4.03,1.73-.88,3.5-1.03,5.31-.44,1.81.59,3.15,1.75,4.03,3.47.88,1.73,1.03,3.5.44,5.3-.59,1.81-1.75,3.15-3.47,4.03-1.73.88-3.5,1.03-5.3.44-1.81-.59-3.15-1.75-4.03-3.48-.88-1.73-1.03-3.5-.44-5.3Z"/>
                    <path className="cls-9"
                          d="m193.67,109.76c-1.9,0-3.54-.68-4.91-2.06-1.37-1.37-2.06-3.01-2.06-4.91s.69-3.54,2.06-4.91c1.37-1.37,3.01-2.06,4.91-2.06s3.54.69,4.91,2.06,2.06,3.01,2.06,4.91-.69,3.54-2.06,4.91c-1.37,1.37-3.01,2.06-4.91,2.06Z"/>
                    <path className="cls-6"
                          d="m193.67,176.88c-1.55,0-2.83-.51-3.85-1.53-1.02-1.02-1.53-2.3-1.53-3.85v-46.96c0-1.62.51-2.92,1.53-3.91,1.02-.98,2.3-1.48,3.85-1.48s2.92.49,3.9,1.48c.99.99,1.48,2.29,1.48,3.91v46.96c0,1.55-.49,2.83-1.48,3.85-.99,1.02-2.29,1.53-3.9,1.53Z"/>
                </svg>
            ) : (
                ""
            )}
        </svg>
    );
};


interface LogoProps {
    appearance: "complet" | "simple";
    withLink?: boolean;
    className?: string;
}

const Logo = styled(({appearance, withLink, className}: LogoProps) =>
    withLink ? (
        <a
            className={className}
            href="/"
            title="Aller page d’accueil du site"
            rel="home"
        >
            <LogoSvg appearance={appearance}/>
        </a>
    ) : (
        <span className={className}>
      <LogoSvg appearance={appearance}/>
    </span>
    )
)`
    display: block;

    & svg {
        height: 100%;
        width: auto;
    }
`;

export default Logo;

