import React from "react";
import {
  List,
  Datagrid,
  TextField,
  SimpleList,
  ArrayField,
  SingleFieldList,
  ChipField
} from "react-admin";
import { useMediaQuery } from "@material-ui/core";

export const ListMembers = props => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down("sm"));

  return (
    <List {...props}>
      {isSmall ? (
        <SimpleList
          primaryText={record => record.name}
          secondaryText={record =>
            new Date(record.created_at).toLocaleDateString()
          }
        />
      ) : (
        <Datagrid rowClick="edit">
          <TextField source="first_name" label={"Prénom"} />
          <TextField source="last_name" label={"Nom"} />
          <TextField source="role" />
          <ArrayField source="member_categories" label={"Catégories"}>
            <SingleFieldList>
              <ChipField source="id" />
            </SingleFieldList>
          </ArrayField>
        </Datagrid>
      )}
    </List>
  );
};
