const initialState = { payload: undefined };

const pageReducer = (state = initialState, action) => {
  switch (action.type) {
    case "PAGE_CONTENT":
      return {
        payload: {
          ...action.payload
        }
      };
    default:
      return state;
  }
};

export default pageReducer;
