const formatStrapiResponse = (resource, json) => {
  switch (resource) {
    case "companies":
      if (json.logo)
        return {
          ...json,
          logo: {
            ...json.logo,
            url: process.env.REACT_APP_API_URL + json.logo.url,
          },
        };
      break;
    case "members":
      if (json.photo)
        return {
          ...json,
          photo: {
            ...json.photo,
            url: process.env.REACT_APP_API_URL + json.photo.url,
          },
        };
      break;
    default:
      return json;
  }
};

export default formatStrapiResponse;
