import React from "react";
import { Typography, Link } from "@material-ui/core";
import styled from "styled-components";
import { domainFromUrl } from "../../helpers/domainFromUrl";
import Modal from "../Modal";
import VCard from "../VCard";

const getCompanyStreetAddress = (company: any) => {
  if (company.address) {
    let streetAddress = "";
    if (company.address.street) {
      streetAddress += company.address.street;
    }
    if (company.address.street2) {
      streetAddress += "\n" + company.address.street2;
    }
    return streetAddress;
  } else {
    return "";
  }
};

interface CompanyItemModalProps {
  company: any;
  className?: string;
}

const CompanyItemModal = styled(
  ({ company, className }: CompanyItemModalProps) => {
    const logoComp = company.logo && company.logo.url;
    return (
      <div className={className}>
        <VCard
          imagePath={process.env.REACT_APP_MEDIA_URL + logoComp}
          url={"http://" + company.url}
          organization={company.name}
          tel={company.tel}
          streetAddress={getCompanyStreetAddress(company)}
          postalCode={company.address && company.address.postalCode}
          locality={company.address && company.address.locality}
        />
      </div>
    );
  }
)`
  & .adr span {
    display: inline-block;
    margin-right: 4px;
  }
  & img {
    width: 200px;
    height: 200px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
`;

interface CompanyItemProps {
  company: any;
  className?: string;
}

const CompanyItem = styled(({ company, className }: CompanyItemProps) => (
  <div className={className}>
    <Modal
      title={company.name}
      content={<CompanyItemModal company={company} />}
    >
      {company.logo && (
        <img
          src={process.env.REACT_APP_MEDIA_URL + company.logo.url}
          alt={company.name}
        />
      )}
    </Modal>
    <Typography variant="h5" component="h4" align="center">
      {company.name}
    </Typography>
    {company.url && (
      <Typography variant="body1" component="p" align="center">
        <Link href={"http://" + company.url}>{domainFromUrl(company.url)}</Link>
      </Typography>
    )}
  </div>
))`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 8px;
  & img {
    display: inline-block;
    height: 100px;
    width: 100px;
    border-radius: 50%;
  }
`;

export default CompanyItem;
