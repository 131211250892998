const getAllDossier = {
  type: "getList",
  resource: "findAll",
  payload: {
    filter: { _a: "" },
    sort: { field: "id", order: "DESC" },
    pagination: { page: 1, perPage: 50 },
  },
};
export default getAllDossier;
