import { fetchUtils } from "react-admin";
import simpleRestProvider from "./ra-strapi-provider";
import Cookies from "./helpers/Cookies/Cookies";

interface Options {
  headers?: Headers;
}
const httpClient = (url, options: Options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({
      Accept: "application/json",
    });
  }
  const token = Cookies.getCookie("token");
  options.headers.set("Authorization", `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

export const dataProvider = simpleRestProvider(
  process.env.REACT_APP_API_URL,
  httpClient
);
