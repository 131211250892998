import React from "react";
import { Grid, isWidthUp, Typography, withWidth } from "@material-ui/core";
import Background from "../../../components/Background/";
import SectionCard from "../../../components/SectionCard";
import Section from "../../../components/Section";
import styled, { withTheme } from "styled-components";
import ImageBackground from "../../../components/Background/ImageBackground";

interface SvgCircleProps {
  color: string;
  opacity: number;
}

const SvgCircle = ({ color, opacity }: SvgCircleProps) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 100 100"
  >
    <circle cx="50" cy="50" r="50" fill={color} style={{ opacity: opacity }} />
  </svg>
);

const CirclesBackground = styled(
  withTheme(({ theme, className }) => (
    <Background className={className}>
      <SvgCircle color={theme.colors.blue1Darker} opacity={0.8} />
      <SvgCircle color={theme.colors.blue2} opacity={0.6} />
    </Background>
  ))
)`
  & svg {
    position: absolute;
    transform-origin: top left;
    display: none;
  }
  @media (min-width: 600px) {
    & svg {
      display: block;
    }
    & svg:nth-child(1) {
      transform: translate(-27%, -30%) scale(0.7);
    }
    & svg:nth-child(2) {
      transform: translate(-15%, 25%) scale(0.5);
    }
  }
  @media (min-width: 960px) {
    & svg:nth-child(1) {
      transform: translate(-20%, -25%) scale(0.7);
    }
    & svg:nth-child(2) {
      transform: translate(-15%, 25%) scale(0.5);
    }
  }
`;

const Title = styled(Typography)`
  position: absolute;
  color: white;
  top: 205px;
  width: 600px;
  left: 30px;
  font-size: 75px;
`;

const Welcome = withWidth()(styled(({ className, content }) => (
  <Section
    id="welcome"
    className={className}
    backgrounds={
      <>
        <ImageBackground imagePath="/snsa-welcome-v2.jpg" />
        <CirclesBackground />
      </>
    }
  >
    <Title variant="h1" component="h1">
      {content.welcome_title}
    </Title>
  </Section>
))`
  ${(props) => {
    if (!isWidthUp("md", props.width)) {
      return `padding-top: 20px !important;`;
    }
  }}
  & h1 {
    padding-bottom: 10px;
  }
`);

export default Welcome;
