import { Box, Grid, Typography } from "@material-ui/core";
import React from "react";
import Button from "@material-ui/core/Button";

export const Error = ({ message }) => {
  return (
    <Grid container justify={"center"} alignContent={"center"}>
      <Box m={3} />
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <Typography color={"error"}>
          {message
            ? message
            : "Erreur pendant la récupération des données, veuillez recharger la page"}
        </Typography>
        <Box m={1} />
        <Button
          variant={"contained"}
          color={"secondary"}
          onClick={() => window.location.reload()}
        >
          Recharger la page
        </Button>
      </Grid>
    </Grid>
  );
};
