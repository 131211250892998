const initialState = { payload: undefined };

const singleArticleReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SINGLE_ARTICLE_CONTENT":
      return {
        payload: {
          ...action.payload
        }
      };
    default:
      return state;
  }
};

export default singleArticleReducer;
