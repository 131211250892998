import Background, { BackgroundProps } from "./";
import styled from "styled-components";

interface ColorBackgroundProps extends BackgroundProps {
  color: string;
}

const ColorBackground = styled(Background)<ColorBackgroundProps>`
  background: ${props => props.color};
`;

export default ColorBackground;
