import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import {usePermissions} from "react-admin";
import MyAccount from "../MyAccount";
import {ROLE} from "../../domain/role";
import {useSelector} from "react-redux";
import {getUser} from "../../../store/selectors/user.selector";
import {COMPANY_TITLE} from "../../../config/config";

const Dashboard = () => {
    const {permissions} = usePermissions();
    const user = useSelector(getUser);

    if (!user.account) return null;
    const titleEspDoc = `Bienvenue sur l'espace documentaire de l'${COMPANY_TITLE}`
    return (
        <Card style={{padding: "20px"}}>
            {permissions && permissions.role === ROLE.admin ? (
                <CardHeader title="Bienvenue sur votre administration"/>
            ) : (
                <CardHeader title={titleEspDoc}/>
            )}
            <CardContent>
                <MyAccount user={user}/>
            </CardContent>
        </Card>
    );
};

export default Dashboard;
