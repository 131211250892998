import React from "react";
import Slider from "react-slick";
import CompanyItem from "./CompanyItem";
import styled from "styled-components";
import alphabetic from "../../helpers/alphabetic";

interface CompaniesCarouselProps {
  className?: string;
  companies: any;
}

const CompaniesCarousel = styled(
  ({ className, companies }: CompaniesCarouselProps) => {
    if (!companies) return null;
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 400,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };
    alphabetic(companies, "name");

    return (
      <Slider {...settings} className={className}>
        {companies.map((company, key) => (
          <CompanyItem company={company} key={key} />
        ))}
      </Slider>
    );
  }
)`
  margin-bottom: 40px;
  & .slick-arrow {
    width: 40px;
    height: 40px;
    &::before {
      font-size: 40px;
      opacity: 0.5;
      color: ${(props) => props.theme.colors.grey};
    }
  }
  & .slick-next {
    right: -35px;
  }
  & .slick-prev {
    left: -35px;
  }
`;

export default CompaniesCarousel;
