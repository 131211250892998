import React from "react";
import { useTheme, useMediaQuery } from "@material-ui/core";
import styled, { withTheme } from "styled-components";
import Points from "./Points";

const Historic = styled(
  withTheme(({ className, theme, periods }) => {
    const muiTheme = useTheme();
    const isDesktop = useMediaQuery(muiTheme.breakpoints.up("md"));
    return (
      <div className={className}>
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox={isDesktop ? "0 0 800 500" : "0 0 449.5 901"}
          style={{ overflow: "visible" }}
        >
          <g id="timeline">
            <path
              style={{
                fill: "none",
                stroke: theme.colors.greenDarker,
                strokeWidth: 5,
                strokeMiterlimit: 10
              }}
              d={
                isDesktop
                  ? "M732.6,450.5H50.5c-27.6,0-50-22.4-50-50s22.4-50,50-50h699c27.6,0,50-22.4,50-50s-22.4-50-50-50h-699c-27.6,0-50-22.4-50-50s22.4-50,50-50h699c27.6,0,50-22.4,50-50s-22.4-50-50-50h-700"
                  : "M49,50.5h350.5c27.6,0,50,22.4,50,50s-22.4,50-50,50H50c-27.6,0-50,22.4-50,50s22.4,50,50,50h349.5c27.6,0,50,22.4,50,50s-22.4,50-50,50H50c-27.6,0-50,22.4-50,50s22.4,50,50,50h349.5c27.6,0,50,22.4,50,50s-22.4,50-50,50H50c-27.6,0-50,22.4-50,50s22.4,50,50,50h349.5c27.6,0,50,22.4,50,50s-22.4,50-50,50H50c-27.6,0-50,22.4-50,50s22.4,50,50,50h332.6"
              }
            />
            <polygon
              style={{
                fill: theme.colors.greenDarker
              }}
              points={
                isDesktop
                  ? "728.9,438 750.5,450.5 728.9,463"
                  : "378.9,863 400.5,850.5 378.9,838"
              }
            />
          </g>
          <Points periods={periods} />
        </svg>
      </div>
    );
  })
)`
  padding: 10px;
`;

export default Historic;
