import React from "react";
import { Typography, Grid, Button } from "@material-ui/core";
import { ThemeStyle } from "@material-ui/core/styles/createTypography";
import { evalShortcodes } from "../../helpers/evalShortCode";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";

type Style = ThemeStyle | "srOnly";

type TextPart = string | JSX.Element;

interface ContentType {
  content: {
    title?: string;
    text?: TextPart | TextPart[];
    imgs?: string[];
    shortcodes?: any;
  };
  seeMoreBtn?: {
    url: (page: string) => void | string;
    text: string;
  };
}

interface ContentStyle {
  center?: boolean;
  titleStyle?: {
    variant: Style | "inherit";
    component: React.ElementType<React.HTMLAttributes<HTMLElement>>;
  };
  titleMargin?: string;
  contentMargin?: string;
}

interface SectionContentProps extends ContentStyle, ContentType {}

const MarginTitle = styled(Typography)<ContentStyle>`
  margin: ${props => props.titleMargin};
`;

const MarginContent = styled(Typography)<ContentStyle>`
  margin: ${props => props.contentMargin};
`;

const SectionContent = ({
  titleStyle = { variant: "h2", component: "h2" },
  content,
  contentMargin = "inherit",
  center,
  seeMoreBtn,
  titleMargin = "0 0 20px 0"
}: SectionContentProps) => {
  return (
    <>
      {content.title && (
        <MarginTitle
          variant={titleStyle.variant}
          component={titleStyle.component}
          align={center ? "center" : "left"}
          style={{ display: seeMoreBtn ? "inline-block" : "bock" }}
          titleMargin={titleMargin}
        >
          {content.title}
        </MarginTitle>
      )}
      {seeMoreBtn && (
        <Button
          onClick={() => seeMoreBtn.url("allArticles")}
          variant={"outlined"}
          style={{ float: "right" }}
        >
          {seeMoreBtn.text}
        </Button>
      )}
      {content.text && (
        <MarginContent
          contentMargin={contentMargin}
          align={center ? "center" : "left"}
        >
          <ReactMarkdown source={content.text} />
          {content.shortcodes &&
            content.shortcodes[0] &&
            evalShortcodes(content.shortcodes[0].name)}
        </MarginContent>
      )}
      <Grid container spacing={1}>
        {content.imgs &&
          content.imgs.map((img: string, index: number) => (
            <Grid item sm={4} key={index}>
              <img key={img} src={img} alt={content.title} width={"100%"} />
            </Grid>
          ))}
      </Grid>
    </>
  );
};

export default SectionContent;
