import React from "react";
import {
  Edit,
  ImageField,
  ImageInput,
  SimpleForm,
  TextInput,
} from "react-admin";

export const EditCompanies = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <TextInput source="url" />
        <TextInput source="tel" />
        <ImageInput source="logo" label="Logo de l'entreprise" accept="image/*">
          <ImageField source={"url"} title={"name"} />
        </ImageInput>
      </SimpleForm>
    </Edit>
  );
};
