import React from "react";
import { Grid, Typography } from "@material-ui/core";

const Disclaimer = () => {
  return (
    <Grid container alignItems={"center"} justify={"center"}>
      <Grid item>
        <Typography
          variant={"caption"}
          color={"secondary"}
          component={"p"}
          align={"center"}
        >
          Avant de pouvoir naviguer, veuillez d'abord compléter votre profil
        </Typography>
      </Grid>
    </Grid>
  );
};
export default Disclaimer;
