import Background, { BackgroundProps } from "./";
import styled from "styled-components";

interface ImageBackgroundProps extends BackgroundProps {
  imagePath: string;
  fixed?: boolean;
}

const ImageBackground = styled(Background)<ImageBackgroundProps>`
  background-image: url(${props => props.imagePath});
  background-size: cover;
  ${props => (props.fixed ? "background-attachment: fixed;" : "")}
`;

export default ImageBackground;
