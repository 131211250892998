const testError = (setError, e, form, setForm) => {
  const name = e.target.name;
  const value = e.target.value;

  if (name === "editPass") {
    if (value === false) {
      setError(null);
      delete form.password;
      delete form.confirm;
      return setForm({ ...form });
    }
  }

  if (name === "password" && form.confirm !== value)
    return setError("Les mots de passe ne correspondent pas");
  if (name === "confirm" && form.password !== value)
    return setError("Les mots de passe ne correspondent pas");
  if (form.confirm === value || form.password === value) return setError(null);
};
export default testError;
