import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide, { SlideProps } from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import { makeStyles, Typography } from "@material-ui/core";

interface ModalProps {
  title?: string;
  text?: string;
  content?: JSX.Element;
  children: React.ReactNode;
  linkStyle?: boolean;
}
const useStyles = makeStyles({
  hoverStyle: {
    "&:hover": {
      cursor: "pointer",
    },
  },
});
const Transition = React.forwardRef<unknown, TransitionProps>(
  function Transition(props: SlideProps, ref: any) {
    return <Slide direction="up" ref={ref} {...props} />;
  }
);

const Modal = ({
  title,
  text,
  content,
  children,
  linkStyle = false,
}: ModalProps) => {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div>
      {linkStyle ? (
        <Typography
          className={classes.hoverStyle}
          variant={"body1"}
          component={"p"}
          onClick={handleClickOpen}
        >
          {children}
        </Typography>
      ) : (
        <Button variant="text" onClick={handleClickOpen}>
          {children}
        </Button>
      )}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby={title ? "alert-dialog-slide-title" : null}
        aria-describedby={
          text || content ? "alert-dialog-slide-description" : null
        }
      >
        {title && (
          <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
        )}
        <DialogContent>
          {text && (
            <DialogContentText id="alert-dialog-slide-description">
              {text}
            </DialogContentText>
          )}
          {content}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Modal;
