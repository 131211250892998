import React, { useState } from "react";
import { Container } from "@material-ui/core";
import ArticleHeader from "../../components/ArticleHeader";
import styled from "styled-components";
import BackButton from "../../components/BackButton";
import SectionCard from "../../components/SectionCard";
import SectionContent from "../../components/SectionContent";
import { Line } from "../../Home/sections/News";
import { connect } from "react-redux";
import { getArticles } from "../../REST/getContents";

export const ArticleDiv = styled.div`
  padding: 30px 0 30px 0;
  background-color: #e6e5e5;
`;

const Title = styled(({ className }) => (
  <div className={className}>
    <SectionCard opacity={0.75} roundedCorners={[true, true, false, false]}>
      <SectionContent
        content={{ title: "Dernières actualités" }}
        titleStyle={{ variant: "h5", component: "h2" }}
        titleMargin="0"
      />
    </SectionCard>
  </div>
))`
  margin-bottom: 16px !important;
`;

const mapStateToProps = state => ({ posts: state.articlesReducer.payload });
const withStore = connect(mapStateToProps);

const AllArticles = ({ posts }) => {
  const [loading, setLoading] = useState(false);
  React.useEffect(() => {
    setLoading(true);
    if (posts.length === 0) {
      getArticles();
    }
    setLoading(false);
    return () => {
      // clean up
    };
  }, []);

  if (loading) return "Chargement...";
  if (!posts) return "Chargement...";
  return (
    <>
      <ArticleHeader img={"snsa-welcome.jpg"} title={"Tous les Articles"} />
      <ArticleDiv>
        <Container>
          <Title />
          {posts.map((post, index) => (
            <Line
              postsLength={posts.length}
              post={post}
              index={index}
              key={index}
            />
          ))}
          <BackButton />
        </Container>
      </ArticleDiv>
    </>
  );
};

export default withStore(AllArticles);
