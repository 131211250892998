import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ArrayField,
  SingleFieldList,
  ChipField,
} from "react-admin";

const DossierList = (props) => (
  <List perPage={100} {...props}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <ArrayField source="documents">
        <SingleFieldList>
          <ChipField source="title" />
        </SingleFieldList>
      </ArrayField>
      <TextField source="parent.name" label={"Dossier Parent"} />

      <ArrayField source="children" label={"Dossiers Enfants"}>
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField source="user_groups">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
    </Datagrid>
  </List>
);

export default DossierList;
