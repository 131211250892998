import ListItem from "@material-ui/core/ListItem";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import React, { useCallback, useState } from "react";
import { NavLink } from "react-router-dom";
import { ListItemSecondaryAction } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { useDataProvider } from "ra-core";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
  root: {
    padding: "0 !important",
    "&:hover": {
      background: "rgba(0, 0, 0, 0.08)",
    },
  },
  textStyle: {
    paddingTop: "8px",
    paddingBottom: "8px",
    fontSize: "1.2em",
  },
});

const ToggleLink = ({ resource }) => {
  const [open, setOpen] = useState(false);
  const [dossiers, setDossier] = useState([]);
  const dataProvider = useDataProvider();
  const classes = useStyles();

  const handleClick = useCallback(() => {
    if (!open) {
      dataProvider
        .getList("findOneBySlug", {
          filter: {
            path: resource.slug,
          },
          sort: { field: "id", order: "DESC" },
          pagination: { page: 1, perPage: 20 },
        })
        .then((res) => {
          const children = res.data[0] && res.data[0].children;
          if (children) {
            const newState = children.map((child) => ({
              ...child,
              slug: resource.slug + "/" + child.slug,
            }));
            setDossier(newState);
          }
        })

        .catch((err) => console.error(err));
    }
    if (open) {
      setDossier([]);
    }
    setOpen(!open);
  }, [dossiers]);

  return (
    <div style={{ paddingLeft: "1em" }}>
      <ListItem href={"/dossier/" + resource.slug} className={classes.root}>
        <NavLink
          key={resource.name}
          to={"/dossier/" + resource.slug}
          style={{ textDecoration: "none", paddingLeft: "1em", width: "100%" }}
        >
          {" "}
          <Typography
            color={"textSecondary"}
            variant={"body2"}
            className={classes.textStyle}
          >
            {(resource.options && resource.options.label) || resource.name}
          </Typography>
        </NavLink>
        <ListItemSecondaryAction button onClick={handleClick}>
          <IconButton edge="end" aria-label="expend">
            {open ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      {dossiers.map((dossier, i) => (
        <ToggleLink key={i} resource={dossier} />
      ))}
    </div>
  );
};
export default React.memo(ToggleLink);
