import { UserState } from "../reducers/user.reducer";

export const setUser = (user: UserState) => ({
  type: "SET_USER",
  payload: user,
});
export const isNotFirstTime = (lastlogin) => ({
  type: "FIRST_TIME",
  payload: { lastLogin: lastlogin, isFirstTime: false },
});
