import axios from "axios";
import Cookies from "../helpers/Cookies/Cookies";

const loggedAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 10000,
  headers: {
    Accept: "application/json",
    Authorization: `Bearer ${Cookies.getCookie("token")}`,
  },
});

const getUser = async (id) => {
  try {
    if (!id) return;
    const user = await loggedAxios.get("/users/" + id);
    console.log(user);
    return user;
  } catch (e) {
    throw e;
  }
};

export default getUser;
