import React from "react";
import {
  List,
  Datagrid,
  TextField,
  SimpleList,
  BooleanField,
  DateField,
} from "react-admin";
import { useMediaQuery } from "@material-ui/core";

export const ListPosts = (props) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <List {...props}>
      {isSmall ? (
        <SimpleList
          primaryText={(record) => record.name}
          secondaryText={(record) =>
            new Date(record.created_at).toLocaleDateString()
          }
        />
      ) : (
        <Datagrid rowClick="edit">
          <TextField source="title" label={"Titre"} />
          <BooleanField source="enabled" label={"En ligne ?"} />
          <DateField source="created_at" label={"Date de Création"} />
        </Datagrid>
      )}
    </List>
  );
};
