import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import React from "react";
import { ToggleButton } from "./ToggleButton";
import ToggleLink from "./ToggleLink";
import { MenuItemLink } from "react-admin";
import AucunDossier from "../../components/Disclaimer/AucunDossier";

export const Links = React.memo(({ resources, title }) => {
  const [openLink, setOpenLink] = React.useState(false);
  if (resources.length === 0 || !resources) return <AucunDossier />;
  return (
    <>
      <ToggleButton
        setOpenLink={setOpenLink}
        openLink={openLink}
        title={title}
      />
      <Collapse in={openLink} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {resources.map((resource, i) => {
            if (resource.hasList)
              return (
                <MenuItemLink
                  key={i}
                  style={{ paddingLeft: "2em" }}
                  to={`/${
                    (resource.slug && "dossier/" + resource.slug) ||
                    resource.name
                  }`}
                  primaryText={
                    (resource.options && resource.options.label) ||
                    resource.name
                  }
                />
              );

            if (resource.id) return <ToggleLink key={i} resource={resource} />;
          })}
        </List>
      </Collapse>
    </>
  );
});
