import React from "react";
import {
  List,
  Datagrid,
  TextField,
  SimpleList,
  NumberField
} from "react-admin";
import { useMediaQuery } from "@material-ui/core";

export const ListPeriods = props => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down("sm"));

  return (
    <List {...props}>
      {isSmall ? (
        <SimpleList
          primaryText={record => record.name}
          secondaryText={record =>
            new Date(record.created_at).toLocaleDateString()
          }
        />
      ) : (
        <Datagrid rowClick="edit">
          <NumberField source="year" />
          <TextField source="title" />
          <TextField source="text" />
        </Datagrid>
      )}
    </List>
  );
};
