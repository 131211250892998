import React from "react";
import styled from "styled-components";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@material-ui/core";

interface SectionCardConfig {
  opacity?: number;
  light?: boolean;
  children: JSX.Element[] | JSX.Element;
  position?: number;
  handleChangeIndex?: (index: number) => void;
  activeSwipe?: boolean;
  roundedCorners?: boolean[];
}

const SectionBg = styled("div")<SectionCardConfig>`
  position: relative;
  color: ${props => (props.light ? "white" : "black")};
  background-color: rgba(
    255,
    255,
    255,
    ${props => (props.opacity ? props.opacity : 1)}
  );
  padding: 20px;
  height: 100%;
  border-radius: ${props =>
    props.roundedCorners
      ? props.roundedCorners.map((isCornerRounded: boolean) =>
          isCornerRounded ? "20px " : "0px "
        )
      : "0"};
`;

const SectionCard = ({
  opacity,
  light,
  children,
  position,
  handleChangeIndex,
  activeSwipe,
  roundedCorners
}: SectionCardConfig) => {
  const muiTheme = useTheme();
  return (
    <SectionBg opacity={opacity} light={light} roundedCorners={roundedCorners}>
      {activeSwipe ? (
        <SwipeableViews
          axis={muiTheme.direction === "rtl" ? "x-reverse" : "x"}
          index={position}
          onChangeIndex={handleChangeIndex}
          enableMouseEvents={activeSwipe}
        >
          {children}
        </SwipeableViews>
      ) : (
        children
      )}
    </SectionBg>
  );
};

export default SectionCard;
