import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  FileInput,
  FileField,
} from "react-admin";
import { getParams } from "../../../../helpers/getParamsId";
import { Button, Typography } from "@material-ui/core";
import { useHistory } from "react-router";
import { redirect } from "../../../../helpers/redirect";

const GroupDocumentCreate = (props) => {
  const params = getParams(props);
  const { goBack } = useHistory();

  const PostTitle = () => {
    return (
      <Typography component={"h1"} variant={"h1"}>
        Ajouter un document
      </Typography>
    );
  };
  return (
    <>
      <Typography
        component={"h1"}
        variant={"h2"}
        align={"center"}
        style={{ padding: "30px" }}
      >
        Ajouter un document
      </Typography>

      <Button variant={"outlined"} onClick={() => goBack()}>
        Revenir
      </Button>

      <Create undoable={false} title={<PostTitle />} {...props}>
        <SimpleForm redirect={() => redirect(params)}>
          <TextInput source="title" label={"Titre"} />
          <TextInput source="text" label={"Description"} multiline fullWidth />
          <FileInput source="media" label="Ajouter document">
            <FileField source={"url"} title={"name"} />
          </FileInput>
          <TextInput
            style={{ display: "none" }}
            source="dossiers"
            label={"dossiers"}
            defaultValue={params.id}
            disabled
          />
        </SimpleForm>
      </Create>
    </>
  );
};

export default GroupDocumentCreate;
