const alphabetic = (array: Array, key: string) => {
  const compare = (a, b) => {
    let comparison = 0;
    if (a[key] > b[key]) {
      comparison = 1;
    } else if (a[key] < b[key]) {
      comparison = -1;
    }
    return comparison;
  };
  return array.sort(compare);
};

export default alphabetic;
