import React from "react";
import styled from "styled-components";
import { Typography } from "@material-ui/core";
import Icon from "@mdi/react";
import {
  mdiFolderOpen,
  mdiHexagonSlice6,
  mdiEarth,
  mdiHeadset,
  mdiAccountGroup
} from "@mdi/js";

interface StatIconProps {
  label: string;
  value: string;
  icon_name: string;
  className?: string;
}

const statIcon = {
  mdiFolderOpen,
  mdiHexagonSlice6,
  mdiEarth,
  mdiHeadset,
  mdiAccountGroup
};

const StatIcon = styled(
  ({ label, value, icon_name, className }: StatIconProps) => (
    <div className={className + " MuiTypography-body1"}>
      <Icon path={statIcon[icon_name]} title={label} />
      <Typography variant="h4" component="p">
        <b>{value}</b>
      </Typography>
      <Typography variant="body1" component="p">
        {label}
      </Typography>
    </div>
  )
)`
  padding: 0 10px;
  text-align: center;
  & svg {
    width: 60%;
    & path {
      fill: ${props => props.theme.colors.blue1};
    }
  }
`;

export default StatIcon;
