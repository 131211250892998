import * as React from "react";
import { MutableRefObject } from "react";

export type DirectionType = {
  x: string | null;
  y: string | null;
};

export type XY = {
  x: number;
  y: number;
};

export interface ScrollStart {
  position: XY;
  time: number;
}

export type ScrollInfo = {
  elapsedTime: number;
  direction: DirectionType;
  speed: XY;
  distance: XY;
  position: XY;
};

export type OptionsType = {
  onScrollStart?: Function;
  onScrollEnd?: Function;
  disableScrollListeningRef?: MutableRefObject<boolean>;
};

const SCROLL_END_DURATION = 100;

const isBrowser = typeof window !== "undefined";

function getPositionX() {
  return window.pageXOffset || 0;
}

function getPositionY() {
  return window.pageYOffset || 0;
}

function getDirectionX(x: number, frameValues: ScrollStart): string | null {
  if (x > frameValues.position.x) return "right";
  if (x < frameValues.position.x) return "left";
  return null;
}

function getDirectionY(y: number, start: ScrollStart): string | null {
  if (y > start.position.y) return "down";
  if (y < start.position.y) return "up";
  return null;
}

function getDistanceX(x: number, start: ScrollStart): number {
  return Math.abs(x - start.position.x);
}

function getDistanceY(y: number, start: ScrollStart): number {
  return Math.abs(y - start.position.y);
}

const getSpeed = (position: XY, elapsedTime: number, start: ScrollStart) => ({
  x:
    (Math.abs(start.position.x - position.x) / Math.max(1, elapsedTime)) * 1000,
  y:
    (Math.abs(start.position.y - position.y) / Math.max(1, elapsedTime)) * 1000,
});

export const useScrollEvent = (options: OptionsType = {}): void => {
  const scrolling = React.useRef<boolean>(false);
  const startValues = React.useRef<ScrollStart>(undefined);
  const frameValues = React.useRef<ScrollInfo>(undefined);
  const scrollTimeout = React.useRef<any>(null);
  const raf = React.useRef<any>(null);

  function frame(timestamp: number) {
    const position = {
      x: getPositionX(),
      y: getPositionY(),
    };

    if (!startValues.current)
      startValues.current = {
        position,
        time: timestamp,
      };

    const elapsedTime = timestamp - startValues.current.time;

    const direction = {
      x: getDirectionX(position.x, startValues.current),
      y: getDirectionY(position.y, startValues.current),
    };
    const distance = {
      x: getDistanceX(position.x, startValues.current),
      y: getDistanceY(position.y, startValues.current),
    };

    const speed = getSpeed(position, elapsedTime, startValues.current);

    frameValues.current = {
      elapsedTime,
      direction,
      speed,
      distance,
      position,
    };

    raf.current = requestAnimationFrame(frame);
  }

  function clearAndSetscrollTimeout() {
    if (scrollTimeout.current) clearTimeout(scrollTimeout.current);
    scrollTimeout.current = setTimeout(scrollEnd, SCROLL_END_DURATION);
  }

  function onScroll(event) {
    if (!options.disableScrollListeningRef.current) {
      if (!scrolling.current) {
        scrollStart(event);
      }

      clearAndSetscrollTimeout();
    }
  }

  function scrollStart(event) {
    scrolling.current = true;
    raf.current = requestAnimationFrame(frame);

    if (typeof options.onScrollStart === "function") {
      options.onScrollStart.bind(undefined, event)();
    }
  }

  function scrollEnd() {
    if (typeof options.onScrollEnd === "function") {
      options.onScrollEnd.bind(null, frameValues.current)();
    }

    startValues.current = undefined;
    frameValues.current = undefined;
    scrolling.current = false;

    cancelAnimationFrame(raf.current);
  }

  React.useEffect(() => {
    if (!isBrowser) return;

    window.addEventListener("scroll", onScroll, true);

    return () => {
      clearTimeout(scrollTimeout.current);
      window.removeEventListener("scroll", onScroll, true);
    };
  }, []);
};
