import React from "react";
import SectionCard from "../../../../components/SectionCard";
import {
  Typography,
  Button,
  WithWidth,
  isWidthUp,
  withWidth
} from "@material-ui/core";
import { NavProps } from "../../../index";
import { Link } from "react-router-dom";

interface MiniCardProps extends WithWidth {
  title: string;
  text: string;
  btnUrl?: string;
  page: string;
}

const MiniCard = ({ title, text, link, width }: MiniCardProps & NavProps) => {
  return (
    <SectionCard
      opacity={0.75}
      roundedCorners={
        isWidthUp("md", width)
          ? [false, false, true, true]
          : [true, true, true, true]
      }
    >
      <Typography variant={"h4"} component={"h4"} align={"center"}>
        {title}
      </Typography>
      <Typography variant={"body1"} component={"p"} align={"center"}>
        {text}
      </Typography>
      <div style={{ textAlign: "center", paddingTop: "10px" }}>
        <Link to={link}>
          <Button color={"primary"} variant={"outlined"}>
            Plus d'infos
          </Button>
        </Link>
      </div>
    </SectionCard>
  );
};

export default withWidth()(MiniCard);
