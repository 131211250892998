import SectionCard from "../../../components/SectionCard";
import SectionMenu from "../../../components/SectionMenu";
import React from "react";
import { Grid, isWidthUp, withWidth } from "@material-ui/core";
import styled from "styled-components";

const Menu = styled(props => {
  return (
    <Grid item sm={3} className={props.className}>
      <SectionCard
        opacity={0.8}
        roundedCorners={
          isWidthUp("md", props.width)
            ? [false, false, true, false]
            : [true, true, false, false]
        }
      >
        <SectionMenu
          title={props.menuAssistance.title}
          items={props.menuAssistance.links}
          handleClick={props.handleClick}
          direction="left"
        />
        <SectionMenu
          title={props.menuMetier.title}
          items={props.menuMetier.links}
          handleClick={props.handleClick}
          direction="left"
        />
      </SectionCard>
    </Grid>
  );
})`
  ${props => {
    if (!isWidthUp("md", props.width)) {
      return "flex:content; max-width: 100%;";
    }
  }}
`;

export default withWidth()(Menu);
