import React, { useState } from "react";
import { TextField, Button, Typography } from "@material-ui/core";
import styled from "styled-components";
import { sendMail } from "../../REST/sendMail";

const TextFieldColor = styled(TextField)`
  label {
    color: white;
  }
  ,
  fieldset {
    border-color: white;
  }
  ,
  input {
    color: white;
  }
  ,
  textarea {
    color: white;
  }
`;

const initialData = {
  nom: { value: "", error: false },
  prenom: { value: "", error: false },
  mail: { value: "", error: false },
  entreprise: { value: "", error: false },
  message: { value: "", error: false },
};

const ContactForm = () => {
  const [data, setData] = useState(initialData);
  const [feedback, setFeedback] = useState(null);
  const handleFormChange = (e: any, field: string) => {
    setData({
      ...data,
      [field]: { value: e.target.value, error: e.target.value === "" },
    });
  };
  const submit = async () => {
    const { nom, prenom, mail, entreprise, message } = data;
    const res = await sendMail({
      nom: nom.value,
      prenom: prenom.value,
      mail: mail.value,
      entreprise: entreprise.value,
      message: message.value,
    });
    if (res === true) {
      setFeedback("Merci pour votre message");
      setData(initialData);
    } else {
      setFeedback("Erreur dans l'envoi de votre message");
    }
    setTimeout(() => setFeedback(null), 5000);
  };

  return (
    <>
      <form>
        <TextFieldColor
          error={data.nom.error}
          fullWidth
          id="outlined-basic"
          label="Nom"
          margin="normal"
          value={data.nom.value}
          onChange={(e) => handleFormChange(e, "nom")}
          helperText={data.nom.error ? "Veuillez entrer votre nom" : ""}
          variant="outlined"
          required
        />
        <TextFieldColor
          error={data.prenom.error}
          fullWidth
          id="outlined-basic"
          label="Prenom"
          margin="normal"
          value={data.prenom.value}
          onChange={(e) => handleFormChange(e, "prenom")}
          helperText={data.prenom.error ? "Veuillez entrer votre prenom" : ""}
          variant="outlined"
          required
        />
        <TextFieldColor
          error={data.mail.error}
          fullWidth
          id="outlined-basic"
          label="E-Mail"
          margin="normal"
          value={data.mail.value}
          onChange={(e) => handleFormChange(e, "mail")}
          helperText={data.mail.error ? "Veuillez entrer votre E-Mail" : ""}
          variant="outlined"
          required
        />
        <TextFieldColor
          error={data.entreprise.error}
          fullWidth
          id="outlined-basic"
          label="Entreprise"
          margin="normal"
          value={data.entreprise.value}
          onChange={(e) => handleFormChange(e, "entreprise")}
          helperText={
            data.entreprise.error ? "Veuillez entrer votre entreprise" : ""
          }
          required
          variant="outlined"
        />
        <TextFieldColor
          error={data.message.error}
          fullWidth
          id="outlined-basic"
          label="Message"
          margin="normal"
          value={data.message.value}
          onChange={(e) => handleFormChange(e, "message")}
          helperText={data.message.error ? "Veuillez entrer votre message" : ""}
          multiline
          rows="8"
          required
          variant="outlined"
        />
        <Button
          variant={"outlined"}
          style={{ borderColor: "white" }}
          onClick={submit}
        >
          Envoyer
        </Button>
      </form>
      {feedback && (
        <Typography variant={"overline"} color={"primary"} component={"p"}>
          {feedback}
        </Typography>
      )}
    </>
  );
};
export default ContactForm;
