import theme from "./theme";
import { barlowRegular, barlowSemiBold, barlowBold } from "../fonts/";
import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";

let muiTheme = createMuiTheme({
  palette: {
    primary: {
      main: theme.colors.blue1,
    },
    secondary: {
      main: theme.colors.blue2,
    },
  },
  typography: {
    h1: {
      fontSize: "3.5rem",
      color: theme.colors.blue1Darker,
    },
    h2: {
      fontSize: "3rem",
      color: theme.colors.grey,
    },
    h3: {
      fontSize: "2.2rem",
      color: theme.colors.grey,
    },
    h4: {
      fontSize: "1.8rem",
      color: theme.colors.grey,
    },
    subtitle1: {
      fontSize: "1.8rem",
      color: theme.colors.grey,
    },
    body1: {
      fontSize: "1.2rem",
      lineHeight: "1.7",
      color: theme.colors.grey,
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [barlowRegular, barlowSemiBold, barlowBold],
      },
    },
  },
});

muiTheme = responsiveFontSizes(muiTheme);

export default muiTheme;
