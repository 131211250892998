import { createStyles, ListItem, Tooltip } from "@material-ui/core";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
import FolderIcon from "@material-ui/icons/Folder";
import React from "react";
import IconButton from "@material-ui/core/IconButton";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import LaunchIcon from "@material-ui/icons/Launch";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      margin: theme.spacing(4, 0, 2),
    },
    bg: {
      backgroundColor: (props) => (props.isEditor ? "#027cf3" : "#828282"),
    },
  })
);

export const DossierItem = ({ dossier, open, secondaryText }) => {
  const classes = useStyles({ isEditor: dossier.isEditor });
  const { pathname } = useLocation();

  const { slug } = dossier;
  const CustomLink = React.useMemo(
    () =>
      React.forwardRef((linkProps, ref) => (
        <Link ref={ref} to={pathname + "/" + slug} {...linkProps} />
      )),
    [pathname, slug]
  );
  const tooltipText = dossier.isEditor
    ? "Vous pouvez éditer ce dossier"
    : "Accéder au dossier";
  return (
    <Tooltip title={tooltipText}>
      <ListItem
        button
        component={CustomLink}
        data-dossierid={dossier.id}
        data-isEditor={dossier.isEditor}
      >
        <ListItemAvatar>
          <Avatar className={classes.bg}>
            {open ? <FolderOpenIcon /> : <FolderIcon />}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={dossier.name}
          secondary={secondaryText ? "Secondary text" : null}
        />
        <ListItemSecondaryAction>
          <IconButton edge="end" aria-label="delete">
            <LaunchIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </Tooltip>
  );
};
