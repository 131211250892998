import "date-fns";
import React from "react";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import frLocale from "date-fns/locale/fr";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";

export default function DateIntervalInput({ handleChange, debut, fin }) {
  const onChangeDate = (date, name) =>
    date &&
    !isNaN(date.getTime()) &&
    handleChange({ target: { name: name, value: date.toISOString() } });

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
      <Grid container justify="center" spacing={3}>
        <KeyboardDatePicker
          margin="normal"
          label="Date de début"
          format="dd/MM/yyyy"
          value={debut}
          onChange={date => onChangeDate(date, "eventdate_gte")}
          KeyboardButtonProps={{
            "aria-label": "change date"
          }}
        />
        <KeyboardDatePicker
          margin="normal"
          label="Date de fin"
          format="dd/MM/yyyy"
          value={fin}
          onChange={date => onChangeDate(date, "eventdate_lte")}
          KeyboardButtonProps={{
            "aria-label": "change date"
          }}
        />
      </Grid>
    </MuiPickersUtilsProvider>
  );
}
