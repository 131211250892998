// @ts-ignore
import React from "react";
import { Grid, Typography } from "@material-ui/core";
// @ts-ignore
import { useQuery, Loading } from "react-admin";
import { DossierList } from "../Dossier/DossierList";
import { Error } from "../Error";
import getAllDossier from "../../query/allDossiers.query";
import RightClick from "../RightClick/RightClick";
import AucunDossier from "../Disclaimer/AucunDossier";

const AllDossier = () => {
  const { data, loading, error } = useQuery(getAllDossier);

  if (loading) return <Loading />;
  if (error) return <Error message={error.message} />;
  if (data.length === 0 || !data) return <AucunDossier />;
  return (
    <RightClick>
      <Grid container>
        <Grid item xs={12}>
          <Typography
            variant={"h5"}
            component={"h1"}
            align={"center"}
            color={"primary"}
          >
            Tous les dossiers
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {data && <DossierList childDossiers={data} />}
        </Grid>
      </Grid>
    </RightClick>
  );
};

export default AllDossier;
