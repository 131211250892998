import React, { useState } from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide, { SlideProps } from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import Title from "./Title";

const Transition = React.forwardRef<unknown, TransitionProps>(
  function Transition(props: SlideProps, ref: any) {
    return <Slide direction="up" ref={ref} {...props} />;
  }
);

interface PointProps {
  className?: string;
  x: number;
  y: number;
  title: string;
  text: string;
}

const Point = styled(({ className, x, y, title, text }: PointProps) => {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <g className={className}>
      <circle cx={x} cy={y} r="12" onClick={handleClickOpen} />
      <Title x={x} y={y} title={title} />
      <foreignObject>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          {title && (
            <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
          )}
          <DialogContent>
            <DialogContentText
              id="alert-dialog-slide-description"
              style={{ whiteSpace: "pre-line" }}
            >
              {text}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </foreignObject>
    </g>
  );
})`
  & circle {
    cursor: pointer;
    stroke-width: 1;
    transition: all 0.25s;
    fill: ${(props) => props.theme.colors.greenDarker};
    stroke: ${(props) => props.theme.colors.greenDarker};
    &:hover {
      fill: ${(props) => props.theme.colors.blue2Darker};
      stroke: ${(props) => props.theme.colors.blue2Darker};
      stroke-width: 8;
    }
    &:active {
      fill: ${(props) => props.theme.colors.blue2};
      stroke: ${(props) => props.theme.colors.blue2};
    }
  }
`;

export default Point;
