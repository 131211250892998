import {COMPANY_TITLE} from "../../config/config";
import { VCardProps } from "../VCard";
import { MenuItemProps } from "../Menu";
import { mdiLinkedin, mdiTwitter } from "@mdi/js";
import { PRESSE_PAGE_LINK } from "../MenuMain/mock";

export const vCard: VCardProps = {
  url: "https://uniondesassisteurs.fr",
  urLabel: "uniondesassisteurs.fr",
  organization:COMPANY_TITLE,
  email: "contact@uniondesassisteurs.fr",
  tel: "",
  streetAddress: "18 Rue des Pyramides",
  postalCode: "75001",
  locality: "Paris",
};

export const pressAreaMenuItems: MenuItemProps[] = [
  {
    name: "Espace Presse",
    route: "/#/" + PRESSE_PAGE_LINK,
  },
];

export const networksMenuItems: MenuItemProps[] = [
  {
    name: "LinkedIn",
    route:
      "https://www.linkedin.com/company/syndicat-national-des-societes-d-assistance/\n",
    icon: mdiLinkedin,
    iconOnly: true,
    type: "button",
  },
  {
    name: "Twitter",
    route: "https://twitter.com/Snsa_Com",
    icon: mdiTwitter,
    iconOnly: true,
    type: "button",
  },
];
