import Helmet from "react-helmet";
import React from "react";
import {baseUrl} from "./config/config";
import {COMPANY_TITLE} from "./config/config";

interface HeadAppProps {
  pageTitle?: string;
}

const HeadApp = ({ pageTitle }: HeadAppProps) => {
  const siteName = COMPANY_TITLE;
  const catchPhrase = COMPANY_TITLE;
  const baseTitle = siteName + " | " + catchPhrase;
  const title = pageTitle ? pageTitle + " | " + baseTitle : baseTitle;
  const defaultThumbnailUrl = baseUrl + "/snsa-thumbnail.jpg";
  const defaultDescription =
    "Parents en recherche de babysitting ? Contactez les babysitters et les nounous proche de chez vous pour du babysitting parmi des centaines de Claudine de confiance.";
  return (
    <Helmet>
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/favicons/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicons/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicons/favicon-16x16.png"
      />
      <link rel="manifest" href="/favicons/site.webmanifest" />
      <link
        rel="mask-icon"
        href="/favicons/safari-pinned-tab.svg"
        color="#2d4698"
      />
      <meta name="msapplication-TileColor" content="#2d4698" />
      <meta name="theme-color" content="#ffffff" />
      <link
        rel="stylesheet"
        type="text/css"
        href="//cdn.jsdelivr.net/gh/kenwheeler/slick@1.8.1/slick/slick.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="//cdn.jsdelivr.net/gh/kenwheeler/slick@1.8.1/slick/slick-theme.css"
      />
      <meta property="og:title" name="title" content={title} />
      <meta
        property="og:description"
        name="description"
        content={defaultDescription}
      />
      <meta
        property="og:image"
        name="thumbnail_image"
        content={defaultThumbnailUrl}
      />
      <meta property="og:type" name="medium_type" content="website" />
      <meta property="og:url" name="" content={baseUrl} />
      <meta property="og:site_name" name="site_name" content={siteName} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content={baseUrl} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={defaultDescription} />
      <meta name="twitter:image" content={defaultThumbnailUrl} />
      <title>{title}</title>
      <style type="text/css">
        {`
          body {
            margin: 0;
          }
        `}
      </style>
    </Helmet>
  );
};

export default HeadApp;
