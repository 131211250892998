import React from "react";
import { Member } from "../../types/member";
import { Typography } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import styled from "styled-components";

interface MemberProfileProps {
  member: Member;
  className?: string;
}

const MemberProfile = styled(({ member, className }: MemberProfileProps) => (
  <div className={className}>
    <Avatar
      src={member.photo && process.env.REACT_APP_MEDIA_URL + member.photo.url}
      alt={member.first_name + " " + member.last_name}
      style={{ width: "60px", height: "60px" }}
    />
    <Typography variant="body1" component="p">
      {member.first_name + " " + member.last_name}
    </Typography>
    {member.role ? (
      <Typography variant="body1" component="p">
        {member.role}
      </Typography>
    ) : (
      ""
    )}
  </div>
))`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 100px;
  padding: 8px;
  text-transform: none !important;
  & p:first-of-type {
    font-family: "Barlow-SemiBold";
    padding-top: 5px;
  }
  & p {
    line-height: 1.1;
    text-align: center;
  }
`;

export default MemberProfile;
