import { applyMiddleware, compose, createStore } from "redux";
import { routerMiddleware } from "connected-react-router";
import { createHashHistory } from "history";
import { USER_LOGOUT } from "react-admin";
import rootReducer from "./reducers";
import createSagaMiddleware from "@redux-saga/core";
import rootSaga from "./sagas";
import { dataProvider } from "../admin/dataProvider";
import authProvider from "../admin/authProvider";
import { initialUserState } from "./reducers/user.reducer";

export const history = createHashHistory();

const resettableAppReducer = (state, action) =>
  rootReducer(history)(
    action.type === USER_LOGOUT ? initialUserState : state,
    action
  );

const sagaMiddleware = createSagaMiddleware();

const middlewares = [routerMiddleware(history), sagaMiddleware];

/* eslint-disable @typescript-eslint/no-explicit-any */
const addReduxDevTools = (window as any).__REDUX_DEVTOOLS_EXTENSION__
  ? (window as any).__REDUX_DEVTOOLS_EXTENSION__()
  : (f: any): any => f;

const enhancer = compose(applyMiddleware(...middlewares), addReduxDevTools);

export const store = createStore(resettableAppReducer, enhancer);

sagaMiddleware.run(rootSaga(dataProvider, authProvider));

export default store;
