import { DossierItem } from "../../ContentItems/DossierItem";
import React from "react";
import { Grid, Typography } from "@material-ui/core";
import List from "@material-ui/core/List";

export const DossierList = ({ childDossiers }) => {
  if (childDossiers.length === 0 || !childDossiers) return null;
  return (
    <Grid container justify={"flex-start"} spacing={1}>
      <Grid item xs={12}>
        <Typography variant={"h6"} color={"secondary"}>
          Sous-dossiers :
        </Typography>
      </Grid>
      <Grid container spacing={1}>
        {childDossiers.map((dossier) => (
          <Grid item key={dossier.id} xs={12} md={4} l={3}>
            <List>
              <DossierItem dossier={dossier} open={false} />
            </List>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
