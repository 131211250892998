import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
  ReferenceInput,
  FileInput,
  FileField,
  DateInput,
} from "react-admin";
import { getParams } from "../../../../helpers/getParamsId";
import { Button, Typography } from "@material-ui/core";
import { useHistory } from "react-router";
import { redirectAdmin } from "../../../../helpers/redirect";

const DocumentCreate = (props) => {
  const params = getParams(props);
  const dossierID = params && params.id;
  const { goBack } = useHistory();

  const PostTitle = () => {
    return (
      <Typography component={"h1"} variant={"h1"}>
        Ajouter un document
      </Typography>
    );
  };
  return (
    <>
      <Typography
        component={"h1"}
        variant={"h2"}
        align={"center"}
        style={{ padding: "30px" }}
      >
        Ajouter un document
      </Typography>

      <Button variant={"outlined"} onClick={() => goBack()}>
        Revenir
      </Button>

      <Create undoable={false} title={<PostTitle />} {...props}>
        <SimpleForm redirect={() => redirectAdmin(params, props.basePath)}>
          <TextInput source="title" label={"Titre"} />
          <TextInput source="text" label={"Description"} multiline fullWidth />
          <FileInput source="media" label="Ajouter document">
            <FileField source={"url"} title={"name"} />
          </FileInput>
          <DateInput
            source="eventdate"
            label={"Date de l'évenement"}
            defaultValue={new Date()}
          />

          {dossierID ? (
            <ReferenceInput
              source={"dossiers"}
              reference={"dossiers"}
              label={"Nom du dossier parents"}
              perPage={100}
              defaultValue={[dossierID]}
              disabled={!!dossierID}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
          ) : (
            <ArrayInput
              source="dossiers"
              label={"Ajoutez le document à un dossier"}
            >
              <SimpleFormIterator>
                <ReferenceInput
                  source="id"
                  reference={"dossiers"}
                  label={"Nom du dossier parents"}
                  perPage={100}
                  defaultValue={dossierID}
                >
                  <SelectInput optionText="name" />
                </ReferenceInput>
              </SimpleFormIterator>
            </ArrayInput>
          )}
        </SimpleForm>
      </Create>
    </>
  );
};

export default DocumentCreate;
